<template>
  <div class="accordion-item">
    <div class="header" @click="handleHeaderClick">
      <h4 class="header-text">
        {{ title }}
      </h4>
      <BaseIcon
        :icon-file="!isOpen ? 'icon-add' : 'icon-subtract'"
        :width="20"
        :height="20"
        class="icon"
      />
    </div>
    <Transition name="slide-down">
      <div v-if="isOpen" class="body">
        <div class="text-content editor-content">
          <slot name="body"></slot>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  name: 'BaseAccordionItem',

  props: {
    isOpen: {
      type: Boolean,
      default: false
    },

    itemId: {
      type: String,
      required: true
    },

    scrollToOpenAccordion: {
      type: Boolean,
      default: false
    },

    title: {
      type: String,
      required: true
    }
  },

  mounted() {
    if (this.scrollToOpenAccordion && this.isOpen) {
      this.$nextTick(() => {
        this.$el.scrollIntoView({ behavior: 'instant', block: 'center' });
      });
    }
  },

  methods: {
    handleHeaderClick() {
      this.$parent.$emit('toggle', this.itemId, this.title);
    }
  }
};
</script>

<style lang="scss" scoped>
.accordion-item {
  border-bottom: 1px solid #eee;

  &:first-of-type {
    border-top: 1px solid #eee;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px;
  user-select: none;
  cursor: pointer;
}

.header-text {
  margin: 0;
  font-weight: 600;
  line-height: 1.3;
}

.icon {
  margin-left: 10px;
  flex-shrink: 0;
}

.text-content {
  margin-bottom: 1.5rem;
  padding: 0 10px;
  font-size: 15px;
  max-width: 800px;
}

::v-deep .text-content b,
::v-deep .text-content strong {
  font-weight: 600;
}

::v-deep p {
  margin-top: 0;
}
</style>
