var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.shouldRenderCard)?_c('div',{staticClass:"proposition-card-wrapper",class:{ 'blur-content': _vm.isBlurred, 'blur-card': _vm.isBlurredDistribution }},[_c(_vm.isBlurred || _vm.disableLink ? 'div' : 'router-link',{tag:"component",class:[
      'proposition-card',
      { 'disable-link': _vm.disableLink, 'blur-content': _vm.isBlurred }
    ],style:(_vm.usedInTutorial ? { overflow: 'visible' } : {}),attrs:{"to":{
      query: {
        propositionPageId: _vm.swap.propositionId,
        type: _vm.swap.details ? _vm.swap.details.type : null
      }
    }},on:{"click":_vm.handlePropositionCardClick},nativeOn:{"click":function($event){return _vm.handlePropositionCardClick.apply(null, arguments)},"contextmenu":function($event){return _vm.handlePropositionCardRightClick.apply(null, arguments)}}},[(_vm.isBlurred)?_c('div',{staticClass:"blurred-cta-container"},[_c('BaseButton',{class:[
          'blurred-button',
          {
            smaller: _vm.isMobile
          }
        ],attrs:{"icon-file":"icon-premium-white"}},[_vm._v(" "+_vm._s(_vm.$t('unlock_with_premium'))+" ")])],1):_vm._e(),_c('div',{class:['residence-header', { 'used-in-tutorial': _vm.usedInTutorial }]},[(
          !_vm.swap.boosted &&
          !_vm.usedInBoostBlock &&
          _vm.currentShownResidence.pictures &&
          _vm.currentShownResidence.pictures.length > 0
        )?_c('SwapListImageSlider',{key:_vm.currentResidence,attrs:{"current-shown-residence":_vm.currentShownResidence,"is-blurred":_vm.isBlurred,"proposition-id":_vm.swap.propositionId.toString(),"current-residence":_vm.currentResidence}}):(
          (_vm.swap.boosted || _vm.usedInBoostBlock) &&
          _vm.currentShownResidence.pictures &&
          _vm.currentShownResidence.pictures.length > 0
        )?_c('div',{class:['primary-image-container']},[_c('img',{class:[
            'primary-image',
            {
              'is-blurred': _vm.isBlurred,
              'large-image': _vm.usedInBoostBlock
            }
          ],attrs:{"src":_vm.currentShownResidence.pictures &&
            _vm.currentShownResidence.pictures.length !== 0
              ? _vm.getImageThumbnail(_vm.currentShownResidence.pictures[0])
              : _vm.currentShownResidence.image || _vm.fallbackImage}})]):(
          _vm.propositionImagesArray && _vm.propositionImagesArray.length > 0
        )?_c('img',{staticClass:"img-placeholder",attrs:{"src":_vm.propositionImagesArray[0],"alt":""}}):_c('div',{staticClass:"placeholder-container"},[_c('div',{staticClass:"img-placeholder no-image",class:{ 'large-image': _vm.usedInBoostBlock || _vm.swap.boosted }}),_c('p',{class:{ 'large-image': _vm.usedInBoostBlock || _vm.swap.boosted }},[_vm._v(" "+_vm._s(_vm.$t('proposition_missing_image_text'))+" ")])]),_c('div',{staticClass:"top-label-container"},[(!_vm.isBlurred)?_c('AmenitiesLabels',{staticClass:"amenities-labels",attrs:{"residence":_vm.currentShownResidence}}):_vm._e()],1),((_vm.swap.boosted || _vm.alwaysShowBoostIcon) && _vm.showBoostIcon)?_c('div',{class:[
          'boost-icon',
          { 'always-show-boost-icon': _vm.alwaysShowBoostIcon }
        ]},[_c('BaseIcon',{attrs:{"icon-file":"icon-rocket-white"}})],1):_vm._e(),_c('div',{staticClass:"bottom-label-container"},[(
            _vm.swapType &&
            !(_vm.isBlurred || _vm.usedInTutorial) &&
            _vm.swap.swapType !== _vm.primaryProposition.swapType
          )?_c('div',{staticClass:"swap-type-label"},[_vm._v(" "+_vm._s(_vm.swapType.label)+" ")]):_vm._e(),_c('InfoLabels',{key:_vm.swap.inSwapProcess,staticClass:"info-labels",attrs:{"swap":_vm.swap}})],1)],1),(
        _vm.showAdditionalImages &&
        _vm.currentShownResidence.pictures &&
        _vm.currentShownResidence.pictures.length >= 3
      )?_c('div',{staticClass:"secondary-images-container"},[_c('img',{attrs:{"src":_vm.getImageThumbnail(_vm.currentShownResidence.pictures[1])}}),_c('img',{attrs:{"src":_vm.getImageThumbnail(_vm.currentShownResidence.pictures[2])}})]):_vm._e(),(_vm.is2to1 && _vm.showTwoToOneBlock)?_c('div',{staticClass:"current-shown-proposition"},[_c('div',[_vm._v(_vm._s(_vm.$t('proposition_card_show_apt'))+":")]),_c('div',{staticClass:"switch-proposition-buttons"},[_c('button',{class:{ active: _vm.currentResidence === 0 },on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.switchCurrentShown(0)}}},[_vm._v(" 1 ")]),_c('button',{class:{ active: _vm.currentResidence === 1 },on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.switchCurrentShown(1)}}},[_vm._v(" 2 ")])])]):_vm._e(),_c('div',{staticClass:"address-and-info-wrapper"},[_c('div',{staticClass:"address-and-badge-container"},[_c('div',{staticClass:"adress-container"},[_c('div',{staticClass:"street-address"},[_vm._v(" "+_vm._s(_vm.currentShownResidence.streetAddress)+" "),(_vm.swap.verified)?_c('BaseIcon',{staticClass:"verified-badge",attrs:{"icon-file":"verified-white-check","width":15,"height":15}}):_vm._e()],1),_c('div',{class:[
              'postal-area',
              { 'diff-value': _vm.swap.diff && _vm.swap.diff.geolocation }
            ]},[_vm._v(" "+_vm._s(_vm.currentShownResidence.postalArea ? _vm.currentShownResidence.postalArea : _vm.currentShownResidence.postalAddress)+" ")])]),_c('div',{staticClass:"swap-type-badge-container"},[_c('div',{staticClass:"swap-type-badge"},[_vm._v(" "+_vm._s(_vm.swap.details && _vm.swap.details.type === 'directswap' ? _vm.$t('swap_list_item_direct_swap') : _vm.swap.details && _vm.swap.details.type === 'triangleswap' ? _vm.$t('swap_list_item_triangle_swap') : _vm.swap.details && _vm.swap.details.type === 'manual' ? _vm.$t('swap_list_item_direct_swap') : '')+" ")])])]),_c('div',{staticClass:"residence-info-bar"},[_c('div',[_c('span',{class:{ 'diff-value': _vm.swap.diff && _vm.swap.diff.rooms }},[_vm._v(" "+_vm._s(_vm.currentShownResidence.rooms)+" "+_vm._s(_vm.$t('swap_item_rooms'))+" ∙ ")]),_c('span',{class:{ 'diff-value': _vm.swap.diff && _vm.swap.diff.sqm }},[_vm._v(" "+_vm._s(_vm.currentShownResidence.sqm)+" "+_vm._s(_vm.$t('swap_item_sqm'))+" ∙ ")]),_c('span',{class:{ 'diff-value': _vm.swap.diff && _vm.swap.diff.floor }},[_vm._v(" "+_vm._s(_vm.currentShownResidence.floor !== 0 && _vm.currentShownResidence.floor !== 999 ? _vm.$t('proposition_description_floor') : '')+" "+_vm._s(_vm.formattedFloor)+" ")])]),_c('span',{class:{ 'diff-value': _vm.swap.diff && _vm.swap.diff.rent }},[_c('b',[_vm._v(_vm._s(_vm.spacedRent))]),_vm._v(" "+_vm._s(_vm.$t('swap_item_cost_per_month'))+" ")])])]),(_vm.showTimerBlock)?_c('div',{staticClass:"interest-timer-block"},[_c('div',{staticClass:"interest-timer"},[_c('BaseIcon',{staticClass:"stopwatch-icon",attrs:{"icon-file":"stopwatch","width":24,"height":24}}),_c('div',[_vm._v("Svara inom 6 dagar")])],1),_c('BaseIcon',{staticClass:"questionmark-icon",attrs:{"icon-file":"questionmark","width":20,"height":20}})],1):_vm._e(),(
        (_vm.interests && _vm.showInterestButtonsBlock && _vm.isUserList) || _vm.isBlurred
      )?_c('InterestButtons',{staticClass:"interest-buttons",attrs:{"interested":_vm.interests[0],"target-proposition-id":_vm.swap.propositionId.toString(),"swap":_vm.swap,"used-in-tutorial":_vm.usedInTutorial,"context":_vm.context,"is-blurred":_vm.isBlurred,"reverse-is-shown":_vm.reverseIsShown},on:{"interested":function($event){return _vm.$emit('interested', $event)},"no-interest":function($event){return _vm.$emit('no-interest', $event)},"open-chat":function($event){return _vm.openPropositionModalTab('chatTab')}}}):_vm._e()],1),(_vm.showTutorialPointer)?_c('div',[_c('InterestPointer')],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }