<template>
  <div ref="tooltip" class="info-tooltip-container">
    <a class="info-button" @click="toggleModal()">i</a>
    <div
      v-if="showInfo"
      :class="['info-tooltip', verticalClass]"
      :style="{ left: `${xPosition}px` }"
    >
      <BaseIcon
        icon-file="close-cross-alt"
        class="close"
        @click="showInfo = false"
      />
      <span v-html="infoHtml"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoTooltip',

  props: {
    infoHtml: {
      type: String,
      default: '',
      required: false
    }
  },

  data() {
    return {
      xPosition: 0,
      verticalClass: 'above',
      showInfo: false
    };
  },

  methods: {
    toggleModal() {
      if (this.showInfo) {
        this.showInfo = false;
        return;
      }

      const rect = this.$refs.tooltip.getBoundingClientRect();
      if (rect.x + 500 > document.body.clientWidth) {
        this.xPosition = Math.floor(-rect.x + 20);
      } else {
        this.xPosition = 0;
      }

      if (rect.y > window.innerHeight - window.innerHeight / 3) {
        this.verticalClass = 'above';
      } else {
        this.verticalClass = 'below';
      }
      this.showInfo = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.info-tooltip-container {
  position: relative;
  display: inline-block;
  vertical-align: top;
}

.info-button {
  cursor: pointer;
  font-style: italic;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  text-align: center;
  vertical-align: middle;
  background: #0b182c;
  color: white;
  margin-left: 10px;
  padding-top: 3px;
  padding-right: 3px;
  position: absolute;
  font-weight: bold;
  font-size: 12px;
  top: -4px;
}

.info-tooltip {
  position: absolute;
  border: 1px solid transparent;
  padding: 20px 50px 20px 30px;
  background: white;
  z-index: 10;
  border-radius: 15px;
  line-height: 20px;
  box-shadow: 0 3px 8px 1px rgba(0, 0, 0, 0.15);
  min-width: 300px;
  width: calc(100vw - 50px);
  max-width: 500px;
}

.info-tooltip .close {
  cursor: pointer;
  width: 15px;
  height: 15px;
  right: 15px;
  position: absolute;
}

.info-tooltip.above {
  bottom: 20px;
}

.info-tooltip.below {
  top: 20px;
}
</style>
