<template>
  <div class="search-wrapper">
    <div class="search-box">
      <div class="search-icon"></div>
      <input
        :placeholder="$t('search_placeholder')"
        class="search-input"
        :value="searchTerm"
        @input="e => onInput(e.target.value)"
      />
      <transition name="fade" mode="out-in">
        <div
          v-if="searchTerm.length"
          class="clear-search"
          @click="() => onInput('')"
        ></div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import debounce from 'lodash/debounce';

export default {
  name: 'Search',

  computed: {
    ...mapGetters({
      searchTerm: 'swapList/searchTerm',
      isMobile: 'screenSize/isMobile'
    })
  },

  methods: {
    ...mapActions({
      handleUpdateSearchTerm: 'swapList/handleUpdateSearchTerm',
      performSearchTermSwapSearch: 'swapList/performSearchTermSwapSearch'
    }),

    search: debounce(function () {
      window.scrollTo({ top: 0, behavior: 'smooth' });

      this.performSearchTermSwapSearch();
    }, 500),

    onInput(value) {
      this.handleUpdateSearchTerm({ value });

      this.search();
    }
  }
};
</script>

<style lang="scss" scoped>
.search-wrapper {
  position: relative;
  padding: 12px 27px 12px 12px;
  display: flex;
  align-items: center;

  @media ($mobile) {
    width: 100%;
    padding: 12px;
  }
}

.search-box {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 11px 28px 11px 12px;
  border-radius: 20px;
  background-color: #f1f2f6;

  @media ($mobile) {
    border-radius: 10px;
  }
}

.search-input {
  background: transparent;
  color: #2c3e50;
  font-size: 0.8rem;
  font-weight: 600;
  border: none;
  outline: none;
  width: 100%;
  padding-left: 6px;
}

.search-icon {
  height: 16px;
  width: 16px;
  background-position: center;
  background-size: contain;
  background-image: url('../../assets/svg/byteslista-search.svg');
}

.clear-search {
  cursor: pointer;
  height: 14px;
  width: 14px;
  background-position: center;
  background-size: contain;
  background-image: url('../../assets/svg/icons/close.svg');
  position: absolute;
  right: 40px;
  top: calc(50% - 7px);

  @media ($mobile) {
    right: 14px;
  }
}
</style>
