<template>
  <textarea
    v-outside-click="handleOutsideClick"
    v-bind="$attrs"
    :value="value"
    :class="{ disabled: $attrs.disabled }"
    @input="$emit('input', $event.target.value)"
    @keyup.enter="$emit('enter', $event)"
    @focus="$emit('focus', $event)"
    @blur="$emit('blur', $event)"
  />
</template>

<script>
import outsideClick from '@/directives/outside-click';

export default {
  name: 'AArea',

  directives: {
    outsideClick
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
      required: false
    }
  },

  computed: {},

  methods: {
    handleOutsideClick(e) {
      this.$emit('outside', e);
    }
  }
};
</script>

<style scoped>
textarea:focus {
  outline: none;
}

textarea.disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}
</style>
