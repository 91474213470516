import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  loading: false,
  searchLoading: false,
  searchNoResultsFound: false,
  searchResult: [],
  searchTerm: '',
  wishAreaOptions: [],
  selectedAreas: [],
  multiPolygon: {},
  mustSelectNewAreas: false
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
