<template>
  <div class="wish-area-wrapper">
    <div
      ref="areaContainerSignup"
      class="area-container"
      :class="{ 'area-container--no-scroll': showAreasPanel }"
    >
      <div class="area-inner">
        <PageStep :step="currentStep" />

        <PageTitle>{{ $t('wish_area_title') }}</PageTitle>

        <div v-if="swapType === 'oneTwo'" class="subtitle">
          {{ $t('wish_title_wish_index', { wishIndex: wishIndex }) }}
        </div>

        <template v-if="wishAreaOptions.length > 0">
          <div class="city-select-container">
            <div
              v-for="cities in wishAreaOptions"
              :key="cities.city"
              class="city-select-item"
              @click="toggleAreasPanel(cities.city)"
            >
              {{ cities.city }}
              <BaseIcon
                :icon-file="'chevron-right-black'"
                :width="20"
                :height="20"
              />
            </div>
            <div class="city-select-item" @click="handleMapInputFieldClick">
              {{ $t('wish_area_other_cities') }}
              <BaseIcon
                :icon-file="'chevron-right-black'"
                :width="20"
                :height="20"
              />
            </div>
          </div>

          <BasePanel v-if="showAreasPanel" @close="toggleAreasPanel">
            <PageTitle>
              {{ $t('wish_area_options_choose_areas_in') }} {{ selectedCity }}
            </PageTitle>
            <BaseCheckbox
              v-for="option in selectedCityOptions"
              :key="option.name"
              :selected="isAreaSelected(option)"
              :name="option.name"
              :value="option.name"
              :is-checkbox-dark="true"
              :label="
                option.type === 'PostalCode' ||
                option.type === 'ThreeDigitPostalCode'
                  ? option.description
                  : option.name
              "
              style="margin-bottom: 12px"
              @change="handleAreaCheckboxClick(option)"
            />
            <template #button>
              <div class="panel-button-content">
                <template v-if="currentSelectedAreas.length > 0">
                  <BaseIcon icon="check-circle" />
                  {{ $t('wish_area_options_choose_areas_button') }}
                </template>
                <template v-else>
                  <BaseIcon icon="close" />
                  {{ $t('wish_area_options_cancel_button') }}
                </template>
              </div>
            </template>
          </BasePanel>
        </template>

        <BasePanel
          v-if="showFreeTextSearchPanel"
          ref="freeTextSearchPanelSignup"
          @close="closeFreeTextSearchPanel"
        >
          <MapInputField
            :id="fuChrome"
            ref="mobileMapInputField"
            :description="$t('wish_area_free_text_label')"
            :placeholder="
              currentSelectedAreas.length >= 1
                ? $t('wish_area_add_area')
                : $t('wish_area_search_for')
            "
            :no-results-found="searchNoResultsFound"
            :no-results-message="$t('map_suggestions_no_results_wish_area')"
            :value="searchTerm"
            :suggestions="searchResult"
            :suggestion-callback="handleSuggestionClick"
            :handle-update="handleSearchTermUpdated"
            :has-focus="inputFieldFocused"
            :set-focus="handleFocus"
            :loading="searchLoading"
            :autofocus="true"
            :show-results-icon="true"
            input-icon="arrow-left"
            class="without-shadow"
            @input-icon-clicked="closeFreeTextSearchPanel"
          />
          <template #button>
            <div class="panel-button-content">
              <template>
                <BaseIcon icon="check-circle" />
                {{ $t('base_panel_close') }}
              </template>
            </div>
          </template>
        </BasePanel>

        <div
          v-if="!isMobile && currentSelectedAreas.length"
          class="selected-areas"
        >
          <hr />
          <label class="selected-label"
            >{{ $t('wish_area_my_chosen_areas') }}:</label
          >
          <div
            v-for="area in currentSelectedAreas"
            :key="area.id"
            class="selected-area"
          >
            <span>{{ area.name }}, {{ area.description }}</span>
            <div
              class="delete-btn"
              @click.stop="() => handleRemoveArea(area)"
            ></div>
          </div>
        </div>
        <div v-if="isMobile" class="mobile-map-wrapper">
          <div class="mobile-ignore-margin">
            <AreaPickerMap
              :coordinates="$country.getValue('MAP_INITIAL_COORDINATES')"
              :zoom="$country.getValue('MAP_ZOOM')"
              :current-polygon="currentPolygon"
            />
          </div>
        </div>

        <MobileTipBox
          v-if="$country.currentCountry !== 'dk'"
          :text="$t('wish_area_mobile_tooltip_text')"
        />

        <NextButtonContainer>
          <div class="button-container-top">
            <span class="chosen-areas-label">
              {{ $t('wish_area_chosen_areas') }}:
              <span v-if="currentSelectedAreas.length === 0">{{
                $t('wish_area_none_selected')
              }}</span>
              <span v-else
                >{{ currentSelectedAreas.length }}
                {{ $t('wish_area_pce') }}</span
              >
            </span>
            <button
              v-if="currentSelectedAreas.length"
              class="show-chosen-areas-button"
              @click="handleShowChosenAreasClick"
            >
              {{ $t('wish_area_show') }}
            </button>
          </div>
          <div v-if="showErrors" class="error-message">
            {{ $t('wish_area_error_message') }}
          </div>
          <div
            v-if="numberOfMatchingApartments >= 50"
            class="matching-apartments-container"
          >
            <span class="matching-apartments-label">
              {{ $t('wish_area_matching_apartments') }}:
              {{ numberOfMatchingApartments }}+
            </span>
          </div>
          <BaseButton
            class="button-go-to-next-page smaller rounded"
            :icon-file="'chevron-right-white'"
            :icon-last="true"
            :icon-size="20"
            @click="handleNextClick()"
          >
            {{ $t('wish_area_action') }}
          </BaseButton>
        </NextButtonContainer>

        <BasePanel v-if="showChosenAreasPanel" @close="toggleChosenAreasPanel">
          <PageTitle>{{ $t('wish_area_my_chosen_areas') }}</PageTitle>
          <div class="mobile-areas">
            <div class="mobile-areas-list">
              <div
                v-for="area in currentSelectedAreas"
                :key="area.id"
                class="selected-area"
              >
                <span>{{ area.name }}, {{ area.description }}</span>
                <div
                  class="delete-btn"
                  @click.stop="() => handleRemoveArea(area)"
                ></div>
              </div>
            </div>
          </div>
          <template #button>
            <div class="panel-button-content">
              <template>
                <BaseIcon icon="check-circle" />
                {{ $t('base_panel_close') }}
              </template>
            </div>
          </template>
        </BasePanel>
      </div>
    </div>
    <div v-if="!isMobile" class="map-container">
      <AreaPickerMap
        :coordinates="$country.getValue('MAP_INITIAL_COORDINATES')"
        :zoom="$country.getValue('MAP_ZOOM')"
        :current-polygon="currentPolygon"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { uid } from 'uid';
import { debounce } from 'lodash';
import PageTitle from '@/components/Signup/PageTitle';
import MapInputField from '@/components/FormElements/InputField/MapInputField';
import AreaPickerMap from '@/components/Maps/AreaPickerMap';
import MobileTipBox from '@/components/Signup/MobileTipBox';
import PageStep from '@/components/Signup/PageStep';
import NextButtonContainer from '@/components/Signup/NextButtonContainer';
import events from '@/utils/events';
import { CURRENT_STEPS } from '@/store/modules/signup';
import { calculateProgress } from '@/utils/progress';

export default {
  name: 'WishArea',
  components: {
    PageTitle,
    MapInputField,
    AreaPickerMap,
    MobileTipBox,
    PageStep,
    NextButtonContainer
  },

  data() {
    return {
      wishIndex: 1,
      inputFieldFocused: false,
      CURRENT_STEPS,
      showErrors: false,
      showChosenAreasPanel: false,
      showAreasPanel: false,
      showFreeTextSearchPanel: false,
      selectedCity: ''
    };
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile',
      swapType: 'signup/swapType',
      wishes: 'signup/wishes',
      wishAreaOptions: 'signup/wishAreaOptions',
      searchTerm: 'signup/searchTerm',
      searchResult: 'signup/searchResultWishAreas',
      selectedAreas: 'signup/selectedAreas',
      polygons: 'signup/polygons',
      searchLoading: 'signup/searchLoading',
      searchNoResultsFound: 'signup/searchNoResultsFound',
      currentStep: 'signup/currentStep',
      numberOfMatchingApartments: 'signup/numberOfMatchingApartments'
    }),

    selectedCityOptions() {
      return this.wishAreaOptions.find(
        option => option.city === this.selectedCity
      ).options;
    },

    fuChrome() {
      return uid(16);
    },

    canContinue() {
      return this.currentSelectedAreas.length > 0;
    },

    currentSelectedAreas() {
      return this.selectedAreas[parseInt(this.wishIndex) - 1];
    },

    currentPolygon() {
      return this.polygons[parseInt(this.wishIndex) - 1];
    },

    nextLink() {
      if (this.swapType === 'oneTwo' && this.wishIndex === '1') {
        return `${this.$t('path_wish', this.$routeLocale)}/2`;
      } else {
        return `${this.$t('path_signup', this.$routeLocale)}`;
      }
    }
  },

  watch: {
    $route: function () {
      const { index } = this.$route.params;

      this.wishIndex = index;
    },

    canContinue() {
      this.showErrors = false;
    }
  },

  created() {
    this.getWishAreaButtons();
  },

  mounted() {
    const { index } = this.$route.params;
    this.wishIndex = index;
    this.setCurrentStep({ step: CURRENT_STEPS.WISH_AREA });
    this.scrollToTop();

    calculateProgress(this.currentStep, this.swapType, this.wishIndex);

    events.emitEvent(
      events.eventTypes.PAGEVIEW,
      `Signup Wish Area ${this.wishIndex}`
    );

    this.getNumberOfApartmentsForRegions(this.wishIndex);

    window.visualViewport.addEventListener(
      'resize',
      this.scrollToTopMapInputField
    );
  },

  beforeDestroy() {
    window.visualViewport.removeEventListener(
      'resize',
      this.scrollToTopMapInputField
    );
  },

  methods: {
    ...mapActions({
      setSearchTerm: 'signup/setSearchTerm',
      getWishAreaButtons: 'signup/getWishAreaButtons',
      performSearch: 'signup/performSearch',
      blurAreaField: 'signup/blurAreaField',
      selectArea: 'signup/selectArea',
      deselectArea: 'signup/deselectArea',
      setCurrentStep: 'signup/setCurrentStep',
      getNumberOfApartmentsForRegions: 'signup/getNumberOfApartmentsForRegions'
    }),

    ...mapMutations({
      addDisableScroll: 'ui/addDisableScroll',
      removeDisableScroll: 'ui/removeDisableScroll'
    }),

    toggleAreasPanel(city) {
      this.showAreasPanel = !this.showAreasPanel;
      this.selectedCity = city;

      // Scroll to top in panel when openning, and on page when closing
      if (this.$refs.areaContainerSignup !== undefined) {
        this.$refs.areaContainerSignup.scrollTop = 0;
      }
      this.scrollToTop();
    },

    handleAreaCheckboxClick(option) {
      if (!option) {
        return;
      }

      if (this.isAreaSelected(option)) {
        this.handleRemoveArea(option);
      } else {
        this.handleSelectArea(option);
      }
    },

    isAreaSelected(option) {
      if (!option) {
        return false;
      }

      return this.selectedAreas[this.wishIndex - 1].some(
        area => area.id == option.id
      );
    },

    handleShowChosenAreasClick() {
      this.showChosenAreasPanel = true;
    },

    toggleChosenAreasPanel() {
      this.showChosenAreasPanel = !this.showChosenAreasPanel;
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },

    initPerformSearch: debounce(function () {
      this.performSearch();
    }, 200),

    handleSearchTermUpdated(val) {
      this.setSearchTerm({ inputValue: val });

      if (val.length > 2) {
        this.initPerformSearch();
      }
    },

    handleSuggestionClick({ suggestion }) {
      const selectedIds = this.currentSelectedAreas.map(area => area.id);
      if (selectedIds.includes(suggestion.id)) {
        this.blurAreaField();
        return;
      }

      this.handleSelectArea(suggestion);

      this.closeFreeTextSearchPanel();
    },

    handleSelectArea(area) {
      this.selectArea({
        area: area,
        wishIndex: parseInt(this.wishIndex) - 1
      });
      this.getNumberOfApartmentsForRegions(this.wishIndex);
    },

    handleRemoveArea(area) {
      this.deselectArea({
        area,
        wishIndex: parseInt(this.wishIndex) - 1
      });
      this.getNumberOfApartmentsForRegions(this.wishIndex);
    },

    closeFreeTextSearchPanel() {
      this.showFreeTextSearchPanel = false;

      this.removeDisableScroll('WishArea');
    },

    handleMapInputFieldClick() {
      this.showFreeTextSearchPanel = true;

      this.$nextTick(() => {
        // Focus on input field in panel
        this.$refs.mobileMapInputField.$refs.mapInput.$refs[
          this.fuChrome
        ].focus();

        this.scrollToTopMapInputField();
      });

      this.addDisableScroll('WishArea');
    },

    scrollToTopMapInputField() {
      if (
        this.$refs.freeTextSearchPanelSignup !== undefined &&
        this.showFreeTextSearchPanel
      ) {
        // Scroll to top in panel
        this.$refs.freeTextSearchPanelSignup.$refs.basePanel.scrollTop = 0;

        setTimeout(() => {
          this.$refs.freeTextSearchPanelSignup.$refs.basePanel.scrollTop = 0;
          this.scrollToTop();
        }, 10);
      }

      // Scroll to top in panel when openning, and on page when closing
      if (!this.isMobile) {
        if (this.$refs.areaContainerSignup !== undefined) {
          this.$refs.areaContainerSignup.scrollTop = 0;
        }
        this.scrollToTop();
      }
    },

    handleFocus() {
      this.inputFieldFocused = true;
    },

    handleBlur() {
      this.inputFieldFocused = false;
    },

    handleNextClick() {
      if (this.canContinue) {
        this.$router.push(this.nextLink);
      }

      this.showErrors = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.wish-area-wrapper {
  max-width: 100vw;
  width: 100%;
  margin: 0;
  display: flex;
  background-color: #faf9f8;

  &.content {
    padding: 0;
  }

  @media ($desktop) {
    height: 100vh;
    padding: 0;
  }
}

.area-container {
  position: relative;
  flex: 4;
  display: flex;
  justify-content: center;

  &.area-container--no-scroll {
    @media ($desktop) {
      overflow: hidden;
    }
  }

  @media ($desktop) {
    overflow-y: auto;
    overflow-y: overlay;
  }
}

.area-inner {
  position: relative;
  padding: 0 16px 180px 16px;
  .panel {
    background-color: #faf9f8;
  }

  @media ($desktop) {
    max-width: 450px;
    padding: 20px;
  }

  @media ($mobile) {
    max-width: 100%;
  }
}

.map-container {
  flex: 6;
  height: calc(100vh - env(safe-area-inset-bottom));
}

.mobile-map-wrapper {
  width: 100%;
  position: relative;
  height: 400px;
}

.mobile-ignore-margin {
  width: 100vw;
  position: absolute;
  top: 0;
  left: -16px;
  height: 100%;
}

.selected-label {
  display: block;
  margin-bottom: 12px;
}

.selected-areas {
  margin-bottom: 32px;

  hr {
    margin-top: 0;
    margin-bottom: 32px;
  }
}

.selected-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  color: #0b182c;
  border-radius: 8px;
  border: 1px solid #dddcdb;
  margin: 8px 0;
  padding: 8px 14px;
  font-size: 14px;
}

.delete-btn {
  cursor: pointer;
  height: 16px;
  width: 16px;
  background-position: center;
  background-size: contain;
  background-image: url('@/assets/svg/icon-close-dark.svg');
}

.subtitle {
  margin-top: -12px;
  margin-bottom: 24px;
}

.input-description {
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 6px 0;
  color: #878b9d;
}

.city-select-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 24px;
}

.city-select-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px 16px;
  margin-bottom: 10px;
  color: #0b182c;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
  user-select: none;

  &:hover,
  &:focus {
    border-color: #dddcdb;
  }
}

.panel-button-content {
  display: flex;
  align-items: center;

  .base-icon {
    margin-right: 8px;
  }
}

.error-message {
  margin-bottom: 10px;
  margin-top: -12px;
  font-weight: 600;
  font-size: 0.8rem;
  color: #ff3843;

  @media ($desktop) {
    margin-bottom: 30px;
  }
}

.button-container-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
  margin-top: -5px;

  @media ($desktop) {
    display: none;
  }
}

.chosen-areas-label {
  font-weight: 700;
  font-size: 14px;
}

.matching-apartments-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
  margin-top: -8px;
}

.matching-apartments-label {
  font-size: 14px;
}

.show-chosen-areas-button {
  font-family: inherit;
  padding: 3px 10px;
  font-weight: 700;
  font-size: 14px;
  color: $main-blue;
  background-color: transparent;
  border: none;
  outline: none;
  user-select: none;
  cursor: pointer;
  -webkit-appearance: none;
}

.button-go-to-next-page {
  margin-bottom: env(safe-area-inset-bottom);
  margin-left: auto;
  padding: 12px 20px 12px 26px;
  font-size: 16px;
}
</style>
