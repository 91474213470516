function setUser(state, user) {
  state.user = user;
}

// Update only values passed to updateUser
function updateUser(state, user) {
  for (const [key, value] of Object.entries(user)) {
    state.user[key] = value;
  }
}

function appReady(state) {
  state.ready = true;
}

function setIsAccessOverridable(state, overridable) {
  state.isAccessOverridable = overridable;
}

function setImpersonated(state, { isImpersonated }) {
  state.isImpersonated = isImpersonated;
}

function setAuthenticated(state, { isAuthenticated }) {
  state.isAuthenticated = isAuthenticated;
}

function setTasksToComplete(state, value) {
  state.tasksToComplete = value;
}

export default {
  appReady,
  setImpersonated,
  setAuthenticated,
  setUser,
  updateUser,
  setIsAccessOverridable,
  setTasksToComplete
};
