<template>
  <div class="current-subscription">
    <div
      class="proposition-image"
      :style="[
        currentProposition.residences[0].images.length > 0
          ? {
              backgroundImage: `url(${currentProposition.residences[0].images[0].fileName})`,
              opacity: 1,
              backgroundSize: 'cover'
            }
          : ''
      ]"
    />
    <div v-if="currentProposition" class="subscription-details">
      <div class="details-wrapper">
        <h2 class="details-title">{{ formattedAddress }}</h2>

        <!-- FREEMIUM -->
        <div v-if="currentProposition.isFreemium" class="subscription-info">
          {{ $t('current_subscription_subscription_info_freemium') }}
        </div>

        <!-- TRIAL -->
        <div
          v-else-if="currentProposition.isTrial"
          class="subscription-info"
          v-html="
            $t('current_subscription_subscription_info', {
              nextPaymentDate: nextPaymentDate
            })
          "
        />

        <!-- FREE -->
        <div v-else-if="currentProposition.inFreeArea">
          <span v-if="currentProposition.active">
            {{ $t('current_subscription_subscription_info_active') }}
          </span>
          <span v-else>
            <span v-html="$t('current_subscription_inactive')" />
          </span>
        </div>

        <!-- Support free days -->
        <div
          v-else-if="
            !currentProposition.inFreeArea &&
            currentSubscription.paymentMethod === 'Complimentary'
          "
        >
          <span v-if="currentProposition.active">
            {{ $t('current_subscription_free_days') }} {{ nextPaymentDate }}.
          </span>
          <span v-else>
            <span v-html="$t('current_subscription_inactive')" />
          </span>
        </div>

        <!-- PAID -->
        <div v-else class="subscription-info">
          <span v-if="currentProposition.active">
            {{
              $t('current_subscription_your_subscription', {
                monthlyPrice: monthlyPrice
              })
            }}
            {{
              currentSubscription.cancelledAt
                ? $t('current_subscription_is_being_canceled')
                : $t('current_subscription_is_being_extended')
            }}
            {{ $t('current_subscription_automatically') }}
            {{ nextPaymentDate }}.
          </span>
          <span v-else>
            <span v-html="$t('current_subscription_inactive')" />
          </span>
          <br />
          <span v-if="!currentSubscription.cancelledAt">
            {{
              $t('current_subscription_extended_with', {
                days: currentSubscription.days
              })
            }}
            {{
              swedishPaymentMethod === $t('current_subscription_for_free')
                ? '.'
                : ` ${$t('current_subscription_via')} ${swedishPaymentMethod}.`
            }}
          </span>
        </div>
      </div>

      <div class="button-wrapper">
        <!-- Canceled (only works with Klarna, not Adyen) -->
        <secondary-button
          v-if="
            currentSubscription.cancelledAt && swedishPaymentMethod !== 'Adyen'
          "
          :style="buttonStyle"
          :full-width="isMobile"
          @click.native="handleRenewSubscription"
          >{{ $t('current_subscription_automatic_renewal') }}</secondary-button
        >

        <!-- Freemium -->
        <secondary-button
          v-else-if="currentProposition.isFreemium"
          :style="buttonStyle"
          :full-width="isMobile"
          @click.native="goToPaymentPage"
          >{{ $t('freemium_banner_cta') }}</secondary-button
        >

        <!-- Trial -->
        <secondary-button
          v-else-if="currentSubscription.paymentMethod === 'Trial'"
          :style="buttonStyle"
          :full-width="isMobile"
          @click.native="goToPaymentPage"
          >{{ $t('current_subscription_upgrade') }}</secondary-button
        >

        <!-- Support free -->
        <secondary-button
          v-else-if="
            currentSubscription.paymentMethod === 'Complimentary' &&
            !currentProposition.inFreeArea
          "
          :style="buttonStyle"
          :full-width="isMobile"
          @click.native="goToPaymentPage"
          >{{ $t('current_subscription_automatic_renewal') }}</secondary-button
        >

        <!-- Paid -->
        <secondary-button
          v-else-if="
            currentSubscription.paymentMethod !== 'Complimentary' &&
            showChangeSubscription &&
            hasPaymentToken
          "
          :style="buttonStyle"
          :full-width="isMobile"
          @click.native="() => openCancelModal({ changeSubscription: true })"
          >{{
            $t('current_subscription_change_subscription')
          }}</secondary-button
        >

        <secondary-button
          v-else-if="
            currentSubscription.paymentMethod === 'Complimentary' &&
            currentProposition.inFreeArea &&
            !currentProposition.active
          "
          :style="buttonStyle"
          :full-width="isMobile"
          @click.native="handleRenewSubscription"
          >{{ $t('current_subscription_automatic_renewal') }}</secondary-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import SecondaryButton from '../Buttons/Secondary';

export default {
  name: 'CurrentSubscription',

  components: {
    SecondaryButton
  },

  props: {
    currentProposition: {
      type: Object,
      required: true
    },
    currentSubscription: {
      type: Object,
      required: true
    },
    openCancelModal: {
      type: Function,
      required: true
    },
    handleRenewSubscription: {
      type: Function,
      required: true
    },
    goToPaymentPage: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      buttonStyle: {
        borderRadius: '3px',
        color: 'black',
        textTransform: 'uppercase',
        fontWeight: 600,
        marginTop: '20px'
      }
    };
  },

  computed: {
    ...mapState('payment', ['hasPaymentToken']),
    ...mapGetters({
      isMobile: 'screenSize/isMobile',
      products: 'handleSubscription/products'
    }),

    showChangeSubscription() {
      return this.products.length > 1;
    },

    formattedAddress() {
      let formattedAddress =
        this.currentProposition.residences[0].streetAddress;

      if (this.currentProposition.residences[1]) {
        formattedAddress += ` & ${this.currentProposition.residences[1].streetAddress}`;
      }

      return formattedAddress;
    },

    monthlyPrice() {
      if (this.$country.currentCountry === 'se') {
        return Math.floor(
          this.currentSubscription.price / (this.currentSubscription.days / 30)
        ).toString();
      }

      return (
        this.currentSubscription.price /
        (this.currentSubscription.days / 30)
      ).toFixed(2);
    },

    nextPaymentDate() {
      if (
        this.currentSubscription.paymentMethod === 'Complimentary' &&
        this.currentProposition.inFreeArea
      ) {
        return '';
      }

      return this.currentProposition.paidUntil;
    },

    swedishPaymentMethod() {
      const paymentMethods = {
        None: 'Ingen',
        CreditCard: 'Kreditkort',
        Invoice: 'Billogram faktura',
        Complimentary: 'Gratis',
        Apple: 'Apple',
        Trial: 'Testperiod',
        Klarna: 'Klarna',
        Adyen: 'Adyen'
      };

      if (paymentMethods[this.currentSubscription.paymentMethod]) {
        return paymentMethods[this.currentSubscription.paymentMethod];
      }

      return this.currentSubscription.paymentMethod;
    }
  }
};
</script>

<style lang="scss" scoped>
.current-subscription {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-radius: 8px;
  padding: 20px;

  @media ($desktop) {
    border: 1px solid rgba(0, 0, 0, 0.17);
  }

  @media ($mobile) {
    flex-direction: column;
  }
}

.details-title {
  font-size: 1.6rem;
  font-weight: 700;
  margin-block-start: 0;
  margin-block-end: 5px;
}

.proposition-image {
  height: 170px;
  width: 300px;
  background-position: center;
  background-size: contain;
  background-color: #f7f7f9;
  background-image: url('../../assets/svg/placeholderImage/placeholder-image-large.svg');
  opacity: 0.7;
  border-radius: 5px;

  @media ($mobile) {
    width: 100%;
    height: 25vh;
    margin-bottom: 14px;
  }

  @media ($desktop) {
    margin-right: 12px;
  }
}

.subscription-details {
  display: flex;
  height: 170px;
  flex-direction: column;
  justify-content: space-between;
}

.subscription-info {
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 1.3rem;

  @media ($desktop) {
    line-height: 24px;
  }
}
</style>
