import i18n from '@/i18n';

const types = {
  County: i18n.t('wish_area_county'),
  PostalArea: i18n.t('wish_area_postal_area'),
  PostalCode: i18n.t('wish_area_postal_code'),
  Municipality: i18n.t('wish_area_municipality'),
  PostalCodeDistrict: i18n.t('wish_area_postal_code_district'),
  ThreeDigitPostalCode: i18n.t('wish_area_three_digit_postal_code'),
  Urban: i18n.t('wish_area_urban'),
  District: i18n.t('wish_area_district')
};

const searchResult = state => {
  const formattedResults = state.searchResult.map(r => ({
    ...r,
    htmlText: `<span style="font-weight:600">${r.name}</span>, ${r.description}
        &nbsp;<span style="margin-left:auto; opacity:.8; font-size: .8rem">${
          types[r.type]
        }</span>`
  }));

  return formattedResults;
};

const searchTerm = state => state.searchTerm;
const selectedAreas = state => state.selectedAreas;
const wishAreaOptions = state => state.wishAreaOptions;
const multiPolygon = state => state.multiPolygon;
const searchLoading = state => state.searchLoading;
const loading = state => state.loading;
const searchNoResultsFound = state => state.searchNoResultsFound;
const mustSelectNewAreas = state => state.mustSelectNewAreas;

export default {
  searchResult,
  searchTerm,
  selectedAreas,
  wishAreaOptions,
  multiPolygon,
  searchLoading,
  loading,
  searchNoResultsFound,
  mustSelectNewAreas
};
