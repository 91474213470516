<template>
  <section class="mobile-header">
    <Logo />

    <span v-if="debugText" class="color-warning">
      {{ debugText }}
    </span>

    <template v-if="appReady">
      <template v-if="isAuthenticated">
        <div id="js-notification-toggle" class="notification-center">
          <div
            class="notification-toggle-icon-container"
            @click="handleNotificationCenterClick"
          >
            <BaseIcon
              :icon="notificationCenterIcon"
              :width="40"
              :height="40"
              :class="[
                'notification-toggle-icon',
                notificationCenterOpen ? 'is-open' : ''
              ]"
            />
            <div
              v-if="unseenNotificationsCount && unseenNotificationsCount > 0"
              class="badge-count"
            >
              {{ unseenNotificationsCount }}
            </div>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="icons-group">
          <router-link class="icons-group-icon" :to="$routes.SEARCH">
            <BaseIcon icon="search-header" />
          </router-link>
          <BaseIcon icon="menu" class="icons-group-icon" @click="toggleMenu" />
        </div>
      </template>
    </template>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { isDebug } from '../../utils/debug';
import Logo from '../DesktopHeader/Logo';

export default {
  name: 'MobileHeader',

  components: {
    Logo
  },

  props: {
    isAuthenticated: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      debugText: null
    };
  },

  computed: {
    ...mapGetters({
      notificationCenterOpen: 'menu/isNotificationCenterOpen',
      unseenNotificationsCount: 'notificationCenter/unseenNotificationsCount',
      appReady: 'app/ready'
    }),

    notificationCenterIcon() {
      if (this.notificationCenterOpen) {
        return 'bell-large-blue';
      } else {
        return 'bell-large';
      }
    }
  },

  created() {
    if (isDebug()) {
      const t = setInterval(() => {
        if (this.appReady) {
          clearInterval(t);
          this.debugText = 'DEBUG';
        }
      }, 0);
    }
  },

  methods: {
    ...mapActions({
      toggleMenu: 'menu/toggleMenu',
      toggleNotificationCenter: 'menu/toggleNotificationCenter'
    }),

    handleNotificationCenterClick(e) {
      e.stopPropagation();
      this.toggleNotificationCenter();
    }
  }
};
</script>

<style lang="scss" scoped>
.mobile-header {
  position: relative;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  margin: 0 auto;
  padding: 0 16px;
  background-color: #fff;
  border-bottom: 1px solid #f1f2f6;
  z-index: 99;

  .must-select-new-areas & {
    pointer-events: none;
  }
}

.notification-toggle-icon-container {
  display: grid;
  place-items: center;
  width: 40px;
  height: 40px;
  margin-left: 15px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  .must-select-new-areas & {
    display: none;
  }
}

.notification-toggle-icon {
  transition: transform 0.1s ease-in-out;

  &.is-open {
    transform: rotate(20deg);
  }
}

.icons-group {
  display: flex;
  flex-shrink: 0;
  cursor: pointer;
}

.icons-group-icon {
  margin-left: 20px;
}

.color-warning {
  font-weight: bold;
  color: red;
}

.badge-count {
  position: absolute;
  top: 10px;
  right: 7px;
  transform: translateX(-50%);
  height: 14px;
  min-width: 14px;
  padding: 0 3px;
  font-size: 10px;
  line-height: 14px;
  border-radius: 17px;
  background: #409fff;
  color: #fff;
  font-weight: 600;
  text-align: center;
}
</style>
