<template>
  <div class="labels">
    <div v-if="conversationOpen" class="label messenger-label">
      <div v-if="hasNewChat" class="unread-dot"></div>
      {{ $t('amenities_labels_conversation')
      }}{{ isMobile ? '' : $t('amenities_labels_open') }}
    </div>
    <div v-for="amenity in amenities" :key="amenity" class="label">
      {{ amenity }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

export default {
  name: 'AmenitiesLabels',

  props: {
    residence: {
      type: Object,
      required: true
    },
    conversationOpen: {
      type: Boolean,
      default: false
    },
    hasNewChat: {
      type: Boolean,
      default: false
    },
    swap: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile'
    }),

    amenities() {
      const { residence } = this;
      const amenities = [];

      if (residence.residenceType === 2)
        amenities.push(this.$t('amenities_residence_type_2'));
      if (residence.elevator)
        amenities.push(this.$t('amenities_labels_elevator'));
      if (residence.balcony)
        amenities.push(this.$t('amenities_labels_balcony'));
      if (residence.bathtub)
        amenities.push(this.$t('amenities_labels_bathtub'));
      if (residence.formedCooperative)
        amenities.push(this.$t('amenities_labels_is_coop'));
      if (residence.becomingCoop)
        amenities.push(this.$t('amenities_labels_is_becoming_coop'));
      shuffleArray(amenities);

      if (amenities.length > 2 && this.conversationOpen) {
        const shortenedAmenities = amenities.slice(0, 2);
        shortenedAmenities.push(`+${amenities.length - 2}`);
        return shortenedAmenities;
      }

      return amenities;
    }
  }
};
</script>

<style lang="scss" scoped>
.labels {
  display: flex;
}

.label {
  border: 1px solid rgba(135, 139, 157, 0.58);
  font-weight: 600;
  font-size: 0.7rem;
  color: rgba(27, 45, 65, 0.7);
  border-radius: 3px;
  margin-right: 3px;
  padding: 4px;
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap;

  &.messenger-label {
    color: $transfer-blue;
    border: none;
    border-radius: 3px;
    background-color: $baby-blue;
    display: flex;
    align-items: center;
  }
}

.unread-dot {
  height: 8px;
  width: 8px;
  margin: 0 4px;
  background-color: $transfer-blue;
  border-radius: 50%;
}
</style>
