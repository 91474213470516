import services from './services';
import { MATCH_STATUS_TYPES } from '@/store/modules/matches';

async function getMatches(context, matchStatus) {
  context.commit('setLoadingMatches', { isLoadingMatches: true });

  const matches = await services.fetchMatches(
    context.rootState.myPropositions.primaryProposition.propositionId,
    matchStatus
  );
  context.commit('setMatches', { matches, matchStatus });

  context.commit('setLoadingMatches', { isLoadingMatches: false });
}

async function refetchSingleMatch(context, { myPropositionId, matchId }) {
  const match = await services.refetchSingleMatch(myPropositionId, matchId);

  // Loop through all matches and replace the old match(es) with the new refetched one
  const allMatches = Object.values(context.state.matches);
  allMatches.forEach(matchesInTab => {
    if (matchesInTab === null) return;
    // Find the index of the refetched match(es)
    const matchIndex = matchesInTab.findIndex(match => match.id === matchId);

    // Replace the old match(es) with the new refetched one
    if (matchIndex !== -1) {
      const matches = [...matchesInTab];
      matches.splice(matchIndex, 1, match);
      context.commit('setMatches', {
        matches,
        matchStatus: context.state.activeTab
      });
    }
  });

  return match;
}

async function setActiveTab(
  context,
  { activeTab, refetchStatusCounts = false }
) {
  context.commit('setActiveTab', activeTab);

  await context.dispatch('getMatches', activeTab);

  if (refetchStatusCounts) {
    const updatedCounts = { ...context.state.matchStatusCounts };
    updatedCounts[activeTab] = context.state.matches[activeTab]?.length;
    context.commit('setMatchStatusCounts', updatedCounts);
  }
}

async function readUpdatedMatch(context, matchId) {
  await services.readUpdatedMatch(
    context.rootState.myPropositions.primaryProposition.propositionId,
    matchId
  );
}

async function getInitialMatchStatusCounts(context, matchId) {
  const summary = await services.fetchInitialMatchStatusCounts(
    context.rootState.myPropositions.primaryProposition.propositionId,
    matchId
  );

  context.commit('setInitialMatchStatusCounts', summary);

  return summary;
}

async function init(context, { matchId }) {
  context.commit('setLoadingStatusCounts', { isLoadingStatusCounts: true });

  context.commit('resetMatches');

  const summary = await getInitialMatchStatusCounts(context, matchId);
  context.commit(
    'setMatchStatusCounts',
    context.state.initialMatchStatusCounts
  );

  if (summary.category === MATCH_STATUS_TYPES.UNANSWERED) {
    setActiveTab(context, { activeTab: MATCH_STATUS_TYPES.UNANSWERED });
  } else if (summary.category === MATCH_STATUS_TYPES.UNREAD) {
    setActiveTab(context, { activeTab: MATCH_STATUS_TYPES.UNREAD });
  } else if (summary.category === MATCH_STATUS_TYPES.UPDATED) {
    setActiveTab(context, { activeTab: MATCH_STATUS_TYPES.UPDATED });
  } else if (summary.category === MATCH_STATUS_TYPES.ACTIVE) {
    setActiveTab(context, { activeTab: MATCH_STATUS_TYPES.ACTIVE });
  } else if (summary.category === MATCH_STATUS_TYPES.INACTIVE) {
    setActiveTab(context, { activeTab: MATCH_STATUS_TYPES.INACTIVE });
  } else {
    setActiveTab(context, { activeTab: MATCH_STATUS_TYPES.ACTIVE });
  }

  context.commit('setLoadingStatusCounts', { isLoadingStatusCounts: false });
}

function setNewMatch(context, match) {
  context.commit('setNewMatch', match);
}

export default {
  getMatches,
  refetchSingleMatch,
  setActiveTab,
  readUpdatedMatch,
  getInitialMatchStatusCounts,
  setNewMatch,
  init
};
