<template>
  <transition v-if="showContextualTutorial && showTutorial" name="tut-slide-up">
    <div
      ref="modal"
      class="contextual-tutorial-container"
      :style="{ 'z-index': overlay ? '200' : '20' }"
    >
      <div
        v-if="showModalWindow || tutStatus === TUTORIAL_STATUS_TYPES.COMPLETED"
        class="progress-bar-container"
        :style="
          tutStatus === TUTORIAL_STATUS_TYPES.COMPLETED && {
            'z-index': '300'
          }
        "
      >
        <ProgressBar
          :hidden="tutStatus === TUTORIAL_STATUS_TYPES.COMPLETED"
          :percentage="progressBar"
        />
      </div>
      <ContextualTutModal
        :hide-close-button="true"
        :show-modal-content="showModalContent"
        @close="handleCloseModal"
        @nextHighlightSwap="handleHighlightSwap"
        @completed="handleCompleted"
      >
        <transition name="tut-slide-up" appear>
          <div
            :class="
              tutStatus === TUTORIAL_STATUS_TYPES.COMPLETED
                ? 'modal-svg-completed-success'
                : 'modal-svg-get-started'
            "
          />
        </transition>
      </ContextualTutModal>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ContextualTutModal from '@/components/Modals/ContextualTutorial/ContextualTutModal.vue';
import ProgressBar from '@/components/ContextualTutorial/ProgressBar.vue';
import {
  TUTORIAL_STATUS_TYPES,
  TUTORIAL_STEPS_TYPES
} from '../../store/modules/tutorial';
import { getCookie, setCookie } from '@/utils/cookies';

export default {
  name: 'ContextualTutorial',
  components: {
    ContextualTutModal,
    ProgressBar
  },

  props: {
    showTutorial: {
      type: Boolean,
      default: false
    },
    escClose: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      showModalWindow: true,
      showModalContent: true,
      overlay: true,
      TUTORIAL_STATUS_TYPES,
      TUTORIAL_STEPS_TYPES,
      tutorialCookieName: 'lb-tutorial',
      tutorialCookieExp: 7,
      hide: false,
      onModalClosed: () => {},
      handleStep: () => {}
    };
  },

  computed: {
    ...mapGetters({
      progressBar: 'tutorial/progressBar',
      showContextualTutorial: 'tutorial/showContextualTutorial',
      interestMarks: 'tutorial/tutInterestMarks',
      tutStatus: 'tutorial/status',
      tutActiveStep: 'tutorial/activeStep',
      showGlobalModal: 'ui/showGlobalModal',
      markInterestDone: 'interests/markInterestDone'
    }),
    combinedWatcher() {
      return [this.markInterestDone, this.showGlobalModal, this.interestMarks];
    },
    hasLocalStorageCookie() {
      return localStorage.getItem(this.tutorialCookieName) !== null;
    }
  },

  watch: {
    // Mark tutorial as complete in BE after first interest
    // to prevent user getting stuck
    interestMarks(newVal) {
      if (newVal === 1) {
        this.$emit('mark-tutorial-as-complete');
      }
    },

    combinedWatcher(currentValue, previousValue) {
      const [
        markInterestDoneCurrent,
        showGlobalModalCurrent,
        interestMarksCurrent
      ] = currentValue;

      const showGlobalModalPrev = previousValue[1];

      if (
        (showGlobalModalPrev === 'FirstNoInterest' &&
          showGlobalModalCurrent === false) ||
        (showGlobalModalPrev === 'FirstYesInterest' &&
          showGlobalModalCurrent === false)
      ) {
        this.onModalClosed();
      }

      if (this.tutStatus === TUTORIAL_STATUS_TYPES.STARTED) {
        this.handleStep = () => {
          this.setTutActiveStep({
            step: TUTORIAL_STEPS_TYPES.FIRST_INTEREST
          });

          this.setProgressBar(40);

          this.setTutorialCookie(this.tutorialCookieName, {
            tutStatus: TUTORIAL_STATUS_TYPES.STARTED,
            tutActiveStep: TUTORIAL_STEPS_TYPES.FIRST_INTEREST,
            tutInterestMarks: 0,
            progressBar: 40,
            showContextualTutorial: true
          });

          this.scrollToTop();
        };

        switch (interestMarksCurrent) {
          case 1:
            this.handleStep = () => {
              this.setTutActiveStep({
                step: TUTORIAL_STEPS_TYPES.CONTINUE_INTEREST
              });

              this.setProgressBar(60);

              this.setTutorialCookie(this.tutorialCookieName, {
                tutStatus: TUTORIAL_STATUS_TYPES.STARTED,
                tutActiveStep: TUTORIAL_STEPS_TYPES.CONTINUE_INTEREST,
                tutInterestMarks: 1,
                progressBar: 60,
                showContextualTutorial: false
              });
            };
            break;
          case 2:
            this.handleStep = () => {};

            this.setProgressBar(75);

            this.setTutorialCookie(this.tutorialCookieName, {
              tutStatus: TUTORIAL_STATUS_TYPES.STARTED,
              tutActiveStep: TUTORIAL_STEPS_TYPES.CONTINUE_INTEREST,
              tutInterestMarks: 2,
              progressBar: 75,
              showContextualTutorial: false
            });
            break;
          case 3:
            this.handleStep = () => {
              this.completeTutorial();
            };
            break;

          default:
            this.handleStep = () => {};
        }

        if (markInterestDoneCurrent) {
          if (showGlobalModalCurrent) {
            this.onModalClosed = this.handleStep;
          } else {
            this.handleStep();
          }
        }
      }
    }
  },

  mounted() {
    window.addEventListener('keyup', this.onEscapeKeyUp);

    // if no cookie present, set it initially
    if (getCookie(this.tutorialCookieName) === '') {
      this.setTutorialCookie(this.tutorialCookieName, {
        tutStatus: TUTORIAL_STATUS_TYPES.STARTED,
        tutActiveStep: TUTORIAL_STEPS_TYPES.HIGHLIGHT_SWAP,
        tutInterestMarks: 0,
        progressBar: 25,
        showContextualTutorial: true
      });
      // set default state
      this.setProgressBar(25);
      this.setShowContextualTutorial(true);
    } else {
      let cookie = '';
      // fallback to local storage cookie if already exists
      if (this.hasLocalStorageCookie) {
        cookie = JSON.parse(localStorage.getItem(this.tutorialCookieName));
      } else {
        cookie = JSON.parse(getCookie(this.tutorialCookieName));
      }

      if (cookie && cookie.tutInterestMarks > 0) {
        // set previous state from cookie
        this.setProgressBar(cookie.progressBar);
        this.setTutStatus({ status: cookie.tutStatus });
        this.setTutActiveStep({ step: cookie.tutActiveStep });
        this.setShowContextualTutorial(cookie.setShowContextualTutorial);
        this.setTutInterestMarks(cookie.tutInterestMarks);
      } else {
        // set default state
        this.setProgressBar(25);
        this.setShowContextualTutorial(true);
      }
    }
    // always set to started
    this.setTutStatus({ status: TUTORIAL_STATUS_TYPES.STARTED });
  },

  destroy() {
    window.removeEventListener('keyup', this.onEscapeKeyUp);
  },

  methods: {
    completeTutorial() {
      this.setTutStatus({
        status: TUTORIAL_STATUS_TYPES.COMPLETED
      });

      this.setProgressBar(100);

      this.setTutActiveStep({
        status: TUTORIAL_STEPS_TYPES.TUTORIAL_SUCCESS
      });

      // clear cookie when completed
      if (this.hasLocalStorageCookie) {
        localStorage.removeItem(this.tutorialCookieName);
      }
      setCookie(this.tutorialCookieName, '', this.tutorialCookieExp);

      // show tutorial window success after half a second
      setTimeout(() => {
        this.setShowContextualTutorial(true);
      }, 500);
    },

    setTutorialCookie(cookieName, object) {
      setCookie(cookieName, JSON.stringify(object), this.tutorialCookieExp);
    },

    onEscapeKeyUp(event) {
      if (event.which === 27) {
        if (this.escClose) {
          this.setShowContextualTutorial(false);
          this.removeDisableScroll('ContextualTutModal');
        }
      }
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },

    ...mapActions({
      setTutActiveStep: 'tutorial/setTutActiveStep',
      setTutStatus: 'tutorial/setTutStatus',
      setTutInterestMarks: 'tutorial/setTutInterestMarks',
      setProgressBar: 'tutorial/setProgressBar',
      setGlobalModal: 'ui/setGlobalModal',
      setShowContextualTutorial: 'tutorial/setShowContextualTutorial',
      removeDisableScroll: 'ui/removeDisableScroll'
    }),

    handleCompleted() {
      this.$emit('completed');
    },

    handleCloseModal() {
      this.showModalWindow = false;
    },

    handleHighlightSwap() {
      this.setTutActiveStep({ step: TUTORIAL_STEPS_TYPES.HIGHLIGHT_SWAP });
      this.setProgressBar(25);
      this.showModalWindow = false;
      // scroll to top
      window.scrollTo(0, 0);
    }
  }
};
</script>

<style lang="scss" scoped>
.contextual-tutorial-container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  margin: 0;
  z-index: 100;

  .progress-bar-container {
    display: flex;
    top: 70px;
    position: relative;
    justify-content: center;
    z-index: 0;
    min-height: 60px;
    min-height: 20px;
    width: 100%;

    @media ($desktop) {
      margin-top: -100px;
    }
  }
}

.modal-svg-get-started {
  background-image: url('@/assets/svg/tutorial-getstarted.svg');
  background-repeat: no-repeat;
  width: 193px;
  height: 176px;
  margin-bottom: 15px;
}

.modal-svg-completed-success {
  background-image: url('@/assets/svg/tutorial-completed-success.svg');
  background-repeat: no-repeat;
  margin-bottom: 20px;
  height: 178px;
  width: 184px;
}

/* smaller screens */
@media only screen and (max-width: 375px) {
  .progress-bar-container {
    position: relative;
    top: -16.5em;
  }
}

@media ($desktop) {
  .progress-bar-container {
    padding-top: 8em;
    top: 14em;
  }
}
</style>
