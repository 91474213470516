import { GrowthBook } from '@growthbook/growthbook';
import { growthbookApi } from '@/utils/axiosConfig';
import { isDebug } from '@/utils/debug';
import { currentCountry } from '@/plugins/country/module';
import events from '@/utils/helpers/events';
import store from '@/store/store';
import { getCookie, setCookie } from '@/utils/cookies';
import { log } from '../../utils/logger';

let growthbook = null;

function getExperimentsEventPayload() {
  const payload = [];
  try {
    const results = growthbook.getAllResults();
    for (const experiment of results) {
      const name = experiment[0];
      const { result } = experiment[1];
      if (result.inExperiment) {
        payload.push(`${name} - ${result.name}`);
      }
    }
  } catch (e) {
    console.log(e.message);
  }
  return payload;
}

function getUserId() {
  const user = store.getters['app/user'];
  let userId = undefined;

  if (user && user.userId) {
    userId = user.userId;
  }

  return userId;
}

function getUserCreated() {
  const user = store.getters['app/user'];
  let userCreated = undefined;

  if (user && user.registeredAt) {
    userCreated = new Date(user.registeredAt).getTime();
  }

  return userCreated;
}

function getPrimaryPropositionSubscriptionType() {
  return store.getters['myPropositions/primaryPropositionSubscriptionType'];
}

export async function setGrowthbookAttributes(
  extraAttributesObj = {},
  storeInCookie = false
) {
  if (!growthbook) {
    window.Logger.warn(
      'GrowthBook failed to initialize. Feature flags and experiments not active.'
    );
    return false;
  }

  try {
    const deviceId = getCookie('lb-sid');

    await growthbook.setAttributes({
      ...growthbook.getAttributes(),
      country_and_user_id: `${currentCountry.toUpperCase()}_${getUserId()}`,
      subscription_type: getPrimaryPropositionSubscriptionType(),
      user_id: getUserId(),
      user_created: getUserCreated(),
      country: currentCountry.toUpperCase(),
      deviceId: deviceId || null,
      ...extraAttributesObj
    });
  } catch (error) {
    log('GrowthBook could not set attributes');
  }

  try {
    if (storeInCookie) {
      const enabledFeatures = [];
      for (const key of Object.keys(growthbook.getFeatures())) {
        if (growthbook.isOn(key)) {
          enabledFeatures.push(key);
        }
      }
      const cookieValue = JSON.stringify(enabledFeatures);
      const urlParts = window.location.hostname.split('.');
      const domain = `${urlParts[1]}.${urlParts[2]}`;

      //const currentExperiments = getCookie('lb-experiments');
      //if (currentExperiments !== cookieValue) {
      setCookie('lb-experiments', cookieValue, 1, domain);

      const experimentsPayload = getExperimentsEventPayload();

      events.emitEvent(
        events.eventTypes.EXPERIMENTS,
        'Updated',
        experimentsPayload
      );
      //}
    }
  } catch {
    log('GrowthBook Could not set experiments cookie');
  }
}

export async function initGrowthbook() {
  if (growthbook) {
    return growthbook;
  }

  try {
    const { data } = await growthbookApi.get();

    growthbook = new GrowthBook({
      // TODO: Switch to fetch api when we feel comfortable not using axios
      // apiHost: getGrowthBookEndpoint()
      enableDevMode: isDebug(),
      trackingCallback: (experiment, result) => {
        events.emitEvent(events.eventTypes.EXPERIMENT, experiment.key, {
          experiment,
          result
        });
      },
      attributes: {
        country: currentCountry.toUpperCase()
      }
    });
    growthbook.setFeatures(data.features);

    window.Logger.info('Growthbook plugin initialized');
  } catch (error) {
    window.Logger.error('GrowthBook Vue plugin initialization error', error);
  }

  await setGrowthbookAttributes();
}

export function isFeatureFlagEnabled(featureFlag) {
  if (!growthbook) {
    window.Logger.warn(
      'GrowthBook failed to initialize. Feature flags and experiments not active.'
    );
    return false;
  }

  //window.Logger.log(`Feature ${featureFlag}:`, growthbook.isOn(featureFlag));
  return growthbook.isOn(featureFlag);
}

export function getFeatureValue(featureFlag, fallbackValue) {
  if (!growthbook) {
    window.Logger.warn(
      'GrowthBook failed to initialize. Feature flags and experiments not active.'
    );
    return fallbackValue;
  }

  // window.Logger.debug(
  //   `Feature ${featureFlag}:`,
  //   growthbook.getFeatureValue(featureFlag, fallbackValue)
  // );

  return growthbook.getFeatureValue(featureFlag, fallbackValue);
}

export function waitForGrowthbookInit() {
  return new Promise(resolve => {
    if (growthbook) {
      resolve();
      return;
    }

    let waitIval = setInterval(() => {
      if (!growthbook) {
        return;
      }

      clearInterval(waitIval);
      resolve();
    }, 50);
  });
}

export default {
  getFeatureValue,
  isFeatureFlagEnabled,
  initGrowthbook,
  setGrowthbookAttributes,
  waitForGrowthbookInit
};
