import { getCookie, setCookie } from './cookies';
import { currentCountry } from '@/plugins/country/module';

let scriptSrc;
switch (currentCountry) {
  case 'se':
    scriptSrc =
      'https://widget.usersnap.com/global/load/20c909de-fca9-4927-8324-60d00114efa0?onload=onUsersnapLoad';
    break;
  case 'de':
    scriptSrc =
      'https://widget.usersnap.com/global/load/86dc1fa1-5483-4a4d-ae2e-6e53e9e47066?onload=onUsersnapLoad';
    break;
  case 'nl':
    scriptSrc =
      'https://widget.usersnap.com/global/load/29380c34-f0bf-44ff-a8ff-9a694bd36404?onload=onUsersnapLoad';
    break;
  case 'dk':
    scriptSrc =
      'https://widget.usersnap.com/global/load/c2b3e675-4265-4bc7-9015-f79ee891a55e?onload=onUsersnapLoad';
    break;
  default:
    scriptSrc = '';
    break;
}

function userSnapFeatureWidget(featureName, cookieValue = true) {
  const usersnapScript = document.getElementById('usersnap-widget-script');
  const hasSeen = getCookie('lb-usersnap-' + featureName);

  if (!usersnapScript && !hasSeen) {
    window.onUsersnapLoad = function (api) {
      api.init();
      api.logEvent(featureName);

      window.Usersnap = api;
    };
    var script = document.createElement('script');
    script.defer = 1;
    script.src = scriptSrc;
    script.id = 'usersnap-widget-script';
    document.getElementsByTagName('head')[0].appendChild(script);

    // Has seen cookie
    setCookie('lb-usersnap-' + featureName, cookieValue, 180);
  } else {
    if (!hasSeen) {
      window.Usersnap.logEvent(featureName);
    }
  }
}

export { userSnapFeatureWidget };
