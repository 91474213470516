<template>
  <div class="labels">
    <div v-for="amenity in amenities" :key="amenity" class="label">
      {{ amenity }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'AmenitiesLabels',

  props: {
    residence: {
      type: Object,
      required: true
    }
  },

  computed: {
    amenities() {
      const amenities = [];

      if (this.residence.residenceType === 2)
        amenities.push(this.$t('amenities_residence_type_2'));
      if (this.residence.elevator)
        amenities.push(this.$t('amenities_labels_elevator'));
      if (this.residence.balcony)
        amenities.push(this.$t('amenities_labels_balcony'));
      if (this.residence.bathtub)
        amenities.push(this.$t('amenities_labels_bathtub'));
      if (this.residence.formedCooperative)
        amenities.push(this.$t('amenities_labels_is_coop'));
      if (this.residence.becomingCoop)
        amenities.push(this.$t('amenities_labels_is_becoming_coop'));

      return amenities;
    },

    hasMoreThanTwoLabels() {
      return this.amenities?.length > 2;
    }
  },
  watch: {
    residence: {
      handler() {
        this.$emit('update-class', this.hasMoreThanTwoLabels);
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
.labels {
  display: flex;
}

.label {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 5px 8px;
  background: #ffffff;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: 16px;
  font-size: 12px;
  line-height: 10px;
  font-weight: 600;
  white-space: nowrap;
}
</style>
