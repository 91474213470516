import axios from 'axios';
import signupServices from './services';
import i18n, { routeLocale } from '@/i18n';
import { trackPropositionCreated } from '@/utils/mixpanel';
import events from '@/utils/events';
import { currentCountry } from '@/plugins/country/module';
import { router } from '@/main';
import { addDataLayer } from '@/utils/gtm';

const setSwapType = (context, { swapType }) => {
  context.commit('setSwapType', { swapType });
};

const setWishMinRooms = (context, { rooms, wishIndex }) => {
  context.commit('setWishMinRooms', { rooms, wishIndex });
};
const setWishMinSize = (context, { size, wishIndex }) => {
  context.commit('setWishMinSize', { size, wishIndex });
};
const setWishMaxRent = (context, { rent, wishIndex }) => {
  context.commit('setWishMaxRent', { rent, wishIndex });
};
const setResidenceRooms = (context, { rooms, residenceIndex }) => {
  context.commit('setResidenceRooms', { rooms, residenceIndex });
};
const setResidenceAddress = (context, { address, residenceIndex }) => {
  context.commit('setResidenceAddress', { address, residenceIndex });
};
const setResidenceCoordinates = (context, { coordinates, residenceIndex }) => {
  context.commit('setResidenceCoordinates', { coordinates, residenceIndex });
};
const setResidenceNumber = (context, { number, residenceIndex }) => {
  context.commit('setResidenceNumber', { number, residenceIndex });
};
const setResidenceArea = (context, { area, residenceIndex }) => {
  context.commit('setResidenceArea', { area, residenceIndex });
};
const setResidenceFloor = (context, { floor, residenceIndex }) => {
  context.commit('setResidenceFloor', { floor, residenceIndex });
};
const setResidenceSqm = (context, { sqm, residenceIndex }) => {
  context.commit('setResidenceSqm', { sqm, residenceIndex });
};
const setResidenceRent = (context, { rent, residenceIndex }) => {
  context.commit('setResidenceRent', { rent, residenceIndex });
};
const setResidenceType = (context, { type, residenceIndex }) => {
  context.commit('setResidenceType', { type, residenceIndex });
};

const setResidenceZipCode = (context, { zipCode, residenceIndex }) => {
  context.commit('setResidenceZipCode', { zipCode, residenceIndex });
};

const setSwapTimeFrame = (context, timeFrame) => {
  context.commit('setSwapTimeFrame', timeFrame);
};

const setName = (context, { name }) => {
  context.commit('setName', { name });
};

const setSSOProfile = (context, ssoProfile) => {
  context.commit('setSSOProfile', ssoProfile);
};

const setEmail = (context, { email }) => {
  const isValid = signupServices.validateEmail(email);
  context.commit('setLoginError', { error: null });
  context.commit('setSignupError', { error: null });
  context.commit('setEmail', { email, isValid });
};

async function waitForUserInitiated(context) {
  let user = context.rootGetters['app/user'];

  if (user === null) {
    await new Promise(resolve => {
      setTimeout(async () => {
        await context.dispatch('app/initAppData', false, { root: true });
        user = context.rootGetters['app/user'];
        resolve();
      }, 150);
    });

    await waitForUserInitiated(context); // Recursive call
  }
}

async function register(context) {
  context.commit('setSignupError', { error: null });
  context.commit('setLoading', { loading: true });

  const { email, name, ssoProfile } = context.state;
  try {
    const { status, message } = await signupServices.registerUser(
      email,
      name,
      ssoProfile
    );

    if (status !== 200) {
      context.commit('setSignupError', {
        error: i18n.t('signup_error_email_already_exists')
      });
      context.commit('setLoading', { loading: false });
      events.emitEvent(events.eventTypes.SEE, 'Registration Error');
      return false;
    }

    if (message) {
      window._token = message;
    }

    await context.dispatch('app/initAppData', false, { root: true });

    // Sometimes it takes a little while for the user to get synced via db-sync
    // So for future experiments to work we need to wait for the user sync before continuing
    await waitForUserInitiated(context);

    try {
      // GTM tracking
      let user = context.rootGetters['app/user'];
      const isMobile = context.rootGetters['screenSize/isMobile'];
      addDataLayer({
        category: 'account',
        action: 'create',
        userId: user.userId,
        userCreatedAt: user.registeredAt,
        isAuthenticated: 1,
        isMobileUser: isMobile
      });
    } catch (error) {
      console.error('Could not track:', error);
    }

    return true;
  } catch (e) {
    console.error(e);
    context.commit('setLoading', { loading: false });
  } finally {
    context.commit('setLoading', { loading: false });
  }
}

async function thirdPartyLogin(context, { accessToken, ssoType }) {
  context.commit('setLoginError', { error: null });
  context.commit('setLoading', { loading: true });

  try {
    let success = false;

    switch (ssoType) {
      case 'FACEBOOK':
        success = await signupServices.facebookLogin({ accessToken });
        break;
      case 'GOOGLE':
        success = await signupServices.googleLogin({ accessToken });
        break;
    }

    if (success) {
      await context.dispatch('app/initAppData', true, { root: true });
    } else {
      context.commit('setLoginError', {
        error: i18n.t('social_login_error_message')
      });
      context.commit('setLoading', { loading: false });
      return false;
    }

    return true;
  } catch (e) {
    console.error(e);
    return false;
  } finally {
    context.commit('setLoading', { loading: false });
  }
}

async function login(context, password) {
  context.commit('setLoginError', { error: null });
  context.commit('setLoading', { loading: true });

  const { email } = context.state;

  try {
    const success = await signupServices.login({ email, password });
    if (success) {
      await context.dispatch('app/initAppData', true, { root: true });
    } else {
      context.commit('setLoginError', {
        error: i18n.t('signup_login_wrong_password')
      });
      context.commit('setLoading', { loading: false });
      return false;
    }

    return true;
  } catch (e) {
    console.error(e);
    return false;
  } finally {
    context.commit('setLoading', { loading: false });
  }
}

async function createProposition(context, uploadImages = false) {
  context.commit('setLoading', { loading: true });

  const { residences, wishes, swapTimeFrame } = context.state;
  const { selectedAreas } = context.state;

  // default to 3
  let st = 3;

  if (swapTimeFrame !== null) {
    st = swapTimeFrame;
  }

  try {
    const res = await signupServices.registerProposition({
      residences,
      wishes,
      st,
      selectedAreas
    });

    const resData = JSON.parse(res.data.message);
    const { isFreeArea, isTrial, isFreemium, propositionId } = resData;

    try {
      let type;

      const user = context.rootGetters['app/user'];

      if (isFreeArea) {
        type = 'free';
      } else if (isTrial) {
        type = 'trial';
      } else if (isFreemium) {
        type = 'freemium';
      } else {
        type = 'paying';
      }

      // GTM tracking
      addDataLayer({
        category: 'proposition',
        action: 'create',
        label: type,
        propositionId: propositionId,
        isFreeArea: isFreeArea
      });

      trackPropositionCreated({ type, userId: user.userId, propositionId });

      addDataLayer({
        category: 'proposition',
        what: 'progress',
        action: 100
      });
    } catch (error) {
      console.error('Could not track:', error);
    }

    context.commit('setPropositionSignup', { proposition: resData });

    console.log('Proposition created:', resData);

    if (uploadImages) {
      context.commit('setLoading', { loading: false });
      return;
    }

    context.dispatch('finishSignup', true);
  } catch (e) {
    context.commit('setLoading', { loading: false });
    console.error(e);
  }
}

async function finishSignup(context) {
  try {
    const { isFreeArea, isTrial, isFreemium, propositionId } =
      context.state.proposition;

    // Refresh app data
    context.dispatch('app/initAppData', null, { root: true });

    // Reset state
    context.commit(
      'signup/resetState',
      { loading: false, proposition: { propositionId: '' } },
      { root: true }
    );

    let isAppRequest = false;

    try {
      isAppRequest = context.rootState.app.isAppRequest;
    } catch (e) {
      console.log('failed to check app request...');
    }

    let queryParam = isAppRequest ? '?isAppRequest=true' : '';

    if (isFreeArea) {
      router.replace(`${i18n.t('path_swap_list', routeLocale)}${queryParam}`);
    } else if (isTrial) {
      router.replace(`${i18n.t('path_trial_info', routeLocale)}${queryParam}`);
    } else if (isFreemium) {
      router.replace(
        `${i18n.t('path_freemium_info', routeLocale)}${queryParam}`
      );
    } else {
      router.replace(
        `${i18n.t(
          'path_payment_proposition',
          routeLocale
        )}/${propositionId}${queryParam}`
      );
    }
  } catch (e) {
    context.commit('setLoading', { loading: false });
    console.error(e);
  }
}

async function performSearch(context) {
  context.commit('setSearchLoading', { loading: true });
  context.commit('setSearchNoResultsFound', { noResults: false });

  const { searchTerm } = context.state;

  const { val, error } = await signupServices.search(searchTerm);

  if (error) {
    //Handle error
    context.commit('setWishAreasSearchResult', { val: [] });
    context.commit('setSearchLoading', { loading: false });
  } else {
    context.commit('setWishAreasSearchResult', { val: val });
    context.commit('setSearchLoading', { loading: false });
    if (val.length === 0) {
      context.commit('setSearchNoResultsFound', { noResults: true });
    }
  }
}

function blurAreaField(context) {
  context.commit('setSearchTerm', { inputValue: '' });
  context.commit('setWishAreasSearchResult', { val: [] });
}

function selectArea(context, { area, wishIndex }) {
  context.commit('addWishArea', { area, wishIndex });
  context.commit('setSearchTerm', { inputValue: '' });
  context.commit('setWishAreasSearchResult', { val: [] });
  context.dispatch('getPolygonsSignup', { wishIndex });
}

async function getPolygonsSignup(context, { wishIndex }) {
  const { selectedAreas } = context.state;
  const areaIds = selectedAreas[wishIndex].map(a => a.id);
  const { val, error } = await signupServices.getMultiPolygon(areaIds);
  if (error) {
    context.commit('setPolygonSignup', { wishIndex, val: {} });
  } else {
    context.commit('setPolygonSignup', { wishIndex, val });
  }
}

async function getWishAreaButtons(context) {
  const fetchedCities = await signupServices.getWishAreaButtons();

  if (!fetchedCities.error) {
    context.commit('setWishAreaOptions', { val: fetchedCities.val });
  }
}

function deselectArea(context, { area, wishIndex }) {
  context.commit('removeWishArea', { area, wishIndex });

  context.dispatch('getPolygonsSignup', { wishIndex });
}

function setSearchTerm(context, { inputValue }) {
  context.commit('setSearchTerm', { inputValue });
}

async function getNumberOfApartmentsForRegions(context, wishIndex) {
  if (context.state.selectedAreas[wishIndex - 1].length > 0) {
    const searchResult = await signupServices.getMatchingApartments(
      context.state.selectedAreas[wishIndex - 1].map(a => a.id)
    );
    context.commit(
      'setNumberOfMatchingApartments',
      Math.ceil(searchResult.val.meta.numResults / 50) * 50 - 50
    );
    return;
  }
  context.commit('setNumberOfMatchingApartments', 0);
}

async function loadSuggestionAddressNumber(
  context,
  { number, residenceIndex }
) {
  if (
    !context.state.selectedSuggestions ||
    !context.state.selectedSuggestions[residenceIndex]
  ) {
    return;
  }

  const currentSuggestion = context.state.selectedSuggestions[residenceIndex];
  const addressParts = currentSuggestion.name.split(/(\d+)/);
  const address = addressParts[0].trim();
  const searchTerm = `${address} ${number} ${currentSuggestion.description}`;
  const res = await axios.get(
    `${i18n.t(
      'url_geolocation2',
      routeLocale
    )}/google/${currentCountry}/autocomplete?searchTerm=${searchTerm}`
  );
  const { predictions, status } = res.data;
  if (status !== 'OK' || predictions.length === 0) {
    return;
  }

  const addresses = predictions.map(searchResult => {
    return {
      id: searchResult.place_id,
      name: searchResult.structured_formatting.main_text,
      description: searchResult.structured_formatting.secondary_text
    };
  });

  context.commit('setSearchNoResultsFound', { noResults: false });
  context.commit('setAddressSearchResults', {
    addresses,
    results: predictions
  });

  context.dispatch(
    'signup/loadSuggestionLocalityDetails',
    {
      residenceIndex,
      placeId: predictions[0].place_id
    },
    { root: true }
  );
}

async function searchAddress(context, { searchTerm }) {
  if (searchTerm.length < 3) {
    context.commit('setAddressSearchResults', { addresses: [], results: [] });
    return;
  }
  context.commit('setSearchLoading', { loading: true });
  context.commit('setSearchNoResultsFound', { noResults: false });

  const res = await axios.get(
    `${i18n.t(
      'url_geolocation2',
      routeLocale
    )}/google/${currentCountry}/autocomplete?searchTerm=${searchTerm}`
  );
  const { predictions, status } = res.data;

  if (status !== 'OK') {
    context.commit('setSearchNoResultsFound', { noResults: true });
    return;
  }

  const addresses = predictions.map(searchResult => {
    return {
      id: searchResult.place_id,
      name: searchResult.structured_formatting.main_text,
      description: searchResult.structured_formatting.secondary_text
    };
  });

  context.commit('setAddressSearchResults', {
    addresses,
    results: predictions
  });
  context.commit('setSearchLoading', { loading: false });
  if (predictions.length === 0) {
    context.commit('setSearchNoResultsFound', { noResults: true });
  }
}

async function loadSuggestionLocalityDetails(
  context,
  { residenceIndex, placeId }
) {
  const res = await axios.get(
    `${i18n.t(
      'url_geolocation2',
      routeLocale
    )}/google/${currentCountry}/place?id=${placeId}`
  );
  const { result } = res.data;
  const { address_components } = result;
  const postalCodeComponent = address_components.find(ac =>
    ac.types.includes('postal_code')
  );

  if (postalCodeComponent) {
    const zipCode = postalCodeComponent.long_name.replace(/ /, '');
    context.dispatch(
      'signup/setResidenceZipCode',
      { zipCode, residenceIndex },
      { root: true }
    );
  } else if (result.geometry && result.geometry.location) {
    const { lat, lng } = result.geometry.location;
    const geolocationResponse = await axios.get(
      `${i18n.t(
        'url_geolocation2',
        routeLocale
      )}/location/${currentCountry}/latlng?lat=${lat}&lng=${lng}`
    );
    const geoZipCode = geolocationResponse.data.find(
      r => r.type === 'PostalCode'
    );
    if (geoZipCode) {
      context.dispatch(
        'signup/setResidenceZipCode',
        { zipCode: geoZipCode.name, residenceIndex },
        { root: true }
      );
    }
  }

  const { geometry } = result;
  if (geometry.location) {
    context.commit('setCoordinates', {
      residenceIndex,
      coordinates: [geometry.location.lng, geometry.location.lat]
    });
  } else {
    context.commit('setCoordinates', {
      residenceIndex,
      coordinates: []
    });
  }
}

function setSelectedSuggestion(
  context,
  { selectedSuggestion, residenceIndex }
) {
  context.commit('setSelectedSuggestion', {
    selectedSuggestion,
    residenceIndex
  });
}

function setCurrentStep(context, { step }) {
  context.commit('setCurrentStep', { step });
}

function setSelectedAreas(context, { selectedAreas }) {
  context.commit('setSelectedAreas', { selectedAreas });
}

function setInSignup(context, { inSignup }) {
  context.commit('setInSignup', { inSignup });
}

export default {
  setSwapType,
  setWishMinRooms,
  setWishMinSize,
  setWishMaxRent,
  setResidenceRooms,
  setResidenceCoordinates,
  setResidenceAddress,
  setResidenceZipCode,
  setResidenceNumber,
  setResidenceArea,
  setResidenceFloor,
  setResidenceSqm,
  setResidenceRent,
  setResidenceType,
  createProposition,
  setSwapTimeFrame,
  setName,
  setEmail,
  register,
  thirdPartyLogin,
  login,
  finishSignup,
  setSSOProfile,
  performSearch,
  setSearchTerm,
  getWishAreaButtons,
  blurAreaField,
  selectArea,
  getPolygonsSignup,
  deselectArea,
  getNumberOfApartmentsForRegions,
  searchAddress,
  loadSuggestionAddressNumber,
  loadSuggestionLocalityDetails,
  setSelectedSuggestion,
  setSelectedAreas,
  setCurrentStep,
  setInSignup
};
