<template>
  <div class="edit-proposition-photos-container">
    <div class="edit-proposition-photos">
      <div v-if="isMobile" class="upload-photos-container">
        <BaseBackButton
          v-if="!isAppRequest"
          class="back-button"
          @click="goBack"
        />
        <div class="upload-photos-content">
          <h1 class="title">{{ $t('edit_photos_title') }}</h1>
        </div>
        <div class="upload-photos-area-container">
          <div class="upload-photos-area">
            <template v-if="isLoading">
              <div
                class=""
                style="width: 28px; height: 28px; margin-bottom: 20px"
              >
                <ASpinner class="spinner-alt" />
              </div>
              <div>{{ $t('edit_photos_uploading') }}</div>
            </template>
            <template v-else>
              <input
                ref="uploadInput"
                type="file"
                accept="image/*"
                multiple
                class="upload-photos-input"
                @change="handleChange($event.target.files)"
              />
              <BaseIcon
                icon="camera"
                :width="48"
                :height="48"
                class="upload-photos-icon"
              />
              <div class="upload-text">
                {{ $t('edit_photos_upload_description_mobile') }}
              </div>
            </template>
          </div>
        </div>
      </div>

      <div class="uploaded-photos-container">
        <div class="uploaded-photos-content">
          <template v-if="!isMobile">
            <BaseBackButton
              v-if="!isAppRequest"
              class="back-button"
              @click="goBack"
            />
            <h1>{{ $t('edit_photos_title') }}</h1>
            <p class="uploaded-photos-description">
              {{ $t('edit_photos_description_desktop') }}
            </p>
          </template>
          <template v-else>
            <h1>{{ $t('edit_photos_title_edit') }}</h1>
            <p>
              {{ $t('edit_photos_description_edit') }}
            </p>
          </template>
        </div>
        <div class="uploaded-photos">
          <template v-for="(serverImage, index) in serverImages">
            <div
              :key="serverImage.picture_id"
              draggable="true"
              class="uploaded-photo"
              @dragstart="moveStartIndex = index"
              @dragover.prevent
              @drop="handleReorder(index)"
            >
              <div
                v-if="serverImage.isLoading"
                class="uploaded-photo-is-loading"
              >
                <div
                  class="spinner-container"
                  style="width: 28px; height: 28px; margin-bottom: 20px"
                >
                  <ASpinner class="spinner-alt" />
                </div>
              </div>
              <div class="image-container">
                <div v-if="index === 0" class="image-badge">
                  {{ $t('edit_photos_primary_pic') }}
                </div>
                <BaseImage
                  :url="getImageThumbnail(serverImage.filename)"
                  alt=""
                  draggable="false"
                  class="uploaded-image"
                  @done="serverImage.isLoading = false"
                />
                <div
                  class="icon-container icon-container-trash"
                  @click="handleRemoveClick(serverImage.picture_id)"
                >
                  <BaseIcon icon="trash-alt" />
                </div>
                <div
                  class="icon-container icon-container-rotate-left"
                  @click="handleRotate(serverImage.picture_id, -90)"
                >
                  <BaseIcon icon="rotate-left" />
                </div>
                <div
                  class="icon-container icon-container-rotate-right"
                  @click="handleRotate(serverImage.picture_id, 90)"
                >
                  <BaseIcon icon="rotate-right" />
                </div>
              </div>
              <div class="photo-options">
                <!-- <div class="photo-options-select">
                  <select
                    :value="serverImage.picture_type.id"
                    @change="setPictureType($event.target.value, serverImage.picture_id)"
                  >
                    <option
                      v-for="pictureType in pictureTypes"
                      :key="pictureType.id"
                      :value="pictureType.id"
                    >
                      {{ pictureType.name }}
                    </option>
                  </select>
                </div> -->
                <template v-if="isMobile">
                  <div
                    :disabled="index == serverImages.length - 1"
                    class="icon-container icon-container-angle-down"
                    @click="handleMoveClick(index, 1)"
                  >
                    <BaseIcon icon="angle-down" />
                  </div>
                  <div
                    :disabled="index === 0"
                    class="icon-container icon-container-angle-up"
                    @click="handleMoveClick(index, -1)"
                  >
                    <BaseIcon icon="angle-up" />
                  </div>
                </template>
              </div>
            </div>
          </template>

          <div
            v-if="!isMobile"
            :class="[
              'upload-photos-area',
              filesIsDragged ? 'files-is-dragged' : '',
              isLoading ? 'files-is-loading' : ''
            ]"
          >
            <template v-if="isLoading">
              <div class="upload-photos-area-content">
                <div
                  class=""
                  style="width: 28px; height: 28px; margin-bottom: 20px"
                >
                  <ASpinner class="spinner-alt" />
                </div>
                <div>{{ $t('edit_photos_uploading') }}</div>
              </div>
            </template>
            <template v-else>
              <input
                ref="uploadInput"
                type="file"
                accept="image/*"
                multiple
                class="upload-photos-input"
                @change="handleChange($event.target.files)"
                @dragover="filesIsDragged = true"
                @dragleave="filesIsDragged = false"
                @drop="filesIsDragged = false"
              />
              <div class="upload-photos-area-content">
                <BaseIcon
                  icon="camera"
                  :width="48"
                  :height="48"
                  class="upload-photos-icon"
                />
                <div
                  class="upload-text"
                  v-html="$t('edit_photos_upload_description_desktop')"
                ></div>
              </div>
            </template>
          </div>
        </div>
      </div>

      <BaseModal
        v-if="showRemoveModal"
        :title="$t('edit_profile_remove_picture')"
        @close="showRemoveModal = false"
      >
        <div class="remove-modal-content">
          <BaseButton class="warning" @click="handleConfirmRemoveImage()">
            {{ $t('edit_photos_modal_remove') }}
          </BaseButton>
          <BaseButton class="grey outline" @click="showRemoveModal = false">
            {{ $t('edit_photos_modal_close') }}
          </BaseButton>
        </div>
      </BaseModal>

      <div class="how-it-works">
        <h1>{{ $t('edit_photos_works_title') }}</h1>
        <p>{{ $t('edit_photos_works_body_1') }}</p>

        <p>{{ $t('edit_photos_works_body_2') }}</p>

        <p>{{ $t('edit_photos_works_body_3') }}</p>

        <p>
          {{ $t('edit_photos_works_body_4') }}
          <a
            v-if="$country.isCountry('se')"
            href="/nyheter/fotoskola-lar-dig-att-ta-fina-annonsbilder/63"
            >{{ $t('edit_photos_works_body_5') }}</a
          >
          <a
            v-if="$country.isCountry('nl')"
            href="/nieuws/fotoschool-leer-om-mooie-advertentiefotos-te-maken/290"
            >{{ $t('edit_photos_works_body_5') }}</a
          >
          <a
            v-if="$country.isCountry('de')"
            href="/neuigkeiten/fototipps-so-gelingen-schone-fotos-fur-deine-anzeige/291"
            >{{ $t('edit_photos_works_body_5') }}</a
          >.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import { lbApiBeta } from '../../utils/axiosConfig';
import ASpinner from '@/components/atomic/atoms/a-spinner';
import { resizeImage } from '@/utils/helpers/image';
import { arrayMoveImmutable } from '@/utils/helpers/array';
import { getBetaEndpoint } from '@/utils/getEndpoint';

export default {
  name: 'EditPropositionPhotos',

  components: {
    ASpinner
  },

  data() {
    return {
      localImages: [],
      serverImages: [],
      isLoading: false,
      pictureTypes: [],
      moveStartIndex: null,
      showRemoveModal: false,
      pictureToRemove: null,
      filesIsDragged: false
    };
  },

  head: {
    title() {
      return {
        inner: this.$t('meta_title_edit_prop_photos')
      };
    }
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile',
      isAppRequest: 'app/isAppRequest'
    }),

    propositionId() {
      return this.$route.params.propositionId.replace(/^\D+/g, '');
    },

    editPropositionPath() {
      return this.$routes.EDIT_PROPOSITION + '/' + this.propositionId;
    }
  },

  async created() {
    await this.getPictureTypes();
    await this.getImages();

    window.scrollTo(0, 0);
  },

  beforeDestroy() {
    this.refreshPropositions();
  },

  methods: {
    ...mapActions({
      setToast: 'toast/setToast',
      refreshPropositions: 'myPropositions/refreshPropositions'
    }),

    goBack() {
      this.$router.push(this.editPropositionPath);
    },

    async handleChange(files) {
      try {
        this.isLoading = true;

        for (const file of files) {
          const localImageUrl = await this.getLocalImageUrl(file);
          this.localImages = [{ localImageUrl, file }, ...this.localImages];
        }

        await this.uploadImages(this.localImages);
        this.localImages = [];
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        this.isLoading = false;
      }
    },

    async uploadImages(images) {
      const URL = getBetaEndpoint() + '/api/object/NewUpload';

      const config = {
        header: {
          'Content-Type': 'multipart/form-data'
        },
        withCredentials: true,
        credentials: 'same-origin'
      };

      const body = new FormData();

      body.append('propositionId', this.propositionId);
      body.append('suffix', this.$route.params.residenceIndex);
      body.append('categoryId', 8);

      for (const image of images) {
        body.append('image', image.file);
      }

      axios
        .post(URL, body, config)
        .then(response => {
          // eslint-disable-next-line no-console
          console.log('image upload response: ', response);

          this.getImages();
          this.isLoading = false;
          this.userResponseMessage(response.data.Message);
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log(error);

          this.isLoading = false;
          this.userResponseMessage(0);
        });
    },

    getImageThumbnail(image) {
      return resizeImage(image, '800x800');
    },

    userResponseMessage(imagesUploaded) {
      // 0 == error
      let toastMessage = '';

      if (imagesUploaded == 0) {
        toastMessage = this.$t('edit_photos_upload_failed');
      } else if (imagesUploaded == 1) {
        toastMessage = this.$t('edit_photos_upload_1_image');
      } else {
        toastMessage = `${imagesUploaded} ${this.$t(
          'edit_photos_upload_x_images'
        )}`;
      }

      this.setToast({ title: toastMessage });
    },

    handleRemoveClick(pictureId) {
      this.pictureToRemove = pictureId;
      this.showRemoveModal = true;
    },

    async handleConfirmRemoveImage() {
      this.serverImages = this.serverImages.filter(
        image => image.picture_id != this.pictureToRemove
      );
      this.showRemoveModal = false;

      await lbApiBeta.post('/api/object/DeletePicture', {
        pictureId: this.pictureToRemove,
        propositionId: this.propositionId
      });

      setTimeout(() => {
        this.refreshPropositions();
      }, 2000);
    },

    async handleRotate(pictureId, degrees) {
      this.updateImage(pictureId, { isLoading: true });

      const response = await lbApiBeta.post('/api/object/RotatePicture', {
        picId: pictureId,
        propositionId: this.propositionId,
        degrees
      });

      const thumbnailUrl = resizeImage(response.data.Object, '800x800');

      this.updateImage(pictureId, {
        thumbnail: thumbnailUrl,
        filename: response.data.Object
      });
    },

    updateImage(pictureId, newObject) {
      const index = this.serverImages.findIndex(
        image => image.picture_id == pictureId
      );
      const serverImage = this.serverImages[index];

      this.$set(this.serverImages, index, { ...serverImage, ...newObject });
    },

    getLocalImageUrl(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = function (progress) {
          resolve(progress.currentTarget.result);
        };

        reader.onerror = reject;

        reader.readAsDataURL(file);
      });
    },

    async getImages() {
      const response = await lbApiBeta.get('/api/object/AdPictures', {
        params: {
          ad_id: this.propositionId,
          ad_suffix: this.$route.params.residenceIndex
        }
      });

      this.serverImages = response.data.Object;
    },

    async getPictureTypes() {
      const response = await lbApiBeta.get('/api/object/GetPictureTypes');
      this.pictureTypes = response.data.Object;
    },

    async setPictureType(newPictureTypeId, imageId) {
      const newPictureType = this.pictureTypes.find(
        type => type.id == newPictureTypeId
      );
      this.updateImage(imageId, { isLoading: true });
      await lbApiBeta.post('/api/object/SetPictureType', {
        picId: imageId,
        picTypeId: newPictureTypeId
      });

      this.updateImage(imageId, {
        picture_type: newPictureType,
        isLoading: false
      });
    },

    handleMoveClick(index, direction) {
      this.moveStartIndex = index;
      this.handleReorder(index + direction);
    },

    async handleReorder(moveEndIndex) {
      this.serverImages = arrayMoveImmutable(
        this.serverImages,
        this.moveStartIndex,
        moveEndIndex
      );

      await this.savePictureOrder();
    },

    savePictureOrder() {
      return lbApiBeta.post('/api/object/SavePicturesOrder', {
        pictureIds: this.serverImages.map(image => image.picture_id)
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.edit-proposition-photos-container {
  max-width: none;
  width: 100%;
  background-color: #f8f8fa;
}

.edit-proposition-photos {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 30px;

  @media ($small-desktop) {
    width: 96%;
  }

  @media ($desktop) {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    max-width: 1200px;
    width: 90%;
    margin: 0 auto;
  }
}

.upload-photos-container {
  @media ($desktop) {
    display: flex;
    flex-direction: column;
    grid-column: span 6;
    padding-right: 15px;
  }
}

.back-button {
  margin-left: 15px;

  @media ($desktop) {
    margin-left: 0;
  }
}

.upload-photos-content {
  @media ($mobile) {
    padding: 0 20px;
    .title {
      margin-top: 20px;
    }
  }
}

.uploaded-photos-description {
  margin-bottom: 30px;
}

.upload-photos-area-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;

  @media ($mobile) {
    background-color: #e9efff;
    margin-bottom: 30px;
  }

  @media ($desktop) {
    flex-grow: 1;
    padding: 0;
    padding-top: 20px;
  }
}

.upload-photos-area {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 180px;
  padding: 20px;
  border: 3px dashed #409fff;
  text-align: center;
  line-height: 1.3;
  font-weight: 600;
  border-radius: 20px;
  cursor: pointer;

  @media ($mobile) {
    font-size: 15px;
  }

  @media ($desktop) {
    width: 100%;
    height: auto;
    padding-bottom: 75%;
    padding-top: 54px;
    margin-bottom: 30px;
    background-color: #e9efff;

    &:not(.files-is-loading) {
      &:hover,
      &:focus {
        background-color: #c9d8ff;
      }
    }
  }
}

.files-is-loading {
  cursor: progress;
}

.files-is-dragged {
  background-color: #c9d8ff;
}

.upload-photos-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}

.upload-photos-area-content {
  @media ($desktop) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.upload-photos-icon {
  margin-bottom: 1rem;
}

.uploaded-photos-container {
  margin-bottom: 30px;

  @media ($desktop) {
    grid-column: span 12;
    margin-bottom: 40px;
  }
}

.uploaded-photos-content {
  @media ($mobile) {
    padding: 0 20px;
  }
}

.uploaded-photos {
  @media ($desktop) {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: 30px;
  }
}

.uploaded-photo {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 10px;
  overflow: hidden;
  margin-bottom: 10px;
  border-radius: 5px;

  @media ($desktop) {
    margin-bottom: 30px;
    cursor: grab;
  }
}

.uploaded-photo-is-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(227, 229, 233, 0.5);
  z-index: 1;

  .spinner-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
}

.image-container {
  flex-grow: 1;
  position: relative;
  padding-bottom: 75%;
  border-radius: 5px;
  overflow: hidden;
}

.image-badge {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #2c3e50;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  z-index: 3;
}

.uploaded-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: #e8e8e8;
}

.icon-container {
  display: grid;
  place-items: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 0;
  cursor: pointer;

  &[disabled] {
    opacity: 0.5;
  }
}

.icon-container-trash {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ff3843;
}

.icon-container-rotate-left {
  background-color: #fff;
  position: absolute;
  bottom: 10px;
  right: 60px;
}

.icon-container-rotate-right {
  background-color: #fff;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.photo-options {
  display: flex;

  @media ($mobile) {
    margin-top: 10px;
    margin-left: auto;
  }
}

.photo-options-select {
  flex-grow: 1;

  select {
    display: block;
    height: 40px;
    width: 100%;
    border: 1px solid #e8e8e8;
    border-radius: 10px;
    font-weight: 600;
    padding: 10px 16px;
    color: $text-primary;
  }
}

.icon-container-angle-up {
  background-color: #2c3e50;
  margin-left: 10px;
}

.icon-container-angle-down {
  background-color: #2c3e50;
  margin-left: 10px;
}

.how-it-works {
  @media ($mobile) {
    padding: 30px 20px;
  }

  @media ($desktop) {
    grid-column: span 6;
    padding-right: 15px;
  }
}

p {
  font-weight: 400;
  line-height: 1.5;

  a {
    font-weight: 700;
    color: #409fff;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

h1 {
  margin-top: 0;
}

.remove-modal-content {
  display: flex;
  justify-content: center;
  padding: 10px 0;

  button {
    margin: 0 10px;
    white-space: nowrap;
  }
}
</style>
