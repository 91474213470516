import { cloneDeep } from 'lodash';

const setSearchTerm = (state, { inputValue }) =>
  (state.searchTerm = inputValue);

const setSearchResult = (state, { val }) => (state.searchResult = val);

const setWishAreaOptions = (state, { val }) => (state.wishAreaOptions = val);

const setExistingAreas = (state, { areas }) => {
  state.selectedAreas = areas;
};

const addWishArea = (state, { area }) => {
  const selectedAreas = cloneDeep(state.selectedAreas);
  selectedAreas.push(area);
  state.selectedAreas = selectedAreas;
};

const removeWishArea = (state, { area }) => {
  let selectedAreas = cloneDeep(state.selectedAreas);
  selectedAreas = selectedAreas.filter(a => a.id !== area.id);
  state.selectedAreas = selectedAreas;
};

const setPolygon = (state, { val }) => {
  state.multiPolygon = val;
};

function setLoading(state, { loading }) {
  state.loading = loading;
}

function setSearchLoading(state, { loading }) {
  state.searchLoading = loading;
}

function setSearchNoResultsFound(state, { noResults }) {
  state.searchNoResultsFound = noResults;
}

function setMustSelectNewAreas(state, value) {
  state.mustSelectNewAreas = value;
}

function resetState(state) {
  state.searchLoading = false;
  state.searchResult = [];
  state.searchTerm = '';
  state.selectedAreas = [];
  state.multiPolygon = {};
  state.loading = false;
  state.searchLoading = false;
}

export default {
  setSearchResult,
  setSearchTerm,
  setWishAreaOptions,
  addWishArea,
  removeWishArea,
  setPolygon,
  setLoading,
  setSearchLoading,
  setExistingAreas,
  setSearchNoResultsFound,
  setMustSelectNewAreas,
  resetState
};
