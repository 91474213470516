<template>
  <div class="buy-boost">
    <ExtraVisibility
      v-if="proposition"
      :proposition="proposition"
      style="margin-top: 10px"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ExtraVisibility from '@/components/Payments/ExtraVisibility';

export default {
  name: 'BuyBoost',

  components: {
    ExtraVisibility
  },

  computed: {
    ...mapGetters({
      proposition: 'myPropositions/primaryProposition'
    })
  },

  mounted() {
    this.temporaryHideTrialBanner({ hide: true });
  },

  beforeDestroy() {
    this.temporaryHideTrialBanner({ hide: false });
  },

  methods: {
    ...mapActions({
      temporaryHideTrialBanner: 'trial/temporaryHideTrialBanner'
    })
  }
};
</script>

<style lang="scss" scoped></style>
