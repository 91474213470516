<template>
  <div class="uploaded-photos">
    <div
      v-for="(serverImage, index) in serverImages"
      :key="serverImage.picture_id"
      draggable="true"
      class="uploaded-photo"
      @dragstart="moveStartIndex = index"
      @dragover.prevent
      @drop="handleReorder(index)"
    >
      <ImageHandler
        :proposition-id="propositionId"
        :image="serverImage"
        :index="index"
        :max-index="serverImages.length - 1"
        @updateImage="updateImage($event)"
        @removeImage="handleRemoveClick($event)"
        @handleMoveClick="handleMoveClick($event)"
      ></ImageHandler>
    </div>

    <ImageDropZone
      :proposition-id="propositionId"
      :residence-index="residenceIndex"
      :explainer="serverImages.length === 0"
      :disabled="disabled"
      @getImages="getImages()"
    />

    <BaseModal
      v-if="showRemoveModal"
      title="Ta bort bild?"
      @close="showRemoveModal = false"
    >
      <div class="remove-modal-content">
        <BaseButton class="warning" @click="handleConfirmRemoveImage()">
          {{ $t('edit_photos_modal_remove') }}
        </BaseButton>
        <BaseButton class="grey outline" @click="showRemoveModal = false">
          {{ $t('edit_photos_modal_close') }}
        </BaseButton>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { lbApiBeta } from '@/utils/axiosConfig';
import { resizeImage } from '@/utils/helpers/image';
import { arrayMoveImmutable } from '@/utils/helpers/array';
import ImageHandler from '@/components/Signup/ImageUpload/ImageHandler.vue';
import ImageDropZone from '@/components/Signup/ImageUpload/ImageDropZone.vue';

export default {
  name: 'EditPropositionPhotos',

  components: {
    ImageHandler,
    ImageDropZone
  },

  props: {
    propositionId: {
      type: String,
      default: null
    },

    residenceIndex: {
      type: String,
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      serverImages: [],
      isLoading: false,
      moveStartIndex: null,
      showRemoveModal: false,
      pictureToRemove: null,
      filesIsDragged: false
    };
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile',
      isAppRequest: 'app/isAppRequest'
    })
  },

  watch: {
    propositionId(newValue, oldValue) {
      if (newValue !== oldValue && newValue !== null) {
        this.serverImages = [];
        this.getImages();
      }
    },

    residenceIndex(newValue, oldValue) {
      if (newValue !== oldValue && newValue !== null) {
        this.serverImages = [];
        this.getImages();
      }
    }
  },

  async created() {
    await this.getImages();
  },

  methods: {
    getImageThumbnail(image) {
      return resizeImage(image, '800x800');
    },

    handleRemoveClick(pictureId) {
      this.pictureToRemove = pictureId;
      this.showRemoveModal = true;
    },

    async handleConfirmRemoveImage() {
      this.serverImages = this.serverImages.filter(
        image => image.picture_id != this.pictureToRemove
      );
      this.showRemoveModal = false;

      await lbApiBeta.post('/api/object/DeletePicture', {
        pictureId: this.pictureToRemove,
        propositionId: this.propositionId
      });
    },

    updateImage(picture) {
      const index = this.serverImages.findIndex(
        image => image.picture_id == picture.picture_id
      );
      const serverImage = this.serverImages[index];

      this.$set(this.serverImages, index, { ...serverImage, ...picture });
    },

    async getImages() {
      if (!this.propositionId || !this.residenceIndex) {
        return;
      }

      const response = await lbApiBeta.get('/api/object/AdPictures', {
        params: {
          ad_id: this.propositionId,
          ad_suffix: this.residenceIndex
        }
      });

      this.serverImages = response.data.Object;
      this.$emit('numberOfImages', this.serverImages.length);
    },

    handleMoveClick(event) {
      this.moveStartIndex = event.index;
      this.handleReorder(event.index + event.direction);
    },

    async handleReorder(moveEndIndex) {
      this.serverImages = arrayMoveImmutable(
        this.serverImages,
        this.moveStartIndex,
        moveEndIndex
      );

      await this.savePictureOrder();
    },

    savePictureOrder() {
      return lbApiBeta.post('/api/object/SavePicturesOrder', {
        pictureIds: this.serverImages.map(image => image.picture_id)
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.uploaded-photos {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  column-gap: 15px;
  justify-items: center;

  @media ($desktop) {
    grid-template-columns: 1fr 1fr;
  }
}

.uploaded-photo {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin-bottom: 30px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  max-width: 500px;
  height: auto;
  width: 100%;
  @media ($desktop) {
    cursor: grab;
  }
}

.uploaded-photos > div:first-child {
  @media ($desktop) {
    grid-column: span 2;
  }
}

.uploaded-photo-is-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(227, 229, 233, 0.5);
  z-index: 1;

  .spinner-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
}

.image-container {
  flex-grow: 1;
  padding-bottom: 75%;
  overflow: hidden;
}

.image-badge {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #2c3e50;
  color: #fff;
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  z-index: 3;
}

.uploaded-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #e8e8e8;
}

.icon-container {
  display: grid;
  place-items: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 0;
  cursor: pointer;

  &[disabled] {
    opacity: 0.5;
  }
}

.icon-container-trash {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ff3843;
}

.icon-container-rotate-left {
  background-color: #fff;
  position: absolute;
  bottom: 10px;
  right: 60px;
}

.icon-container-rotate-right {
  background-color: #fff;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.photo-options {
  display: flex;

  @media ($mobile) {
    margin-top: 10px;
    margin-left: auto;
  }
}

.icon-container-angle-up {
  background-color: #2c3e50;
  margin-left: 10px;
}

.icon-container-angle-down {
  background-color: #2c3e50;
  margin-left: 10px;
}

.how-it-works {
  @media ($mobile) {
    padding: 30px 20px;
  }

  @media ($desktop) {
    grid-column: span 6;
    padding-right: 15px;
  }
}

p {
  font-weight: 400;
  line-height: 1.5;

  a {
    font-weight: 700;
    color: #409fff;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

h1 {
  margin-top: 0;
}

.remove-modal-content {
  display: flex;
  justify-content: center;
  padding: 10px 0;

  button {
    margin: 0 10px;
    white-space: nowrap;
  }
}
</style>
