<template>
  <div class="accordion-group">
    <h3 class="group-title">{{ content.AccordionGroupTitle }}</h3>
    <BaseAccordion
      v-slot="{ isOpen }"
      class="accordion"
      :accordion-id="accordionId"
      :save-last-open="scrollToOpenAccordion"
      v-bind="$attrs"
    >
      <BaseAccordionItem
        v-for="(item, itemIndex) in content.AccordionItem"
        :key="itemIndex"
        :item-id="`${accordionId}-${itemIndex}`"
        :is-open="isOpen.includes(`${accordionId}-${itemIndex}`)"
        :scroll-to-open-accordion="scrollToOpenAccordion"
        :title="item.Title"
      >
        <template #body>
          <span v-html="item.Description"></span>
        </template>
      </BaseAccordionItem>
    </BaseAccordion>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default: () => {}
    },

    accordionId: {
      type: Number,
      default: 0
    },

    scrollToOpenAccordion: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.accordion-group {
  margin-top: 60px;
  margin-bottom: 60px;
  padding: 0 16px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.group-title {
  font-size: 22px;
  font-weight: 600;
  margin-top: 50px;
  line-height: 1.2;
}
</style>
