<template>
  <div v-if="geolocations.length > 0" class="block-container">
    <h1 class="block-title">
      {{ usedIn === 'seo-landing-page' ? customTitle : content.BlockTitle }}
    </h1>
    <div class="geolocations">
      <div v-for="geolocation in geolocations" :key="geolocation.id">
        <a :href="getGeolocationFullUrl(geolocation)" class="geolocation">
          {{ geolocation.name }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Geolocations',

  props: {
    content: {
      type: [Object, Array],
      default: () => {}
    },
    usedIn: {
      type: String,
      default: ''
    },
    areaName: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      geolocations: []
    };
  },

  computed: {
    customTitle() {
      return this.$t('sitemap_areas_in', { area: this.areaName });
    }
  },

  created() {
    this.geolocations = this.content;
  },

  methods: {
    getGeolocationFullUrl(geolocation) {
      return window.document.location.href + '/' + geolocation.slug;
    }
  }
};
</script>

<style lang="scss" scoped>
.block-container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;
  margin-bottom: 32px;
  padding: 0 16px;

  @media ($large-screen) {
    margin-top: 64px;
    margin-bottom: 64px;
  }
}

.block-title {
  font-size: 24px;
  margin-bottom: 10px;

  @media ($large-screen) {
    font-size: 30px;
    margin-bottom: 14px;
  }
}

.geolocation {
  display: inline-block;
  margin-bottom: 10px;
  width: 50%;

  @media ($large-screen) {
    width: 25%;
  }
}
</style>
