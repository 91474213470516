<template>
  <div class="not-found">
    <BaseIcon
      icon-file="notfound"
      :width="200"
      :height="200"
      class="no-result-icon"
    />
    <h1 class="no-result-title">{{ $t('proposition_removed_title') }}</h1>
    <BaseButton :link="isAuthenticated ? $routes.SWAP_LIST : $routes.SEARCH">{{
      isAuthenticated
        ? $t('proposition_removed_button')
        : $t('proposition_removed_button_logged_out')
    }}</BaseButton>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PagePropositionNotFound',

  head: {
    title() {
      return {
        inner: this.$t('proposition_removed_title')
      };
    }
  },

  computed: {
    ...mapGetters({
      isAuthenticated: 'app/isAuthenticated'
    })
  },

  mounted() {
    window.pageNotFound = true;
    window.prerenderPaused = false;
  }
};
</script>

<style lang="scss" scoped>
.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
}

.no-result-icon {
  margin-top: 60px;
  margin-bottom: 32px;
}

.no-result-title {
  color: #213a75;
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.2;
  padding: 0 40px;
  margin-top: 0;
  margin-bottom: 40px;
}
</style>
