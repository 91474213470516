<template>
  <div class="filter-container">
    <div class="flex-wrapper">
      <div class="sort-action">
        {{ $t('filter_container_sort_on') }}
        <span class="sort-label" @click.stop="setGlobalModal('SortingModal')">{{
          readableSortType()
        }}</span>
      </div>
      <div
        v-if="isUserList && isSwapList"
        class="map-btn-wrapper"
        @click="goToMap"
      >
        <div class="map-btn-icon"></div>

        <div class="map-btn-label">
          {{ $t('filter_container_show_on_map') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  SORT_DIRECTIONS,
  SWAP_LIST_TYPES,
  SORT_TYPES
} from '@/store/modules/swapList';
import events from '@/utils/helpers/events';

export default {
  name: 'FilterContainer',

  props: {
    isSwapList: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      sortModalOpen: false,
      SORT_DIRECTIONS
    };
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile',
      sortType: 'swapList/sortType',
      isUserList: 'swapList/isUserList',
      primaryPropositionIsLimited: 'myPropositions/primaryPropositionIsLimited'
    })
  },

  methods: {
    ...mapActions({
      setSortType: 'swapList/setSortType',
      setSwapListType: 'swapList/setSwapListType',
      setGlobalModal: 'ui/setGlobalModal'
    }),

    readableSortType() {
      switch (this.sortType) {
        case SORT_TYPES.HIGHEST_SCORE:
          return this.$t('filter_container_type_score');
        case SORT_TYPES.SQM_ASC:
          return this.$t('filter_container_type_sqm_asc');
        case SORT_TYPES.SQM_DESC:
          return this.$t('filter_container_type_sqm_desc');
        case SORT_TYPES.ROOMS_DESC:
          return this.$t('filter_container_type_num_of_rooms_desc');
        case SORT_TYPES.ROOMS_ASC:
          return this.$t('filter_container_type_num_of_rooms_asc');
        case SORT_TYPES.RENT_DESC:
          return this.$t('filter_container_type_rent_desc');
        case SORT_TYPES.RENT_ASC:
          return this.$t('filter_container_type_rent_asc');
        case SORT_TYPES.INTEREST_DATE:
          return this.$t('sort_options_type_interest_date');
        case SORT_TYPES.PERCENTAGE:
          return this.$t('filter_container_type_interest');
        default:
          return '';
      }
    },

    openSortModal() {
      this.sortModalOpen = true;
    },
    closeSortModal() {
      this.sortModalOpen = false;
    },

    goToMap() {
      if (this.primaryPropositionIsLimited) {
        this.setGlobalModal('MapLocked');

        events.emitEvent(events.eventTypes.SEE, 'Paywall', {
          trigger: 'map-locked',
          src: 'swap-list'
        });
      } else {
        this.setSwapListType({ type: SWAP_LIST_TYPES.MAP });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.sort-action {
  font-weight: 600;
  font-size: 0.8rem;
  margin-right: 20px;
}

.sort-label {
  cursor: pointer;
  color: $main-blue;
  text-decoration: underline;
  position: relative;

  &::after {
    position: absolute;
    right: -14px;
    top: 4px;
    content: '';
    background-image: url('../../../assets/svg/arrow-blue.svg');
    background-position: center;
    background-size: contain;
    transform: rotate(90deg);
    height: 10px;
    width: 10px;
  }
}

.flex-wrapper {
  display: flex;
  align-items: center;
}

.map-btn-wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 4px;
}

.map-btn-label {
  font-weight: 600;
  font-size: 0.8rem;
}

.map-btn-icon {
  height: 20px;
  width: 20px;
  background-size: contain;
  background-position: center;
  background-image: url('../../../assets/svg/icons/show-on-map.svg');
  margin: 0 4px;
}
</style>
