<template>
  <div class="my-proposition-card">
    <div class="proposition-card-header">
      <div class="status">
        {{ $t('my_prop_card_status') }}:
        <span v-if="proposition.active">{{ $t('my_prop_card_active') }}</span
        ><span v-else>{{ $t('my_prop_card_inactive') }}</span>
      </div>
      <div>
        <BaseButton
          v-if="!proposition.active && proposition.freemiumAvailable"
          class="smaller rounded"
          style="margin-right: 8px"
          @click="showFreemiumCtaModal(proposition.propositionId)"
          >{{ $t('pay_proposition_activate') }}</BaseButton
        >
        <BaseButton
          v-else-if="!proposition.active"
          class="smaller rounded"
          style="margin-right: 8px"
          @click="goToPaymentPage"
        >
          {{ $t('pay_proposition_activate') }}
        </BaseButton>
        <BaseButton
          v-if="!proposition.active"
          class="smaller rounded warning"
          @click="deletePropositionAndRefresh"
          >{{ $t('my_propositions_card_delete') }}</BaseButton
        >
      </div>
    </div>
    <BaseButton v-if="isMobile" class="preview-button rounded white">
      <span>
        {{ $t('my_prop_card_preview_ad') }}
        <BaseIcon :icon-file="'arrow-right-dark'" :width="20" :height="20" />
      </span>
    </BaseButton>
    <BasePropositionCardNew v-if="isMobile" :swap="proposition" />
    <BasePropositionCard
      v-else
      :key="proposition.propositionId"
      :swap="proposition"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import events from '../../utils/helpers/events';

export default {
  name: 'MyPropositionsCard',

  props: {
    proposition: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile'
    })
  },

  methods: {
    ...mapActions({
      setGlobalModal: 'ui/setGlobalModal',
      fetchProducts: 'klarnaPayments/fetchProducts',
      deleteProposition: 'myPropositions/deleteProposition'
    }),

    async deletePropositionAndRefresh() {
      await this.deleteProposition({
        propositionId: this.proposition.propositionId
      });

      window.location.reload();
    },

    showFreemiumCtaModal(propositionId) {
      if (this.$route.query.propositionId != propositionId) {
        this.$router.replace({ query: { propositionId } });
      }

      this.fetchProducts({
        productType: 'annonsforlangning',
        propositionId: propositionId
      });
      this.setGlobalModal('ActivateOrPay');

      events.emitEvent(events.eventTypes.SEE, 'Paywall', {
        trigger: 'activate-proposition',
        src: 'my-propositions'
      });
    },

    goToPaymentPage() {
      // If freemium is not available
      if (!this.proposition.inFreeArea) {
        events.emitEvent(events.eventTypes.CLICK, 'Payment', {
          id: 'my-propositions-card'
        });

        this.$router.push(
          `${this.$routes.PAYMENT_PROPOSITION}/${this.proposition.propositionId}`
        );
      } else {
        `${this.$routes.HANDLE_SUBSCRIPTION}/${this.proposition.propositionId}`;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.my-proposition-card {
  position: relative;
}

.proposition-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 12px;
}

.status {
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.3px;
}

.preview-button {
  position: absolute !important;
  top: calc(50% - 17px);
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  font-weight: 400 !important;
  white-space: nowrap;
  pointer-events: none;

  span {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
</style>
