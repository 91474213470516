<template>
  <div class="accordion">
    <slot :is-open="isOpen"></slot>
  </div>
</template>

<script>
import events from '@/utils/helpers/events';
export default {
  name: 'BaseAccordion',

  props: {
    saveLastOpen: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isOpen: []
    };
  },

  created() {
    this.$on('toggle', this.handleToggleItem);

    if (this.$route.query.accordion) {
      this.isOpen.push(this.$route.query.accordion);
    }
  },

  methods: {
    handleToggleItem(itemId, title) {
      if (this.isOpen.includes(itemId)) {
        this.isOpen = this.isOpen.filter(id => id !== itemId);
        events.emitEvent(events.eventTypes.CLICK, 'Accordion Closed', {
          src: `FAQ`,
          title
        });
      } else {
        this.isOpen.push(itemId);
        events.emitEvent(events.eventTypes.CLICK, 'Accordion Opened', {
          src: `FAQ`,
          title
        });
      }

      // add query param with lastest clicked accordion item
      if (this.saveLastOpen) {
        this.$router.replace({ query: { accordion: itemId } });
      }
    }
  }
};
</script>
