<template>
  <div
    :style="{
      'background-image': `url(${bgImage})`
    }"
    class="hero"
  >
    <div class="hero-column">
      <div class="hero-content">
        <h1>{{ content.Title }}</h1>
        <p>{{ content.Description }}</p>
        <ul class="usp-container">
          <li v-if="content.Usp1">{{ content.Usp1 }}</li>
          <li v-if="content.Usp2">{{ content.Usp2 }}</li>
          <li v-if="content.Usp3">{{ content.Usp3 }}</li>
        </ul>
        <BaseButton
          :link="content.ButtonLink"
          :is-external="true"
          class="cta-button rounded"
        >
          {{ content.ButtonText }}
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hero',

  props: {
    content: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    bgImage() {
      return (
        this.content.BackgroundImage.formats?.large?.url ||
        this.content.BackgroundImage.url
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.hero {
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 16px;
  height: calc(100vh - 60px);
  max-height: calc(100svh - 60px);
  background-size: cover;
  position: relative;

  .is-authenticated & {
    height: calc(100vh - 118px);
    max-height: calc(100svh - 118px);

    @media ($desktop) {
      height: 60vh;
    }
  }

  @media ($desktop) {
    height: 60vh;
  }

  @media ($mobile) {
    background-position: center;
  }
}

.hero-column {
  display: grid;
  place-items: end start;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  padding-bottom: 16px;
  width: 100%;

  @media ($desktop) {
    padding: 20px 16px;
  }
}

.hero-content {
  display: inline-block;
  padding: 16px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.9);

  @media ($desktop) {
    padding: 24px;
  }

  @media ($large-screen) {
    max-width: 50%;
  }

  h1 {
    font-family: $font-headings;
    font-size: 32px;
    line-height: 1.1;
    margin-top: 0;
    margin-bottom: 8px;

    @media ($desktop) {
      font-size: 40px;
      margin-bottom: 10px;
    }
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.3;
    margin-top: 0;

    @media ($desktop) {
      font-size: 18px;
    }
  }
}

.usp-container {
  margin-top: 0;
  margin-bottom: 24px;
  padding-left: 0;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  list-style-type: none;
}

.usp-container li {
  display: flex;
  align-items: center;
  text-align: start;
  font-size: 14px;
  font-weight: 600;
  line-height: 130%;
}

.usp-container li::before {
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  background-image: url('@/assets/svg/dark-check.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.cta-button {
  width: 100%;
}
</style>
