<template>
  <Spinner v-if="!ready || loading" />
  <div v-else class="wish-area-wrapper">
    <div
      ref="areaContainer"
      class="area-container"
      :class="{ 'area-container--no-scroll': showAreasPanel }"
    >
      <div class="area-inner">
        <div class="page-title">
          {{ $t('edit_wish_area_title') }}
        </div>

        <MobileTipBox
          v-if="mustSelectNewAreas"
          :title="$t('signup_tooltip_title')"
          :text="$t('edit_wish_area_infobox')"
          class="mobile-tip-box"
        />

        <template v-if="wishAreaOptions.length > 0">
          <div class="city-select-container">
            <BasePill
              v-for="cities in wishAreaOptions"
              :key="cities.city"
              class="city-select-item"
              @click="toggleAreasPanel(cities.city)"
            >
              {{ cities.city }}
            </BasePill>
          </div>

          <BasePanel v-if="showAreasPanel" @close="toggleAreasPanel">
            <div class="page-title">
              {{ $t('edit_wish_area_options_choose_areas_in') }}<br />
              {{ selectedCity }}
            </div>
            <BaseCheckbox
              v-for="option in selectedCityOptions"
              :key="option.name"
              :selected="isAreaSelected(option)"
              :name="option.name"
              :value="option.name"
              :is-checkbox-dark="true"
              :label="
                option.type === 'PostalCode' ||
                option.type === 'ThreeDigitPostalCode'
                  ? option.description
                  : option.name
              "
              style="margin-bottom: 12px"
              @change="handleAreaCheckboxClick(option)"
            />
            <template slot="button">
              <div class="panel-button-content">
                <template v-if="currentSelectedAreas.length > 0">
                  <BaseIcon icon="check-circle" />
                  {{ $t('edit_wish_area_options_choose_areas_button') }}
                </template>
                <template v-else>
                  <BaseIcon icon="close" />
                  {{ $t('edit_wish_area_options_cancel_button') }}
                </template>
              </div>
            </template>
          </BasePanel>
        </template>

        <div class="input-wrapper">
          <MapInputField
            v-if="!isMobile"
            :id="fuChrome"
            :description="
              $routeLocale === 'sv'
                ? 'Jag vill flytta till ett annat område'
                : ''
            "
            :placeholder="
              currentSelectedAreas.length >= 1
                ? $t('edit_wish_area_add_area')
                : $t('edit_wish_area_search_for')
            "
            :no-results-found="searchNoResultsFound"
            :no-results-message="$t('map_suggestions_no_results_wish_area')"
            :value="searchTerm"
            :suggestions="searchResult"
            :suggestion-callback="handleSuggestionClick"
            :handle-update="handleSearchTermUpdated"
            :has-focus="inputFieldFocused"
            :set-focus="handleFocus"
            :loading="searchLoading"
            :show-results-icon="true"
            input-icon="magnifying-glass"
          />

          <template v-if="isMobile">
            <p class="input-description">
              {{
                $routeLocale === 'sv'
                  ? 'Jag vill flytta till ett annat område'
                  : ''
              }}
            </p>
            <BaseInput
              name="mobile-free-text-input"
              type="text"
              icon="magnifying-glass"
              :placeholder="$t('edit_wish_area_search_for')"
              autocomplete="off"
              @focus="handleMapInputFieldClick"
            />
          </template>
        </div>

        <BasePanel
          v-if="showFreeTextSearchPanel"
          ref="freeTextSearchPanel"
          :disable-animation="true"
          @close="closeFreeTextSearchPanel"
        >
          <MapInputField
            :id="fuChrome"
            ref="mobileMapInputField"
            :description="
              $routeLocale === 'sv'
                ? 'Jag vill flytta till ett annat område'
                : ''
            "
            :placeholder="
              currentSelectedAreas.length >= 1
                ? $t('edit_wish_area_add_area')
                : $t('edit_wish_area_search_for')
            "
            :no-results-found="searchNoResultsFound"
            :no-results-message="$t('map_suggestions_no_results_wish_area')"
            :value="searchTerm"
            :suggestions="searchResult"
            :suggestion-callback="handleSuggestionClick"
            :handle-update="handleSearchTermUpdated"
            :has-focus="inputFieldFocused"
            :set-focus="handleFocus"
            :loading="searchLoading"
            :autofocus="true"
            :show-results-icon="true"
            input-icon="arrow-left"
            class="without-shadow"
            @input-icon-clicked="closeFreeTextSearchPanel"
          />
          <template slot="button">
            <div class="panel-button-content">
              <template>
                <BaseIcon icon="check-circle" />
                {{ $t('base_panel_close') }}
              </template>
            </div>
          </template>
        </BasePanel>

        <div v-if="!isMobile" class="selected-areas">
          <div
            v-for="area in currentSelectedAreas"
            :key="area.id"
            class="selected-area"
          >
            <span>{{ area.name }}, {{ area.description }}</span>
            <div
              class="delete-btn"
              @click.stop="() => handleRemoveArea(area)"
            ></div>
          </div>
        </div>

        <div v-if="isMobile" class="mobile-map-wrapper">
          <div class="mobile-ignore-margin">
            <AreaPickerMap
              :coordinates="$country.getValue('MAP_INITIAL_COORDINATES')"
              :zoom="$country.getValue('MAP_ZOOM')"
              :current-polygon="multiPolygon"
            />
          </div>
        </div>

        <div class="button-container">
          <div class="button-container-top">
            <span class="chosen-areas-label">
              {{ $t('edit_wish_area_chosen_areas') }}:
              <span v-if="currentSelectedAreas.length === 0">{{
                $t('edit_wish_area_none_selected')
              }}</span>
              <span v-else
                >{{ currentSelectedAreas.length }}
                {{ $t('edit_wish_area_pce') }}</span
              >
            </span>
            <button
              v-if="currentSelectedAreas.length"
              class="show-chosen-areas-button"
              @click="handleShowChosenAreasClick"
            >
              {{ $t('edit_wish_area_show') }}
            </button>
          </div>
          <div v-if="showErrors" class="error-message">
            {{ $t('edit_wish_area_error_message') }}
          </div>
          <BaseButton
            class="full-width button-go-to-next-page"
            @click="handleSaveClick()"
          >
            {{ $t('edit_wish_area_action') }}
          </BaseButton>
        </div>

        <BasePanel v-if="showChosenAreasPanel" @close="toggleChosenAreasPanel">
          <div class="page-title">
            {{ $t('edit_wish_area_my_chosen_areas') }}
          </div>
          <div class="mobile-areas">
            <div class="mobile-areas-list">
              <div
                v-for="area in currentSelectedAreas"
                :key="area.id"
                class="selected-area"
              >
                <span>{{ area.name }}, {{ area.description }}</span>
                <div
                  class="delete-btn"
                  @click.stop="() => handleRemoveArea(area)"
                ></div>
              </div>
            </div>
          </div>
          <template slot="button">
            <div class="panel-button-content">
              <template>
                <BaseIcon icon="check-circle" />
                {{ $t('base_panel_close') }}
              </template>
            </div>
          </template>
        </BasePanel>
      </div>
    </div>
    <div v-if="!isMobile" class="map-container">
      <AreaPickerMap
        :coordinates="$country.getValue('MAP_INITIAL_COORDINATES')"
        :zoom="$country.getValue('MAP_ZOOM')"
        :current-polygon="multiPolygon"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { uid } from 'uid';
import { debounce } from 'lodash';
import handleClickOutside from '@/utils/handleClickOutside';
import MapInputField from '../../components/FormElements/InputField/MapInputField';
import AreaPickerMap from '../../components/Maps/AreaPickerMap';
import Spinner from '../../components/Loading/Spinner';
import MobileTipBox from '@/components/Signup/MobileTipBox';

export default {
  name: 'EditWishAreas',
  components: {
    MapInputField,
    AreaPickerMap,
    Spinner,
    MobileTipBox
  },

  data() {
    return {
      inputFieldFocused: false,
      showErrors: false,
      showChosenAreasPanel: false,
      showAreasPanel: false,
      showFreeTextSearchPanel: false,
      selectedCity: '',
      propositionId: null,
      wishIndex: null
    };
  },
  head: {
    title() {
      return {
        inner: this.$t('meta_title_edit_wish_areas')
      };
    }
  },
  computed: {
    ...mapGetters({
      ready: 'app/ready',
      isMobile: 'screenSize/isMobile',
      searchTerm: 'wishAreas/searchTerm',
      searchResult: 'wishAreas/searchResult',
      selectedAreas: 'wishAreas/selectedAreas',
      multiPolygon: 'wishAreas/multiPolygon',
      searchLoading: 'wishAreas/searchLoading',
      wishAreaOptions: 'wishAreas/wishAreaOptions',
      loading: 'wishAreas/loading',
      searchNoResultsFound: 'wishAreas/searchNoResultsFound',
      isAppRequest: 'app/isAppRequest',
      mustSelectNewAreas: 'wishAreas/mustSelectNewAreas'
    }),

    selectedCityOptions() {
      return this.wishAreaOptions.find(
        option => option.city === this.selectedCity
      ).options;
    },

    fuChrome() {
      return uid(16);
    },

    canContinue() {
      return this.currentSelectedAreas.length > 0;
    },

    currentSelectedAreas() {
      return this.selectedAreas;
    }
  },

  watch: {
    canContinue() {
      this.showErrors = false;
    }
  },

  mounted() {
    const { propositionId, wishIndex } = this.$route.params;

    this.propositionId = propositionId;
    this.wishIndex = wishIndex;

    this.getWishAreaButtons();
    this.getExistingSelectedAreas({ propositionId, wishIndex });

    this.scrollToTop();

    if (this.isAppRequest) {
      const header = document.querySelector('.static-header');
      if (header) header.style.display = 'none';

      const wrapper = document.querySelector('.tab-bar__wrapper');
      if (wrapper) wrapper.style.display = 'none';

      const mapbox = document.querySelector('.mapboxgl-map');
      if (mapbox) mapbox.style.display = 'none';

      const mobileWrapper = document.querySelector('.mobile-map-wrapper');
      if (mobileWrapper) mobileWrapper.style.height = '200px';

      const link = document.querySelector('.link-button');
      if (link) link.style.display = 'none';
    }
  },

  destroyed() {
    this.resetState();

    this.setMustSelectNewAreas(false);
  },

  methods: {
    ...mapActions({
      setSearchTerm: 'wishAreas/setSearchTerm',
      getWishAreaButtons: 'wishAreas/getWishAreaButtons',
      performSearch: 'wishAreas/performSearch',
      blurAreaField: 'wishAreas/blurAreaField',
      selectArea: 'wishAreas/selectArea',
      deselectArea: 'wishAreas/deselectArea',
      getExistingSelectedAreas: 'wishAreas/getExistingSelectedAreas',
      updateAreas: 'wishAreas/updateAreas',
      resetState: 'wishAreas/resetState',
      toggleDisableScroll: 'ui/toggleDisableScroll'
    }),

    ...mapMutations({
      setMustSelectNewAreas: 'wishAreas/setMustSelectNewAreas'
    }),

    toggleAreasPanel(city) {
      this.showAreasPanel = !this.showAreasPanel;
      this.selectedCity = city;

      // Scroll to top in panel when openning, and on page when closing
      this.$refs.areaContainer.scrollTop = 0;
      this.scrollToTop();
    },

    handleAreaCheckboxClick(option) {
      if (!option) {
        return;
      }

      if (this.isAreaSelected(option)) {
        this.handleRemoveArea(option);
      } else {
        this.handleSelectArea(option);
      }
    },

    isAreaSelected(option) {
      if (!option) {
        return false;
      }

      return this.currentSelectedAreas.some(area => area.id == option.id);
    },

    handleShowChosenAreasClick() {
      this.showChosenAreasPanel = true;
    },

    toggleChosenAreasPanel() {
      this.showChosenAreasPanel = !this.showChosenAreasPanel;
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },

    goBack() {
      this.$router.go(-1);
    },

    initPerformSearch: debounce(function () {
      this.performSearch();
    }, 200),

    handleSearchTermUpdated(val) {
      this.setSearchTerm({ inputValue: val });

      if (val.length > 2) {
        this.initPerformSearch();
      }
    },

    handleSuggestionClick({ suggestion }) {
      const selectedIds = this.currentSelectedAreas.map(area => area.id);
      if (selectedIds.includes(suggestion.id)) {
        this.blurAreaField();
        return;
      }

      this.handleSelectArea(suggestion);

      this.closeFreeTextSearchPanel();
    },

    handleSelectArea(area) {
      this.selectArea({
        area: area
      });
    },

    handleRemoveArea(area) {
      this.deselectArea({
        area
      });
    },

    closeFreeTextSearchPanel() {
      this.showFreeTextSearchPanel = false;

      this.toggleDisableScroll('edit-wish-areas');
    },

    handleMapInputFieldClick() {
      if (this.isMobile) {
        this.showFreeTextSearchPanel = true;

        this.$nextTick(() => {
          // Focus on input field in panel
          this.$refs.mobileMapInputField.$refs.mapInput.$refs[
            this.fuChrome
          ].focus();

          // Scroll to top in panel
          this.$refs.freeTextSearchPanel.$refs.basePanel.scrollTop = 0;
        });

        this.toggleDisableScroll('edit-wish-areas');
      }
    },

    handleFocus() {
      this.inputFieldFocused = true;
      if (!this.isMobile) {
        document.addEventListener('mouseup', this.closeOnClickOutside);
      }
    },

    handleBlur() {
      this.inputFieldFocused = false;
      if (!this.isMobile) {
        document.removeEventListener('mouseup', this.closeOnClickOutside);
      }
    },

    closeOnClickOutside(e) {
      const suggestions = document.getElementById('js-suggestions-field');
      const input = document.getElementById(this.fuChrome);

      handleClickOutside({
        event: e,
        elements: [suggestions, input],
        action: this.handleBlur
      });
    },

    handleSaveClick() {
      if (this.canContinue) {
        this.updateAreas({
          propositionId: this.propositionId,
          wishIndex: this.wishIndex
        });
      } else {
        this.showErrors = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wish-area-wrapper {
  max-width: 100vw;
  width: 100%;
  margin: 0;
  padding-right: 12px;
  padding-left: 12px;
  padding-bottom: calc(176px + env(safe-area-inset-bottom) * 2);

  @media ($desktop) {
    display: flex;
    height: 100vh;
    padding-top: $desktop-menu-height;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 0;
  }
}

.area-container {
  position: relative;

  @media ($desktop) {
    display: flex;
    flex: 4;
    justify-content: center;
    overflow-y: auto;
    overflow-y: overlay;
  }

  &.area-container--no-scroll {
    @media ($desktop) {
      overflow: hidden;
    }
  }
}

.area-inner {
  position: relative;
  width: 100%;

  @media ($desktop) {
    max-width: 450px;
    padding: 20px;
  }

  @media ($mobile) {
    max-width: 100%;
  }
}

.page-title {
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 120%;
  margin: 16px 0 32px 0;

  @media ($desktop) {
    font-size: 2.4rem;
    margin-top: 20px;
  }
}

.map-container {
  flex: 6;
  position: sticky;
  top: $desktop-menu-height;
  height: calc(100vh - #{$desktop-menu-height});
}

.mobile-map-wrapper {
  width: 100%;
  position: relative;
  height: 400px;
}

.mobile-ignore-margin {
  width: 100vw;
  position: absolute;
  top: 0;
  left: -12px;
  height: 100%;
}

.selected-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $transfer-green;
  color: white;
  border-radius: 5px;
  font-weight: 600;
  margin: 8px 0;
  padding: 5px 12px;
  line-height: 1.5;
}

.delete-btn {
  cursor: pointer;
  height: 14px;
  width: 14px;
  background-position: center;
  background-size: contain;
  background-image: url('../../assets/svg/close-white.svg');
}

.subtitle {
  font-weight: 600;
  color: $text-secondary;
  font-size: 1.2rem;
  text-align: center;
  position: relative;
  top: -20px;
}

.input-description {
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 6px 0;
  color: #878b9d;
}

.city-select-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 22px;
}

.city-select-item {
  margin-right: 8px;
  margin-bottom: 8px;
}

.panel-button-content {
  display: flex;
  align-items: center;

  .base-icon {
    margin-right: 8px;
  }
}

.error-message {
  margin-bottom: 10px;
  margin-top: -12px;
  padding: 0 5px;
  font-weight: 600;
  font-size: 0.8rem;
  color: #ff3843;

  @media ($desktop) {
    margin-bottom: 30px;
  }
}

.button-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  padding-bottom: calc(76px + env(safe-area-inset-bottom));
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(20px);
  z-index: 2;

  @media ($mobile) {
    .must-select-new-areas & {
      padding-bottom: calc(20px + env(safe-area-inset-bottom));
    }
  }

  @media ($desktop) {
    position: relative;
    padding: 40px 0;
  }

  // Remove position fixed when keyboard is open
  // to prevent that too much screen space is covered
  @media (max-height: 500px) {
    position: absolute;
  }
}

.button-container-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
  margin-top: -5px;

  @media ($desktop) {
    display: none;
  }
}

.chosen-areas-label {
  font-weight: 700;
  font-size: 14px;
  padding-left: 5px;
}

.show-chosen-areas-button {
  font-family: inherit;
  padding: 3px 10px;
  font-weight: 700;
  font-size: 14px;
  color: $main-blue;
  background-color: transparent;
  border: none;
  outline: none;
  user-select: none;
  cursor: pointer;
  -webkit-appearance: none;
}

.button-go-to-next-page {
  margin-bottom: env(safe-area-inset-bottom);
}

.mobile-tip-box {
  margin-top: -10px;

  @media ($desktop) {
    margin-bottom: 28px;
  }
}
</style>
