<template>
  <div class="static-page">
    <div class="page-container">
      <div v-html="content.PageContent"></div>
    </div>

    <TheFooter :is-authenticated="isAuthenticated" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import TheFooter from '@/components/TheFooter';

export default {
  name: 'StaticPage',

  components: {
    TheFooter
  },

  async beforeRouteUpdate(to, from, next) {
    await this.init();
    next();
  },

  data() {
    return {
      content: {}
    };
  },

  head() {
    return {
      title() {
        return {
          inner: this.content.PageTitle || ''
        };
      },
      meta: this.setSEONoIndex
        ? [
            {
              id: 'robots',
              name: 'robots',
              content: 'noindex'
            }
          ]
        : undefined
    };
  },

  computed: {
    ...mapGetters({
      isAuthenticated: 'app/isAuthenticated'
    }),
    setSEONoIndex() {
      let slug = this.$route.params.slug || this.$route.redirectedFrom;
      if (['NL', 'DE'].includes(this.$country.currentCountry.toUpperCase())) {
        return `/${slug}` === this.$t('path_general_terms');
      }
      return false;
    }
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      let slug = this.$route.params.slug || this.$route.redirectedFrom;

      // Remove the first "/" character if it exists
      if (slug.startsWith('/')) {
        slug = slug.substring(1);
      }

      try {
        const response = await axios.get(
          `https://cms.lagenhetsbyte.se/static-pages/${slug}?_locale=${this.$routeLocale}`
        );
        this.content = response.data;

        window.scrollTo(0, 0);
        this.$emit('updateHead');
      } catch (error) {
        //
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.static-page {
  max-width: none;
  width: 100%;
  font-size: 16px;
  line-height: 1.5;

  @media ($desktop) {
    padding-top: 120px;
    padding-bottom: 0;
  }
}

.page-container {
  max-width: 900px;
  min-height: 100vh;
  margin-left: auto;
  margin-right: auto;
  padding: 0 16px;

  img {
    width: 100%;
  }
}
</style>
