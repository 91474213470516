<template>
  <footer v-if="!isAppRequest" class="page-footer">
    <div class="footer-content">
      <div v-if="!isAuthenticated" class="footer-content-section">
        <div class="footer-content-section-title rocket">
          {{ $t('the_footer_get_started') }}
        </div>
        <a
          :href="$t('path_add_proposition', $routeLocale)"
          class="footer-content-section-link"
        >
          {{ $t('the_footer_get_started_for_free') }}
        </a>

        <template>
          <router-link class="footer-content-section-link" :to="$routes.SEARCH">
            {{ $t('the_footer_search_ads') }}
          </router-link>
        </template>

        <router-link
          :to="$t('path_login', $routeLocale)"
          class="footer-content-section-link"
        >
          {{ $t('the_footer_log_in') }}
        </router-link>
      </div>

      <div class="footer-content-section">
        <div class="footer-content-section-title lightbulb">
          {{ $t('the_footer_read_more') }}
        </div>
        <router-link
          :to="$t('path_news', $routeLocale)"
          class="footer-content-section-link"
        >
          {{ $t('the_footer_news') }}
        </router-link>
        <a
          v-if="$country.isCountry('se')"
          :href="$t('path_swap_application', $routeLocale)"
          class="footer-content-section-link"
        >
          {{ $t('the_footer_bytesansokan') }}
        </a>
      </div>

      <div class="footer-content-section">
        <div class="footer-content-section-title binoculars">
          {{ $t('the_footer_about_lb') }}
        </div>
        <router-link
          :to="$t('path_about_us', $routeLocale)"
          class="footer-content-section-link"
        >
          {{ $t('about_us') }}
        </router-link>
        <router-link
          :to="$t('path_general_terms', $routeLocale)"
          class="footer-content-section-link"
        >
          {{ $t('the_footer_terms') }}
        </router-link>
        <router-link
          v-if="$country.isCountry('de')"
          :to="$t('path_impressum', $routeLocale)"
          class="footer-content-section-link"
        >
          {{ $t('the_footer_impressum') }}
        </router-link>
        <router-link
          :to="$t('path_cookie_policy', $routeLocale)"
          class="footer-content-section-link"
        >
          {{ $t('the_footer_cookie_policy') }}
        </router-link>
        <router-link
          :to="$t('path_sitemap', $routeLocale)"
          class="footer-content-section-link"
        >
          {{ $t('the_footer_sitemap') }}
        </router-link>
      </div>

      <div class="footer-support-box">
        <div class="footer-content-section-title support">
          {{ $t('the_footer_customer_service') }}
        </div>
        <router-link
          :to="$t('path_help', $routeLocale)"
          class="footer-content-section-link"
        >
          {{ $t('the_footer_help') }}
        </router-link>

        <div class="footer-support-box-contact">
          <a
            v-if="$country.isCountry('se')"
            :href="`tel:${$t('the_footer_tel_link', $routeLocale)}`"
            class="support-number"
          >
            {{ $t('the_footer_tel_visible', $routeLocale) }}
          </a>
          <a
            :href="`mailto:${$t('the_footer_email', $routeLocale)}`"
            class="support-email"
          >
            <span class="bold">{{ $t('the_footer_email_label') }}</span>
            {{ $t('the_footer_email', $routeLocale) }}
          </a>
        </div>
      </div>
    </div>
    <div class="footer-footer">
      <p>
        {{
          $t('the_footer_copyright', { currentYear: new Date().getFullYear() })
        }}
      </p>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TheFooter',
  props: {
    isAuthenticated: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      isAppRequest: 'app/isAppRequest'
    })
  }
};
</script>

<style lang="scss" scoped>
.page-footer {
  width: 100%;
  margin-top: 40px;
  border-top: 2px solid #f1f2f6;

  @media ($desktop) {
    margin-top: 100px;
  }
}

.footer-content {
  padding-top: 20px;
  margin: auto;
  max-width: 1300px;

  @media ($desktop) {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    padding: 60px 0;
  }
}

.footer-content-section {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.footer-content-section > *,
.footer-support-box > * {
  margin-bottom: 15px;
}

.footer-content-section-title {
  position: relative;
  margin-left: 30px;
  font-weight: 700;
}

.footer-content-section-title::before {
  position: absolute;
  content: '';
  background-position: center;
  left: -30px;
  background-size: contain;
  background-repeat: no-repeat;
  height: 20px;
  width: 20px;
}
.footer-content-section-title.rocket::before {
  background-image: url('../assets/svg/space-rocket.svg');
}
.footer-content-section-title.lightbulb::before {
  background-image: url('../assets/svg/lightbulb2.svg');
}
.footer-content-section-title.binoculars::before {
  background-image: url('../assets/svg/binoculars.svg');
}
.footer-content-section-title.support::before {
  background-image: url('../assets/svg/support2.svg');
}

.footer-content-section-link {
  text-decoration: none;
  color: #878b9d;
  font-size: 14px;
  font-weight: 600;
}

.footer-support-box {
  padding: 20px;
  background-color: #f8f8fa;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 8px;
}

.footer-support-box-mobile {
  padding: 40px 0;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.footer-support-box-contact {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #ddd;
}

.support-number {
  color: $main-blue;
  text-decoration: none;
  font-size: 1.3rem;
  font-weight: 700;
  margin-bottom: 5px;

  @media ($mobile) {
    color: #2c3e50;
    font-size: 30px;
  }
}

.support-email {
  text-decoration: none;
  color: #878b9d;
  font-size: 14px;
  font-weight: 600;
}

.footer-footer {
  background-color: #f8f8fa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  opacity: 0.7;

  padding: 30px 60px;
  width: 100%;
  margin: 0 auto;

  & p {
    font-size: 14px;
    margin: 8px 0 0 0;
    font-weight: 600;
  }

  @media ($mobile) {
    padding: 20px 0;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;

    & p {
      margin: 8px 0 0 0;
      font-size: 0.8rem;
      text-align: center;
    }
  }
}
</style>
