<template>
  <div class="my-propositions">
    <div class="page-container">
      <div v-if="!ready || loadingPropositions" class="propositions-container">
        <h2 class="page-heading">{{ $t('my_propositions_loading') }}</h2>
      </div>

      <div v-else class="propositions-container">
        <h2 class="page-heading">
          {{ $t('menu_items_link_3') }}
        </h2>

        <router-link :to="$routes.EDIT_PROFILE" class="edit-profile-link">
          <img :src="user.avatar" alt="" />
          <div class="name-and-edit-link">
            <div class="name">
              {{ user.name }}
              <BaseIcon
                v-if="user.verified"
                icon="verified"
                :width="14"
                :height="14"
                class="verified-badge"
              />
            </div>
            <div class="edit-link">
              {{ $t('popup_menu_item_2') }}
            </div>
          </div>
          <BaseIcon
            :icon-file="'chevron-right-dark'"
            :width="20"
            :height="20"
            class="icon"
          />
        </router-link>

        <div v-if="shouldShowOnboardingBanner" class="onboarding-wrapper">
          <OnboardingBanner />
        </div>

        <VerifyBanner
          v-if="$country.isFeatureEnabled('BANKID') && user && !user.verified"
          class="verify-wrapper"
        />

        <NoActivePropositionInfo
          v-if="
            (!activePropositions.length > 0 &&
              (activePropositions.length === 0 ||
                displayedOutdatedTrial !== null)) ||
            propositions.length === 0
          "
          :info-type="propositionInfoType"
        />

        <template v-if="propositions.length > 0">
          <div class="my-propositions-card">
            <MyPropositionsCard :proposition="currentlyEditingProposition" />
          </div>

          <div class="in-swap-process-toggle">
            <BaseCheckbox
              id="inSwapProcess"
              :label="$t('edit_profile_in_swap_process')"
              :value="inSwapProcess"
              :selected="inSwapProcess"
              :is-checkbox-dark="true"
              variant="label-light"
              size="sm"
              class="checkbox"
              @change="toggleInSwapProcess"
            />
          </div>

          <PropositionSwitcher
            v-if="activePropositions.length > 1"
            class="prop-switcher"
          />

          <div class="proposition-links-container">
            <PropositionEditSwitcher
              v-if="propositions.length > 1"
              class="prop-edit-switcher"
            />
            <LinkList class="proposition-links">
              <LinkListItem
                :path="
                  $t('path_edit_proposition', $routeLocale) +
                  '/' +
                  currentlyEditingPropositionId
                "
                :icon-file="'pen'"
                >{{ $t('my_propositions_link_edit') }}</LinkListItem
              >
              <LinkListItem
                :path="
                  $t('path_edit_proposition_wishes', $routeLocale) +
                  '/' +
                  currentlyEditingPropositionId
                "
                :icon-file="'map-pin'"
                >{{ $t('my_propositions_link_wish') }}</LinkListItem
              >
              <LinkListItem
                v-if="hasSecondWish"
                :path="
                  $t('path_edit_proposition_second_wishes', $routeLocale) +
                  '/' +
                  currentlyEditingPropositionId
                "
                :icon-file="'map-pin'"
              >
                {{ $t('my_propositions_link_second_wish') }}
              </LinkListItem>
              <LinkListItem
                :path="
                  $t('path_handle_subscription', $routeLocale) +
                  '/' +
                  currentlyEditingPropositionId
                "
                :icon-file="'credit-card'"
                >{{ $t('my_propositions_link_payments') }}</LinkListItem
              >

              <div
                class="my-visitors-link-container"
                @click="handleShowMyVisitorsPaywall"
              >
                <LinkListItem
                  :path="
                    currentlyEditingProposition.active &&
                    !currentlyEditingProposition.isTrial &&
                    !currentlyEditingProposition.isFreemium
                      ? $t('path_my_visitors', $routeLocale) +
                        '/' +
                        currentlyEditingPropositionId
                      : ''
                  "
                  :icon-file="'user-search'"
                  >{{ $t('my_propositions_link_visitors') }}</LinkListItem
                >
              </div>
              <LinkListItem
                :path="$routes.BLOCKED_PROPOSITIONS"
                :icon-file="'blocked-house'"
                >{{ $t('side_menu_removed_swap_suggestions') }}</LinkListItem
              >
              <LinkListItem
                v-if="
                  $country.isFeatureEnabled('EXTRA_VISIBILITY') &&
                  currentlyEditingProposition.active &&
                  !currentlyEditingProposition.isFreemium &&
                  !currentlyEditingProposition.isTrial &&
                  !currentlyEditingProposition.extraVisibility
                "
                :icon-file="'stars-white2'"
                class="boost-list-item"
                @click.native="
                  setExtraVisibilityPaymentProposition(
                    currentlyEditingProposition
                  );
                  setExtraVisibilityPaymentVisible(true);
                "
                >{{ $t('boost_cta_button') }}
                <template #icon-after>
                  <BaseIcon
                    :icon-file="'chevron-right-white2'"
                    :width="20"
                    :height="20"
                    class="chevron-icon"
                  />
                </template>
              </LinkListItem>
            </LinkList>
          </div>
        </template>

        <LinkList :compact="true" class="new-and-delete-links">
          <LinkListItem
            :path="$t('path_add_proposition', $routeLocale)"
            :is-external="true"
            :icon-file="'plusminus'"
            >{{ $t('my_propositions_link_new') }}</LinkListItem
          >
          <LinkListItem
            v-if="activePropositions.length > 0"
            :path="
              $t('path_handle_subscription', $routeLocale) +
              '/' +
              currentlyEditingPropositionId
            "
            :icon-file="'trash-bin'"
          >
            {{ $t('my_propositions_link_cancel') }}
          </LinkListItem>
        </LinkList>

        <div v-if="!isAppRequest" class="log-out">
          <router-link :to="$routes.LOGOUT">{{
            $t('popup_menu_item_8')
          }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { lbApiBeta } from '../../utils/axiosConfig';
import events from '@/utils/helpers/events';

import MyPropositionsCard from '../../components/PropositionCards/MyPropositionsCard';
import NoActivePropositionInfo from '../../components/MyPropositions/PropositionInfo';
import OnboardingBanner from '../../components/Onboarding/OnboardingBanner';
import VerifyBanner from '../../components/Onboarding/VerifyBanner';
import PropositionSwitcher from '../../components/PropositionSwitcher/PropositionSwitcher';
import PropositionEditSwitcher from '../../components/PropositionSwitcher/PropositionEditSwitcher';
import LinkList from '@/components/Navigation/LinkList/LinkList.vue';
import LinkListItem from '@/components/Navigation/LinkList/LinkListItem.vue';

export default {
  name: 'MyPropositions',

  components: {
    MyPropositionsCard,
    NoActivePropositionInfo,
    OnboardingBanner,
    VerifyBanner,
    PropositionSwitcher,
    PropositionEditSwitcher,
    LinkList,
    LinkListItem
  },

  data() {
    return {
      inSwapProcess: false
    };
  },

  head: {
    title() {
      return {
        inner: this.$t('meta_title_my_propositions')
      };
    }
  },

  computed: {
    ...mapGetters({
      ready: 'app/ready',
      user: 'app/user',
      loadingPropositions: 'myPropositions/loadingPropositions',
      fetchingPropositions: 'myPropositions/fetchingPropositions',
      propositions: 'myPropositions/propositions',
      displayedOutdatedTrial: 'trial/displayedOutdatedTrial',
      shouldShowOnboardingBanner: 'myPropositions/onboardingState',
      primaryPropositionId: 'myPropositions/primaryPropositionId',
      currentlyEditingProposition: 'myPropositions/currentlyEditingProposition',
      isAppRequest: 'app/isAppRequest'
    }),

    currentlyEditingPropositionId() {
      return this.currentlyEditingProposition.propositionId;
    },

    residences() {
      return this.currentlyEditingProposition.residences;
    },

    primaryProposition() {
      return this.propositions.find(
        proposition => proposition.propositionId === this.primaryPropositionId
      );
    },

    propositionInfoType() {
      if (this.displayedOutdatedTrial) {
        return 1;
      } else if (this.propositions.length === 0) {
        return 2;
      } else {
        return 0;
      }
    },

    activePropositions() {
      return this.propositions
        .filter(proposition => proposition.active)
        .sort((a, b) => {
          return a.isPrimary > b.isPrimary ? -1 : 1;
        });
    },

    hasSecondWish() {
      return this.currentlyEditingProposition.wishes.length > 1;
    }
  },

  watch: {
    async currentlyEditingProposition() {
      this.setInSwapProcess();
    }
  },

  created() {
    if (!this.fetchingPropositions && this.ready) this.fetchPropositions();

    if (this.$country.isFeatureEnabled('PAYMENT')) {
      this.getSetHasPaymentToken();
    }

    this.setInSwapProcess();

    this.refreshPropositions();
  },

  mounted() {
    this.showMobileTabBar();

    this.$nextTick(() => {
      window.scrollTo(0, 0);
    });
  },

  methods: {
    ...mapActions({
      fetchPropositions: 'myPropositions/fetchPropositions',
      refreshPropositions: 'myPropositions/refreshPropositions',
      showMobileTabBar: 'menu/showMobileTabBar',
      getSetHasPaymentToken: 'payment/getSetHasPaymentToken',
      setGlobalModal: 'ui/setGlobalModal'
    }),

    ...mapMutations({
      setExtraVisibilityPaymentVisible: 'extraVisibility/setVisible',
      setExtraVisibilityPaymentProposition: 'extraVisibility/setProposition'
    }),

    async toggleInSwapProcess() {
      this.inSwapProcess = !this.inSwapProcess;

      try {
        await lbApiBeta.post(
          `/api/v2/proposition/${this.currentlyEditingProposition.propositionId}/in-swap-process`,
          {
            inSwapProcess: this.inSwapProcess
          }
        );

        this.refreshPropositions();
      } catch (error) {
        //
      }
    },

    setInSwapProcess() {
      if (!this.currentlyEditingProposition) return;

      this.inSwapProcess = this.currentlyEditingProposition.inSwapProcess;
    },

    handleShowMyVisitorsPaywall() {
      this.setGlobalModal('MyVisitorsLocked');

      events.emitEvent(events.eventTypes.SEE, 'Paywall', {
        trigger: 'my-visitors-locked',
        src: 'my-propositions'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.my-propositions {
  max-width: none;
  width: 100%;
  min-height: 100vh;
  background-color: #faf9f8;

  @media ($desktop) {
    padding-top: 80px;
    padding-bottom: 0;
  }
}

.page-container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 16px;
}

.page-heading {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.264px;
  margin-top: 24px;
  margin-bottom: 16px;
}

.edit-profile-link {
  display: flex;
  align-items: center;
  padding: 8px;
  color: #0b182c;
  border-bottom: 1px solid #dddcdb;
  padding-bottom: 16px;
  margin-bottom: 24px;
}

.edit-profile-link img {
  width: 48px;
  height: 48px;
  margin-right: 16px;
  border-radius: 50%;
  object-fit: cover;
}

.name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 600;
  margin-bottom: 4px;
}

.edit-link {
  color: #485261;
  font-size: 14px;
}

.verified-badge {
  margin-left: 4px;
}

.edit-profile-link .icon {
  margin-left: auto;
}

.onboarding-wrapper {
  margin-bottom: 20px;
}

.verify-wrapper {
  margin-bottom: 40px;
}

.my-propositions-card {
  margin-top: 32px;
}

.prop-switcher {
  margin-top: 24px;
}

.proposition-links-container {
  border-top: 1px solid #dddcdb;
  padding-top: 16px;
  margin-top: 16px;
}

.proposition-links {
  margin-bottom: 12px;
  margin-top: 0;
}

.new-and-delete-links {
  padding: 16px 0;
  border-top: 1px solid #dddcdb;
  border-bottom: 1px solid #dddcdb;
}

.boost-list-item {
  background: linear-gradient(85deg, #7b52ff 0%, #4719dc 67.99%);
  color: #faf9f8;
}

.log-out {
  display: flex;
  justify-content: center;
  font-size: 14px;
  margin-top: 32px;
  margin-bottom: 48px;
}
</style>
