<template>
  <div
    v-if="shouldRenderCard"
    class="proposition-card-wrapper"
    :class="{ 'blur-content': isBlurred, 'blur-card': isBlurredDistribution }"
  >
    <component
      :is="isBlurred || disableLink ? 'div' : 'router-link'"
      :to="{
        query: {
          propositionPageId: swap.propositionId,
          type: swap.details ? swap.details.type : null
        }
      }"
      :class="[
        'proposition-card',
        { 'disable-link': disableLink, 'blur-content': isBlurred }
      ]"
      :style="usedInTutorial ? { overflow: 'visible' } : {}"
      @click="handlePropositionCardClick"
      @click.native="handlePropositionCardClick"
      @click.right.native="handlePropositionCardRightClick"
    >
      <div v-if="isBlurred" class="blurred-cta-container">
        <BaseButton
          icon-file="icon-premium-white"
          :class="[
            'blurred-button',
            {
              smaller: isMobile
            }
          ]"
        >
          {{ $t('unlock_with_premium') }}
        </BaseButton>
      </div>

      <!-- RESIDENCE HEADER BLOCK -->
      <div
        :class="['residence-header', { 'used-in-tutorial': usedInTutorial }]"
      >
        <SwapListImageSlider
          v-if="
            !swap.boosted &&
            !usedInBoostBlock &&
            currentShownResidence.pictures &&
            currentShownResidence.pictures.length > 0
          "
          :key="currentResidence"
          :current-shown-residence="currentShownResidence"
          :is-blurred="isBlurred"
          :proposition-id="swap.propositionId.toString()"
          :current-residence="currentResidence"
        />
        <div
          v-else-if="
            (swap.boosted || usedInBoostBlock) &&
            currentShownResidence.pictures &&
            currentShownResidence.pictures.length > 0
          "
          :class="['primary-image-container']"
        >
          <img
            :src="
              currentShownResidence.pictures &&
              currentShownResidence.pictures.length !== 0
                ? getImageThumbnail(currentShownResidence.pictures[0])
                : currentShownResidence.image || fallbackImage
            "
            :class="[
              'primary-image',
              {
                'is-blurred': isBlurred,
                'large-image': usedInBoostBlock
              }
            ]"
          />
        </div>
        <img
          v-else-if="
            propositionImagesArray && propositionImagesArray.length > 0
          "
          :src="propositionImagesArray[0]"
          alt=""
          class="img-placeholder"
        />
        <div v-else class="placeholder-container">
          <div
            class="img-placeholder no-image"
            :class="{ 'large-image': usedInBoostBlock || swap.boosted }"
          ></div>
          <p :class="{ 'large-image': usedInBoostBlock || swap.boosted }">
            {{ $t('proposition_missing_image_text') }}
          </p>
        </div>

        <div class="top-label-container">
          <AmenitiesLabels
            v-if="!isBlurred"
            :residence="currentShownResidence"
            class="amenities-labels"
          />
        </div>

        <div
          v-if="(swap.boosted || alwaysShowBoostIcon) && showBoostIcon"
          :class="[
            'boost-icon',
            { 'always-show-boost-icon': alwaysShowBoostIcon }
          ]"
        >
          <BaseIcon icon-file="icon-rocket-white" />
        </div>

        <div class="bottom-label-container">
          <div
            v-if="
              swapType &&
              !(isBlurred || usedInTutorial) &&
              swap.swapType !== primaryProposition.swapType
            "
            class="swap-type-label"
          >
            {{ swapType.label }}
          </div>

          <InfoLabels
            :key="swap.inSwapProcess"
            :swap="swap"
            class="info-labels"
          />
        </div>
      </div>

      <!-- RECIDENCE ADDITIONAL IMAGES BLOCK -->
      <div
        v-if="
          showAdditionalImages &&
          currentShownResidence.pictures &&
          currentShownResidence.pictures.length >= 3
        "
        class="secondary-images-container"
      >
        <img :src="getImageThumbnail(currentShownResidence.pictures[1])" />
        <img :src="getImageThumbnail(currentShownResidence.pictures[2])" />
      </div>

      <!-- CURRENT SHOWN BLOCK -->
      <div v-if="is2to1 && showTwoToOneBlock" class="current-shown-proposition">
        <div>{{ $t('proposition_card_show_apt') }}:</div>
        <div class="switch-proposition-buttons">
          <button
            :class="{ active: currentResidence === 0 }"
            @click.prevent.stop="switchCurrentShown(0)"
          >
            1
          </button>
          <button
            :class="{ active: currentResidence === 1 }"
            @click.prevent.stop="switchCurrentShown(1)"
          >
            2
          </button>
        </div>
      </div>

      <!-- RESIDENCE ADDRESS AND LOCATION -->
      <div class="address-and-info-wrapper">
        <div class="address-and-badge-container">
          <div class="adress-container">
            <div class="street-address">
              {{ currentShownResidence.streetAddress }}
              <BaseIcon
                v-if="swap.verified"
                icon-file="verified-white-check"
                :width="15"
                :height="15"
                class="verified-badge"
              />
            </div>

            <div
              :class="[
                'postal-area',
                { 'diff-value': swap.diff && swap.diff.geolocation }
              ]"
            >
              {{
                currentShownResidence.postalArea
                  ? currentShownResidence.postalArea
                  : currentShownResidence.postalAddress
              }}
            </div>
          </div>

          <div class="swap-type-badge-container">
            <div class="swap-type-badge">
              {{
                swap.details && swap.details.type === 'directswap'
                  ? $t('swap_list_item_direct_swap')
                  : swap.details && swap.details.type === 'triangleswap'
                  ? $t('swap_list_item_triangle_swap')
                  : swap.details && swap.details.type === 'manual'
                  ? $t('swap_list_item_direct_swap')
                  : ''
              }}
            </div>
          </div>
        </div>

        <!-- RECIDENCE INFO BLOCK -->
        <div class="residence-info-bar">
          <div>
            <span :class="{ 'diff-value': swap.diff && swap.diff.rooms }">
              {{ currentShownResidence.rooms }}
              {{ $t('swap_item_rooms') }} &#8729;
            </span>
            <span :class="{ 'diff-value': swap.diff && swap.diff.sqm }">
              {{ currentShownResidence.sqm }}
              {{ $t('swap_item_sqm') }} &#8729;
            </span>
            <span :class="{ 'diff-value': swap.diff && swap.diff.floor }">
              {{
                currentShownResidence.floor !== 0 &&
                currentShownResidence.floor !== 999
                  ? $t('proposition_description_floor')
                  : ''
              }}
              {{ formattedFloor }}
            </span>
          </div>
          <span :class="{ 'diff-value': swap.diff && swap.diff.rent }">
            <b>{{ spacedRent }}</b>
            {{ $t('swap_item_cost_per_month') }}
          </span>
        </div>
      </div>

      <!-- INTEREST TIMER BLOCK -->
      <div v-if="showTimerBlock" class="interest-timer-block">
        <div class="interest-timer">
          <BaseIcon
            icon-file="stopwatch"
            :width="24"
            :height="24"
            class="stopwatch-icon"
          />
          <div>Svara inom 6 dagar</div>
        </div>
        <BaseIcon
          icon-file="questionmark"
          :width="20"
          :height="20"
          class="questionmark-icon"
        />
      </div>

      <!-- INTEREST BUTTONS BLOCK -->
      <InterestButtons
        v-if="
          (interests && showInterestButtonsBlock && isUserList) || isBlurred
        "
        class="interest-buttons"
        :interested="interests[0]"
        :target-proposition-id="swap.propositionId.toString()"
        :swap="swap"
        :used-in-tutorial="usedInTutorial"
        :context="context"
        :is-blurred="isBlurred"
        :reverse-is-shown="reverseIsShown"
        @interested="$emit('interested', $event)"
        @no-interest="$emit('no-interest', $event)"
        @open-chat="openPropositionModalTab('chatTab')"
      />
    </component>
    <div v-if="showTutorialPointer">
      <InterestPointer />
    </div>
  </div>
</template>

<script>
import InterestIndicator from '@/components/PropositionCard/InterestIndicator';
import InterestButtons from '@/components/PropositionCard/InterestButtons';
import AmenitiesLabels from '@/components/ImageSlider/AmenitiesLabels';
import InfoLabels from '@/components/ImageSlider/InfoLabels';
import InterestPointer from '@/components/ContextualTutorial/InterestPointer';
import events from '@/utils/helpers/events';
import { resizeImage } from '@/utils/helpers/image';
import { mapActions, mapGetters } from 'vuex';
import {
  NO_IMAGE,
  NO_IMAGE_PIC_1,
  NO_IMAGE_PIC_2,
  NO_IMAGE_PIC_3
} from '@/utils/helpers/default-values';
import { addRightClickTracker } from '@/utils/helpers/rightClick';

import { SWAP_VIEW_TABS } from '@/store/modules/swapList';
import SwapListImageSlider from './ImageSlider/SwapListImageSlider.vue';
import { SWAP_FILTER_TYPES } from '../store/modules/swapList';

export default {
  name: 'BasePropositionCardNew',

  components: {
    InterestIndicator,
    InterestButtons,
    AmenitiesLabels,
    InfoLabels,
    InterestPointer,
    SwapListImageSlider
  },

  props: {
    swap: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      default: 0
    },
    disableLink: {
      type: Boolean,
      default: false
    },
    isBlurred: {
      type: Boolean,
      default: false
    },
    showFavouriteButton: {
      type: Boolean,
      default: true
    },
    showAdditionalImages: {
      type: Boolean,
      default: true
    },
    showTwoToOneBlock: {
      type: Boolean,
      default: true
    },
    showInterestStatusBlock: {
      type: Boolean,
      default: true
    },
    showTimerBlock: {
      type: Boolean,
      default: false
    },
    showInterestButtonsBlock: {
      type: Boolean,
      default: true
    },
    usedInTutorial: {
      type: Boolean,
      default: false
    },
    alwaysShowBoostIcon: {
      type: Boolean,
      default: false
    },
    showBoostIcon: {
      type: Boolean,
      default: true
    },
    showTutorialPointer: {
      type: Boolean,
      default: false
    },
    usedInBoostBlock: {
      type: Boolean,
      default: false
    },
    context: {
      type: String,
      default: null
    },
    reverseIsShown: {
      type: Boolean,
      default: false
    },
    isBlurredDistribution: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      SWAP_VIEW_TABS: SWAP_VIEW_TABS,
      SWAP_FILTER_TYPES,
      currentResidence: 0,
      NO_IMAGE,
      NO_IMAGE_PIC_1,
      NO_IMAGE_PIC_2,
      NO_IMAGE_PIC_3,
      slickSettings: {
        dotsClass: 'slick-dots custom-dot-class',
        edgeFriction: 0.35,
        infinite: false,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1
      },
      shouldRenderCard: false
    };
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile',
      isUserList: 'swapList/isUserList',
      primaryPropositionId: 'myPropositions/primaryPropositionId',
      primaryProposition: 'myPropositions/primaryProposition',
      swapFilterType: 'swapList/swapFilterType'
    }),

    payWallTrigger() {
      let trigger = 'content-blocked-unanswered';
      switch (this.swapFilterType) {
        case SWAP_FILTER_TYPES.LAST_PART_NEW:
          trigger = 'content-blocked-last-part';
          break;
        case SWAP_FILTER_TYPES.ANSWERED:
          trigger = 'content-blocked-answered';
          break;
        case SWAP_FILTER_TYPES.REMOVED:
          trigger = 'content-blocked-removed';
          break;
        default:
          trigger = 'content-blocked-unanswered';
      }
      return trigger;
    },

    residence1() {
      return this.swap.residences ? this.swap.residences[0] : null;
    },

    residence2() {
      return this.swap.residences[1];
    },

    interests() {
      return this.swap.details?.interests;
    },

    swapPercentage() {
      const { interests } = this;
      if (!interests) {
        return;
      }
      const interestedCount = interests.filter(interest => interest).length;

      const percentage = interestedCount / interests.length;
      if (percentage * 100 < 80) {
        return Math.floor(percentage * 100);
      }
      return Math.round(percentage * 100);
    },

    spacedRent() {
      return this.currentShownResidence.rent
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },

    is2to1() {
      return this.swap.residences?.length > 1;
    },

    currentShownResidence() {
      if (!this.is2to1) {
        return this.residence1;
      } else {
        return this.swap.residences[this.currentResidence];
      }
    },

    currentSecondaryResidence() {
      if (this.currentResidence === 0) {
        return this.residence2;
      } else {
        return this.residence1;
      }
    },

    swapType() {
      switch (this.swap.swapType) {
        case 21:
          return { label: this.$t('general_2_for_1'), color: '#ED63D2' };
        case 12:
          return { label: this.$t('general_1_for_2'), color: '#FFC212' };
        case 11:
          return { label: this.$t('general_1_for_1'), color: '#409FFF' };
        default:
          return null;
      }
    },

    formattedFloor() {
      switch (this.currentShownResidence.floor) {
        case 0:
          return this.$t('general_ground_floor_short');
        case 999:
          return this.$t('general_attic_short');
        default:
          return this.currentShownResidence.floor;
      }
    },

    fallbackImage() {
      const fallbackImages = [NO_IMAGE_PIC_1, NO_IMAGE_PIC_2, NO_IMAGE_PIC_3];
      const fallbackImage = fallbackImages[this.index % fallbackImages.length];

      return fallbackImage;
    },

    propositionImagesArray() {
      if (this.currentShownResidence.images) {
        return this.currentShownResidence.images.map(
          imageObject => imageObject.fileName
        );
      } else {
        return null;
      }
    }
  },

  mounted() {
    this.shouldRenderCard = true;
  },

  methods: {
    ...mapActions({
      markFavourite: 'favourites/markFavourite',
      unmarkFavourite: 'favourites/unmarkFavourite',
      fetchSwapDetails: 'swapList/fetchSwapDetails',
      setGlobalModal: 'ui/setGlobalModal',
      setCurrentSwapViewTab: 'swapList/setCurrentSwapViewTab',
      setPageModalActivePage: 'ui/setPageModalActivePage'
    }),

    handleMarkFavourite() {
      if (this.swap.isFavourite) {
        if (this.usedInTutorial) {
          this.$emit('clickedFavourite', false);
        } else {
          events.emitEvent(events.eventTypes.CLICK, 'Favourite Unmark', {
            src: `swaplist`
          });

          this.unmarkFavourite({ propositionId: this.swap.propositionId });
        }
      } else {
        if (this.usedInTutorial) {
          this.$emit('clickedFavourite', true);
        } else {
          events.emitEvent(events.eventTypes.CLICK, 'Favourite Mark', {
            src: `swaplist`
          });

          this.markFavourite({ propositionId: this.swap.propositionId });
        }
      }
    },

    switchCurrentShown(switchTo) {
      this.currentResidence = switchTo;
    },

    getImageThumbnail(image, size = '800x800') {
      return resizeImage(image, size);
    },

    triggerPropositionClickedEvent() {
      events.emitEvent(events.eventTypes.CLICK, 'View Proposition', {
        src: this.usedInBoostBlock ? 'sponsored-propositions' : `swaplist`,
        propositionId: this.swap.propositionId
      });
    },

    handlePropositionCardRightClick() {
      addRightClickTracker(
        this.swap.propositionId,
        this.usedInBoostBlock ? 'sponsored-propositions' : `swaplist`
      );
    },

    handlePropositionCardClick() {
      if (this.isBlurred) {
        this.setGlobalModal('SwapsLocked');
        events.emitEvent(events.eventTypes.SEE, 'Paywall', {
          trigger: this.payWallTrigger,
          src: 'swap-list',
          cardPosition: this.index + 1
        });
      } else {
        this.triggerPropositionClickedEvent();
      }
    },

    openPropositionModalTab(tab) {
      if (tab === 'chatTab' || tab === 'swapchainTab') {
        // Get swap details
        this.fetchSwapDetails({
          propositionId: this.swap.propositionId,
          type: this.swap.details.type
        });

        // Set active page modal tab to details
        this.setPageModalActivePage('swapdetails');

        if (tab === 'chatTab') {
          // Set current swap view tab to chat
          this.setCurrentSwapViewTab({ type: SWAP_VIEW_TABS.CHAT });
        }

        if (tab === 'swapchainTab') {
          // Set current swap view tab to swap chain
          this.setCurrentSwapViewTab({ type: SWAP_VIEW_TABS.SWAP });
        }
      } else {
        this.setCurrentSwapViewTab({ type: SWAP_VIEW_TABS.SWAP });
        this.triggerPropositionClickedEvent();
      }

      this.$router.push({
        query: {
          propositionPageId: this.swap.propositionId,
          type: this.swap.details.type
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.proposition-card {
  display: block;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  color: #2c3e50;

  @media (min-width: 769px) {
    .proposition-card.disable-link {
      margin: 30px 70px;
    }
  }
}

.blurred-cta-container {
  display: flex;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  .blurred-button {
    display: flex;
    margin: auto;
    border-radius: 8px;
    min-width: max-content;
    transition: all 0.2s ease-in-out;
    &:active {
      opacity: initial;
    }

    @media ($mobile) {
      top: -4%;
    }

    @media ($small-md-screen) {
      top: -2%;
    }

    @media ($smaller-screen) {
      top: -12%;
    }
  }
}

.proposition-card-wrapper {
  margin-bottom: 1rem;
  filter: none;
}

.info-from-boost {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background-color: #9f41e9;
  color: #fff;

  .icon {
    margin-right: 3px;
  }
}

.info-full-interest {
  background-color: #2ec973;
  color: #fff;
}

.info-in-swap-process {
  background-color: #ffc212;
}

.residence-header {
  position: relative;
}

.residence-header.used-in-tutorial {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  overflow: hidden;
}

.address-and-area {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 75%;
  background-color: #2c3e50;
  padding: 10px;
  padding-right: 40px;
  border-radius: 0px 0px 50px 0px;
  color: #fff;
}

.verified-badge {
  margin-left: 5px;
}

.boost-icon {
  position: absolute;
  top: 6px;
  right: 6px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;

  border-radius: 32px;
  background-color: #9f41e9;
  z-index: 1;

  &.always-show-boost-icon {
    right: 6px;
  }
}

.primary-image-container {
  overflow: hidden;
}

.primary-image {
  width: 100%;
  object-fit: cover;
  grid-column: 1;
  grid-row: 1;

  @media ($mobile) {
    object-fit: cover;
    aspect-ratio: 2 / 0.9;
  }

  &.is-blurred {
    filter: blur(10px);
  }
}

.blur-content {
  .residence-header,
  .secondary-images-container,
  .address-and-info-wrapper {
    filter: blur(6px);
    pointer-events: none;
    user-select: none;
  }
  border-radius: 10px;
}

.large-image {
  aspect-ratio: 2 / 1.2;
}

.secondary-images-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 1px;

  img {
    width: 100%;
    aspect-ratio: 2 / 0.9;
    object-fit: cover;
  }

  img:first-of-type {
    padding-right: 1px;
  }
}

.current-shown-proposition {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px 5px 15px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
}

.address-and-info-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 15px;

  .address-and-badge-container {
    display: flex;
    justify-content: space-between;
  }

  .address-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .street-address {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    margin-bottom: 4px;
  }

  .postal-area {
    font-size: 12px;
    line-height: 15px;
    color: #878b9d;

    &.diff-value {
      color: #db8e00;
    }
  }

  .residence-info-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;

    p {
      margin: 6px 0 0 0;
    }
    font-size: 12px;
    line-height: 15px;
  }

  .swap-type-badge-container {
    display: flex;
    justify-content: flex-end;
    height: fit-content;
    .swap-type-badge {
      display: flex;
      align-items: center;
      border-radius: 12px;
      font-size: 11px;
      line-height: 130%;
      white-space: nowrap;
      color: #878b9d;
    }
  }
}

.switch-proposition-buttons {
  display: flex;

  button {
    display: grid;
    place-items: center;
    width: 28px;
    height: 28px;
    margin-left: 10px;
    background-color: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;

    &.active {
      background-color: #2c3e50;
      border-color: #2c3e50;
      color: #fff;
    }
  }
}

.interest-timer-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  border-top: 1px solid #e8e8e8;
}

.interest-timer {
  display: flex;
  align-items: center;
}

.interest-buttons {
  :deep(.interest-button) {
    font-size: 14px;

    .button-icon {
      width: 15px;
      height: 15px;
    }
  }
}

.stopwatch-icon {
  margin-right: 10px;
}

.diff-value {
  color: #db8e00;
}

.disable-link {
  user-select: none;
}

.messenger-label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  font-weight: 600;
  padding: 8px;

  .unread-dot {
    height: 8px;
    width: 8px;
    margin: 0 4px;
    background-color: $transfer-blue;
    border-radius: 50%;
  }
}

.top-label-container {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  top: 8px;
  left: 8px;
  width: 100%;
  .new-swap-badge {
    background: none;
    display: grid;
    place-items: center;
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    cursor: pointer;
    background: #409fff;
    color: #fff;
    padding: 5px 8px;
    font-size: 12px;
    line-height: 10px;
    border-radius: 4px;
    font-weight: 600;
  }
  .amenities-labels {
    display: flex;
    gap: 8px;
  }
}

.bottom-label-container {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  left: 8px;
  width: 100%;
  bottom: 10px;
  .swap-type-label {
    padding: 5px 8px;
    background-color: #2c3e50b7;
    color: #fff;
    border-radius: 4px;
    font-size: 11px;
    font-weight: 600;
    line-height: 10px;
    align-self: flex-end;
  }
  .info-labels {
    display: inline;
    width: fit-content;
    gap: 8px;
  }
}

.img-placeholder {
  width: 100%;
  object-fit: cover;
  background-color: #f7f7f9;
  display: block;
  aspect-ratio: 2 / 1.2;
}

.placeholder-container {
  position: relative;
  background-color: #f7f7f9;
  display: flex;
  flex-direction: column;
  align-items: center;

  .img-placeholder.no-image {
    background-position: 50%;
    background-color: #f7f7f9;
    background-size: 50%;
    background-image: url('../assets/svg/placeholderImage/placeholder-image-large.svg');
    aspect-ratio: 2 / 1.2;
    object-fit: cover;

    @media ($mobile) {
      aspect-ratio: 2 / 0.9;
    }

    &.large-image {
      @media ($mobile) {
        aspect-ratio: 2 / 1.2;
      }
    }
  }

  p {
    font-size: 12px;
    text-align: center;
    margin: 0;
    position: absolute;
    top: 70%;
    z-index: 1;

    @media (min-width: 520px) and (max-width: 690px) {
      font-size: 14px;
      top: 75%;
    }
    @media (min-width: 691px) and (max-width: 768px) {
      font-size: 16px;
      top: 78%;
    }

    @media ($desktop) {
      top: 66%;
    }

    &.large-image {
      top: 66%;
    }
  }
}

.blur-card {
  filter: blur(4px);
  pointer-events: none;
  user-select: none;
}
</style>
