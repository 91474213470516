<template>
  <div :class="['interest-buttons', { 'is-blurred': isBlurred }]">
    <div
      :class="[
        'interest-button interest-button--no',
        { 'is-not-interested': interested === false },
        { 'reverse-is-shown': reverseIsShown }
      ]"
      @click.prevent.stop="handleNoClick"
    >
      <div class="button-content">
        <BaseIcon
          :icon-file="interested === false ? 'close-new-white' : 'close-new'"
          class="button-icon"
        />
        {{ $t('interest_button_hide') }}
      </div>
    </div>
    <div
      v-if="swap.details.percentage !== 100 || matchesViewABTest"
      :class="[
        'interest-button interest-button--yes',
        {
          'is-interested': interested === true,
          'background-fill-rl':
            !noInterestMarksLeft &&
            interested === true &&
            shouldAnimateYesButton
        }
      ]"
      @click.prevent.stop="handleYesClick"
    >
      <div class="button-content">
        <YesClickAnimation
          v-if="!noInterestMarksLeft && interested && shouldAnimateYesButton"
        ></YesClickAnimation>
        <BaseIcon
          :icon-file="interested ? 'check-mark-new-white' : 'check-mark-new'"
          class="button-icon"
        />
        {{ $t('interest_button_interested') }}
      </div>
    </div>
    <div
      v-else
      class="interest-button start-chat"
      @click.prevent.stop="$emit('open-chat')"
    >
      <div class="button-content">
        <BaseIcon icon-file="icon-chat-bubbles" class="button-icon" />
        <template v-if="!swap.chat">
          {{ $t('swap_item_start_chat') }}
        </template>
        <template v-else-if="swap.chat && !swap.chat.unreadCount">
          {{ $t('swap_item_open_chat') }}
        </template>
        <template v-else>
          {{ $t('swap_item_chat_x_unread', { unread: swap.chat.unreadCount }) }}
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import YesClickAnimation from '@/components/SwapList/Gratification/YesClickAnimation';
import {
  TUTORIAL_STATUS_TYPES,
  TUTORIAL_STEPS_TYPES
} from '../../store/modules/tutorial';
import { SWAP_FILTER_TYPES } from '../../store/modules/swapList';
import events from '@/utils/helpers/events';

export default {
  name: 'InterestButtons',

  components: {
    YesClickAnimation
  },

  props: {
    interested: {
      type: [Boolean, null],
      default: null
    },
    targetPropositionId: {
      type: String,
      required: true
    },
    swap: {
      type: Object,
      required: true
    },
    usedInTutorial: {
      type: Boolean,
      default: false
    },
    context: {
      type: String,
      default: null
    },
    reverseInterestMarkClicked: {
      type: Boolean,
      default: false
    },
    reverseIsShown: {
      type: Boolean,
      default: false
    },
    isBlurred: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isNotInterested: false,
      tutHasMarkedInterest: false,
      shouldAnimateYesButton: false,
      TUTORIAL_STATUS_TYPES,
      TUTORIAL_STEPS_TYPES,
      SWAP_FILTER_TYPES
    };
  },

  computed: {
    ...mapGetters({
      tutStatus: 'tutorial/status',
      tutActiveStep: 'tutorial/activeStep',
      tutInterestMarks: 'tutorial/tutInterestMarks',
      noInterestMarksLeft: 'interests/noInterestMarksLeft',
      swapFilterType: 'swapList/swapFilterType',
      primaryPropositionIsLimited: 'myPropositions/primaryPropositionIsLimited'
    }),

    isTutorialLike() {
      return (
        this.tutStatus === TUTORIAL_STATUS_TYPES.STARTED &&
        this.tutInterestMarks < 3
      );
    },

    isTutorialFirstLike() {
      return (
        this.tutStatus === TUTORIAL_STATUS_TYPES.STARTED &&
        this.tutActiveStep === TUTORIAL_STEPS_TYPES.HIGHLIGHT_SWAP &&
        this.tutInterestMarks === 1
      );
    },

    matchesViewABTest() {
      return this.$growthbook.isFeatureFlagEnabled('matches-view');
    }
  },

  methods: {
    ...mapActions({
      markInterest: 'interests/markInterest',
      setGlobalModal: 'ui/setGlobalModal',
      incrementTutInterestMarks: 'tutorial/incrementTutInterestMarks'
    }),

    handleNoClick() {
      if (this.isTutorialLike && !this.tutHasMarkedInterest) {
        this.incrementTutInterestMarks(1);
        this.tutHasMarkedInterest = true;
      }
      if (this.usedInTutorial) {
        this.$emit('interested', false);
      } else {
        this.$emit('no-interest', this.$event);

        if (
          this.swapFilterType === SWAP_FILTER_TYPES.REMOVED &&
          this.interested
        ) {
          this.markInterest({
            targetPropositionId: this.targetPropositionId,
            interested: false,
            where: this.isTutorialFirstLike ? 'swap-list-tutorial' : 'swap-list'
          });
        }

        if (!this.reverseInterestMarkClicked) {
          this.markInterest({
            targetPropositionId: this.targetPropositionId,
            skipRequest: true,
            interested: false,
            where: this.isTutorialFirstLike ? 'swap-list-tutorial' : 'swap-list'
          });
        } else {
          this.handleYesClick();
        }
      }
    },

    handleYesClick() {
      if (
        this.primaryPropositionIsLimited &&
        this.swapFilterType === SWAP_FILTER_TYPES.REMOVED
      ) {
        this.setGlobalModal('ReverseInterestActionPaywall');
        events.emitEvent(events.eventTypes.SEE, 'Paywall', {
          trigger: 'reverse-no-interest-action',
          src: 'interest-mark'
        });
        return;
      }

      this.shouldAnimateYesButton = true;

      if (this.isTutorialLike && !this.tutHasMarkedInterest) {
        this.incrementTutInterestMarks(1);
        this.tutHasMarkedInterest = true;
      }

      if (this.usedInTutorial) {
        this.$emit('interested', true);
      } else if (!this.interested) {
        this.markInterest({
          targetPropositionId: this.targetPropositionId,
          interested: true,
          where: this.isTutorialFirstLike ? 'swap-list-tutorial' : 'swap-list'
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@keyframes background-fill {
  0% {
    opacity: 0;
    background-position: -100% 0;
  }
  10% {
    opacity: 1;
    background-position: -100% 0;
  }
  33%,
  100% {
    background-position: 0 0;
  }
}

.is-blurred {
  filter: blur(6px);
  pointer-events: none;
  user-select: none;
}

.interest-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-top: 1px solid #e8e8e8;
}

.interest-button {
  display: grid;
  place-items: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  user-select: none;
  white-space: nowrap;
  cursor: pointer;
  padding: 10px 16px;

  &.reverse-is-shown {
    pointer-events: none;
  }
}

.background-fill-rl {
  background-size: 200% 100%;
  background-image: linear-gradient(to left, white 50%, $transfer-green 50%);
  color: white;
  animation: background-fill 1.9s ease-in-out forwards;
}

.interest-button:first-of-type {
  border-right: 1px solid #e8e8e8;
}

.button-content {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-inline: auto;
  width: fit-content;
}

.button-icon {
  margin-right: 4px;
  width: 14px;
  height: 14px;
}

.is-not-interested {
  background-color: $sweet-red;
  color: #fff;
}

.is-interested {
  background-image: linear-gradient(
    to left,
    $transfer-green 50%,
    $transfer-green 50%
  );
  color: white;
}

.start-chat {
  background-color: #409fff;
  color: #fff;
}
</style>
