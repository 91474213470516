<template>
  <a :href="$routes.HOME" class="logo-wrapper">
    <img v-if="logo && logo.logo.url" :src="logo.logo.url" class="logo" />
  </a>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Logo',

  data() {
    return {
      logo: null
    };
  },

  async created() {
    try {
      const response = await axios.get(
        `https://cms.lagenhetsbyte.se/logo?_locale=${this.$routeLocale}`
      );
      this.logo = response.data;
    } catch (error) {
      console.log(error);
    }
  }
};
</script>

<style lang="scss" scoped>
.logo-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
}

.logo {
  height: 64%;

  @media ($desktop) {
    height: 55%;
  }
}
</style>
