<template>
  <div class="how-it-works">
    <div class="how-it-works-column">
      <div
        :style="{
          'background-image': `url(${bgImage})`
        }"
        class="how-it-works-top"
      >
        <div class="top-content">
          <h1>{{ content.Title }}</h1>
          <p>{{ content.Subtitle }}</p>
        </div>
      </div>
      <div class="blocks">
        <div
          v-for="(block, index) in content.IconTitleText"
          :key="index"
          class="block-item"
        >
          <div class="block-icon">
            <img v-if="block.Icon" :src="block.Icon.url" alt="" />
          </div>
          <div class="block-title">{{ block.Title }}</div>
          <div class="block-text">{{ block.Text }}</div>
        </div>
      </div>
      <div class="cta-button-container">
        <BaseButton
          :link="content.Button.ButtonLink"
          :is-external="true"
          class="cta-button rounded larger"
        >
          {{ content.Button.ButtonText }}
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HowItWorks',

  props: {
    content: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    bgImage() {
      return (
        this.content.BackgroundImage.formats?.large?.url ||
        this.content.BackgroundImage.url
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.how-it-works {
  margin-top: 32px;
  margin-bottom: 32px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  @media ($large-screen) {
    margin-top: 64px;
    margin-bottom: 64px;
    padding: 0 16px;
  }

  img {
    width: 100%;
  }
}

.how-it-works-column {
  background-color: #f8f8fa;

  @media ($large-screen) {
    border-radius: 4px;
    overflow: hidden;
  }
}

.how-it-works-top {
  display: grid;
  place-content: center;
  background-size: cover;
  background-position: 50%;
  height: 500px;
  padding: 30px;
  color: #fff;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
}

.top-content {
  max-width: 700px;
  text-align: center;

  h1 {
    font-size: 42px;
    margin: 0;
  }

  p {
    font-size: 20px;
    margin: 0;
  }
}

.blocks {
  display: grid;
  justify-content: center;
  gap: 25px;
  padding: 0 25px;
  margin-top: -32px;

  @media ($large-screen) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.block-item {
  display: grid;
  justify-items: center;
  text-align: center;
  align-content: start;
  margin-bottom: 16px;
  max-width: 400px;

  @media ($large-screen) {
    margin-bottom: 0;
  }
}

.block-icon {
  display: grid;
  place-content: center;
  width: 65px;
  height: 65px;
  background-color: #fff;
  border-radius: 50%;
  margin-bottom: 16px;

  img {
    width: 30px;
  }
}

.block-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.block-text {
  font-size: 17px;
}

.cta-button-container {
  display: grid;
  place-content: center;
  padding: 48px 0;

  @media ($large-screen) {
    margin-top: 10px;
    padding: 60px 0;
  }
}
</style>
