import i18n from '@/i18n';

export function convertSystemChatMessage(messageBody) {
  try {
    const { type, displayName } = JSON.parse(messageBody);
    if (type === 'USER_LEFT') {
      return i18n.t('chat_notification_removed', { displayName });
    } else if (type === 'MARKED_YES') {
      return i18n.t('chat_notification_interest_yes', { displayName });
    } else if (type === 'MARKED_NO') {
      return i18n.t('chat_notification_interest_no', { displayName });
    } else if (type === 'INTEREST_LEVEL_0') {
      return i18n.t('chat_notification_interest_level_0', { displayName });
    } else if (type === 'INTEREST_LEVEL_1') {
      return i18n.t('chat_notification_interest_level_1', { displayName });
    } else if (type === 'INTEREST_LEVEL_2') {
      return i18n.t('chat_notification_interest_level_2', { displayName });
    } else if (type === 'INTEREST_LEVEL_3') {
      return i18n.t('chat_notification_interest_level_3', { displayName });
    } else if (type === 'PROPOSITION_INACTIVATED') {
      return i18n.t('chat_notification_inactivated', { displayName });
    } else if (type === 'PROPOSITION_ACTIVATED') {
      return i18n.t('chat_notification_activated', { displayName });
    } else if (type === 'BLOCKED') {
      return i18n.t('chat_notification_blocked', { displayName });
    } else if (type === 'UNBLOCKED') {
      return i18n.t('chat_notification_unblocked', { displayName });
    }
    return messageBody;
  } catch (e) {
    return messageBody;
  }
}

export function convertSystemChatMessageWithoutAddress(messageBody) {
  try {
    const { type } = JSON.parse(messageBody);
    if (type === 'USER_LEFT') {
      return i18n.t('chat_notification_removed', { displayName: '' });
    } else if (type === 'MARKED_YES') {
      return i18n.t('chat_notification_interest_yes', { displayName: '' });
    } else if (type === 'MARKED_NO') {
      return i18n.t('chat_notification_interest_no', { displayName: '' });
    } else if (type === 'INTEREST_LEVEL_0') {
      return i18n.t('chat_notification_interest_level_0', { displayName: '' });
    } else if (type === 'INTEREST_LEVEL_1') {
      return i18n.t('chat_notification_interest_level_1', { displayName: '' });
    } else if (type === 'INTEREST_LEVEL_2') {
      return i18n.t('chat_notification_interest_level_2', { displayName: '' });
    } else if (type === 'INTEREST_LEVEL_3') {
      return i18n.t('chat_notification_interest_level_3', { displayName: '' });
    } else if (type === 'PROPOSITION_INACTIVATED') {
      return i18n.t('chat_notification_inactivated', { displayName: '' });
    } else if (type === 'PROPOSITION_ACTIVATED') {
      return i18n.t('chat_notification_activated', { displayName: '' });
    } else if (type === 'BLOCKED') {
      return i18n.t('chat_notification_blocked', { displayName: '' });
    } else if (type === 'UNBLOCKED') {
      return i18n.t('chat_notification_unblocked', { displayName: '' });
    }
    return messageBody;
  } catch (e) {
    return messageBody;
  }
}
