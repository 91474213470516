<template>
  <div class="user-card-container">
    <div v-if="hasSwappedEarlier" class="success-swap-banner">
      <div>
        {{ $t('user_card_swaped') }}
        <br />
        <span class="large">{{ user && user.successfulSwapCount }}x</span>
      </div>
    </div>
    <div v-if="isAuthenticated" class="info-area">
      <div
        class="user-img"
        :style="{
          backgroundImage: `url(${$t(
            'url_cdn',
            $routeLocale
          )}/profil-bilder/${roundedUserId}/${user.userId}.jpg)`,
          opacity: 1,
          backgroundSize: 'cover'
        }"
      />

      <div class="activity-indicator-area">
        <div v-if="user && user.name" class="user-name">{{ user.name }}</div>
        <div v-if="user.verified" class="verified-user">
          <BaseIcon
            v-if="user.verified"
            icon="verified"
            :width="12"
            :height="12"
            class="verified-badge"
          />
          Verifierad användare
        </div>
        <div class="registered-at">
          {{ $t('account_created_at') + ' ' + accountRegisteredAt }}
        </div>
      </div>
    </div>
    <user-activity-details
      v-if="isAuthenticated"
      class="activity-details"
      :proposition-id="propositionId.toString()"
    ></user-activity-details>
    <div class="contact-area">
      <BaseButton
        :class="[
          'full-width',
          {
            grey: showContactTriangleButton,
            outline: showContactTriangleButton
          }
        ]"
        :style="
          !isAuthenticated && hasSwappedEarlier ? 'margin-top: 40px;' : ''
        "
        @click="openContactModal"
      >
        {{
          showContactTriangleButton
            ? $t('user_card_contact')
            : $t('user_card_contact_swap')
        }}
      </BaseButton>
      <BaseButton
        v-if="showContactTriangleButton"
        class="full-width"
        style="margin-top: 12px"
        @click="handleContactTriangleSwap"
      >
        {{ $t('user_card_contact_swap') }}
      </BaseButton>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { SWAP_VIEW_TABS } from '@/store/modules/swapList';
import UserActivityDetails from './UserActivityDetails.vue';

export default {
  components: { UserActivityDetails },
  props: {
    propositionId: {
      type: String,
      required: true
    },
    user: {
      type: Object,
      default: () => {}
    },
    openContactModal: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      SWAP_VIEW_TABS: SWAP_VIEW_TABS
    };
  },

  computed: {
    ...mapGetters({
      isAuthenticated: 'app/isAuthenticated',
      propositionViewModalId: 'ui/propositionViewModalId',
      propositionViewModalType: 'ui/propositionViewModalType'
    }),

    showContactTriangleButton() {
      return this.propositionViewModalType === 'triangleswap';
    },

    roundedUserId() {
      return this.user ? Math.floor(this.user.userId / 1000) * 1000 : 0;
    },

    hasSwappedEarlier() {
      return this.user && this.user.successfulSwapCount > 0;
    },

    accountRegisteredAt() {
      if (!this.user.registeredAt) {
        return '-';
      }
      return this.$dayjs(this.user.registeredAt).format('YYYY-MM-DD');
    }
  },

  methods: {
    ...mapActions({
      fetchSwapDetails: 'swapList/fetchSwapDetails',
      setCurrentSwapViewTab: 'swapList/setCurrentSwapViewTab',
      setPageModalActivePage: 'ui/setPageModalActivePage'
    }),

    handleContactTriangleSwap() {
      // Get swap details
      this.fetchSwapDetails({
        propositionId: this.propositionViewModalId,
        type: this.propositionViewModalType
      });

      // Set active page modal tab to details
      this.setPageModalActivePage('swapdetails');

      // Set current swap view tab to chat
      this.setCurrentSwapViewTab({ type: SWAP_VIEW_TABS.CHAT });
    }
  }
};
</script>

<style lang="scss" scoped>
.user-card-container {
  position: relative;
  width: 90%;
  background-color: #f1f2f6;
  border-radius: $small-box-radius;
  border: 1px solid rgba(28, 45, 65, 0.103);
  padding: 25px;

  @media ($desktop) {
    width: 100%;
    background-color: #f8f8fa;
    text-align: left;
  }

  @media ($mobile) {
    padding: 15px 20px;
    margin: 14px auto;
    margin-top: 0;
  }
}

.activity-details {
  margin-left: -25px;
  margin-right: -25px;

  @media ($mobile) {
    margin-left: -20px;
    margin-right: -20px;
  }
}

.success-swap-banner {
  position: absolute;
  top: 0;
  right: 5%;
  background-color: #ffc212;
  color: white;
  font-weight: 700;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  text-align: center;
  padding: 10px 16px;
  font-size: 0.8rem;

  & .large {
    font-size: 1.3rem;
  }

  // adjustment smaller screens
  @media ($mobile-small) {
    padding: 6px 12px;
    font-size: 0.7rem;
    right: 3%;

    & .large {
      font-size: 1.2rem;
    }
  }
}

.info-area {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.user-img {
  height: 60px;
  width: 60px;
  min-width: 60px;
  border-radius: 50%;
  background-color: $text-secondary;
  margin-right: 10px;
  background-size: cover;
  background-position: center;
}

.user-name {
  font-size: 0.95rem;
  font-weight: 700;
  margin-bottom: 2px;
}

.verified-user {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  font-size: 12px;
  font-weight: 600;
  color: rgba(28, 45, 65, 0.6);
}

.verified-badge {
  margin-right: 3px;
}

.activity-indicator-area {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.registered-at {
  margin: 0;
  font-size: 0.7rem;
  color: #878b9d;
}

.phone-number {
  font-size: 0.9rem;
  font-weight: 600;
  color: $transfer-blue;
  width: 70%;
  margin-bottom: 5px;

  & a {
    color: $transfer-blue;
    text-decoration: none;
  }
}

.phone-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.phone-icon {
  height: 10px;
  width: 10px;
  margin-right: 4px;
  background-size: contain;
  background-position: center;
  background-image: url('../../../assets/svg/phone.svg');
}

.contact-area {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-rating {
  margin-top: 8px;
  font-size: 0.8rem;
  font-weight: 600;
  color: rgba(28, 45, 65, 0.6);
}
</style>
