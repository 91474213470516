<template>
  <div class="hero-wrapper">
    <div class="edit-details-and-description">
      <div class="top-container">
        <BaseBackButton @click="goBack" />
        <h1 v-if="proposition" class="description-title">
          {{ $t('edit_prop_residence') }}
        </h1>
        <p class="info-text">
          {{ $t('edit_prop_residence_desc_and_facts_subtitle') }}
        </p>
      </div>
      <div v-if="currentResidence" class="edit-details-container">
        <div class="details">
          <h1 class="address">
            {{ currentResidence.streetAddress }}
          </h1>
          <p class="postal-address">{{ currentResidence.postalAddress }}</p>
          <template v-if="residenceDescription">
            <div class="form-group">
              <div class="input-container">
                <BaseInput
                  id="rent"
                  v-model.number="residenceDescription.rent"
                  :valid="!showErrors || isNumeric(residenceDescription.rent)"
                  name="rent"
                  type="number"
                  :label="$t('edit_prop_details_rent')"
                  :placeholder="$t('edit_prop_details_rent')"
                  :suffix="$t('general_currency_per_month_short')"
                  :is-label-dark="true"
                  class="form-group-item"
                />
                <BaseInput
                  id="sqm"
                  v-model.number="residenceDescription.sqm"
                  :valid="!showErrors || isNumeric(residenceDescription.sqm)"
                  name="sqm"
                  type="number"
                  :label="$t('edit_prop_details_sqm')"
                  :placeholder="$t('edit_prop_details_sqm')"
                  :suffix="$t('general_sqm_short')"
                  :is-label-dark="true"
                  class="form-group-item"
                />
              </div>

              <BasePicker
                id="rooms"
                :ref="!residenceDescription.rooms ? 'hasErrorRooms' : ''"
                key="rooms-picker"
                :title="$t('edit_prop_details_rooms')"
                name="rooms"
                :options="$country.getValue('PROPOSITION_ROOMS')"
                :primary-options-threshold="5"
                :value="residenceDescription.rooms"
                :valid="!showErrors || !!residenceDescription.rooms"
                :is-small-title="true"
                @selected="handleSetRooms"
              />

              <BasePicker
                id="floor"
                :ref="
                  !residenceDescription.floor ? 'hasErrorFloor' : 'floorInput'
                "
                key="floor-picker"
                :title="$t('edit_prop_details_floor')"
                name="floor"
                :options="$country.getValue('PROPOSITION_FLOOR')"
                :primary-options-threshold="6"
                :value="residenceDescription.floor"
                :custom-first-option="
                  $t('edit_prop_details_floor_picker_bottom_floor')
                "
                :valid="!showErrors || !!residenceDescription.floor"
                :is-small-title="true"
                @selected="handleSetFloor"
              />
            </div>

            <BaseInput
              id="landlordName"
              v-model="residenceDescription.landlordName"
              name="landlordName"
              type="text"
              :valid="!(landlordNameHasContactInfo && hasTriedToSave)"
              :label="$t('edit_prop_details_landlord')"
              :placeholder="$t('edit_prop_details_landlord')"
              :error-message="$t('edit_description_error_contact_info')"
              :is-label-dark="true"
              @onChange="handleInput"
            />

            <div
              v-if="$growthbook.isFeatureFlagEnabled(`residence-type`)"
              style="margin: 40px auto"
            >
              <BaseCheckbox
                :label="$t('proposition_residence_type_2')"
                :value="residenceDescription.residenceType === 2"
                :selected="residenceDescription.residenceType === 2"
                :is-checkbox-dark="true"
                class="residence-type-checkbox"
                @change="
                  residenceDescription.residenceType === 1
                    ? (residenceDescription.residenceType = 2)
                    : (residenceDescription.residenceType = 1)
                "
              />
              <InfoTooltip :info-html="$t('residence-type-info')"></InfoTooltip>
            </div>

            <div class="amenities-container">
              <h4>{{ $t('edit_prop_details_facilities') }}</h4>
              <div class="amenities">
                <BaseCheckbox
                  :label="$t('edit_prop_details_bathtub')"
                  :value="residenceDescription.bathtub"
                  :selected="residenceDescription.bathtub"
                  :is-checkbox-dark="true"
                  class="checkbox"
                  @change="
                    residenceDescription.bathtub = !residenceDescription.bathtub
                  "
                />

                <BaseCheckbox
                  :label="$t('edit_prop_details_balcony')"
                  :value="residenceDescription.balcony"
                  :selected="residenceDescription.balcony"
                  :is-checkbox-dark="true"
                  class="checkbox"
                  @change="
                    residenceDescription.balcony = !residenceDescription.balcony
                  "
                />

                <template v-if="$country.isCountry('se')">
                  <BaseCheckbox
                    label="Bildad bostadsrättsförening"
                    :value="residenceDescription.formedCooperative"
                    :selected="residenceDescription.formedCooperative"
                    :is-checkbox-dark="true"
                    class="checkbox"
                    @change="
                      residenceDescription.formedCooperative =
                        !residenceDescription.formedCooperative
                    "
                  />

                  <BaseCheckbox
                    label="Blivande bostadsrätt"
                    :value="residenceDescription.becomingCooperative"
                    :selected="residenceDescription.becomingCooperative"
                    :is-checkbox-dark="true"
                    class="checkbox"
                    @change="
                      residenceDescription.becomingCooperative =
                        !residenceDescription.becomingCooperative
                    "
                  />
                </template>

                <BaseCheckbox
                  :label="$t('edit_prop_details_elevator')"
                  :value="residenceDescription.elevator"
                  :selected="residenceDescription.elevator"
                  :is-checkbox-dark="true"
                  class="checkbox"
                  @change="
                    residenceDescription.elevator =
                      !residenceDescription.elevator
                  "
                />

                <BaseCheckbox
                  :label="$t('edit_prop_details_fireplace')"
                  :value="residenceDescription.fireplace"
                  :selected="residenceDescription.fireplace"
                  :is-checkbox-dark="true"
                  class="checkbox"
                  @change="
                    residenceDescription.fireplace =
                      !residenceDescription.fireplace
                  "
                />
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="edit-container">
        <template v-if="residenceDescription">
          <div class="edit-description-container">
            <div class="edit-description">
              <h1 class="description-title">
                {{ $t('edit_description_title') }}
              </h1>
              <p class="info-text">
                {{ $t('edit_description_desc1') }}
              </p>
              <p class="sub-text">
                {{ $t('edit_description_desc2') }}
              </p>
              <label class="textarea-label" for="desc">
                {{ $t('edit_description_label') }}
              </label>
              <BaseTextArea
                v-model="residenceDescription.description"
                name="description"
                cols="30"
                :placeholder="$t('edit_description_placeholder')"
                :rows="isMobile ? 10 : 15"
                :valid="!(descHasContactInfo && hasTriedToSave)"
                :error-message="$t('edit_description_error_contact_info')"
                :highlighted-words="[]"
                @onChange="handleInput"
              />
            </div>
          </div>
        </template>
      </div>
    </div>

    <FloatingSaveButton
      class="save-button"
      :is-visible="dataChanged"
      :icon-file="'check-mark-white'"
      @saveClick="handleSaveClick"
    >
      <template>
        <template v-if="isSaving">
          {{ $t('edit_user_credentials_loading') }}</template
        >
        <template v-else>
          {{ $t('edit_user_credentials_save') }}
        </template>
      </template>
    </FloatingSaveButton>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FloatingSaveButton from '../../components/Buttons/FloatingSaveButton.vue';
import { lbApiBeta } from '../../utils/axiosConfig';
import formValidations from '../../utils/formValidations';
import InfoTooltip from '../../components/InfoTooltip.vue';

export default {
  name: 'EditDetailsAndDescription',

  components: {
    FloatingSaveButton,
    InfoTooltip
  },

  data() {
    return {
      propositionId: null,
      residenceIndex: null,
      proposition: null,
      residenceDescription: null,
      saveButtonVisible: false,
      isSaving: false,
      showErrors: false,
      invalidFields: [],
      landlordNameHasContactInfo: false,
      descHasContactInfo: false,
      hasTriedToSave: false,
      inputHasErrors: false,
      originalData: null
    };
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile',
      userPropositions: 'myPropositions/propositions'
    }),

    editPropositionPath() {
      return this.$routes.EDIT_PROPOSITION + '/' + this.propositionId;
    },

    currentResidence() {
      if (!this.proposition) return;

      return this.proposition.residences[this.residenceIndex - 1];
    },

    isValid() {
      return (
        this.isNumeric(this.residenceDescription.rent) &&
        this.isNumeric(this.residenceDescription.sqm)
      );
    },

    dataChanged() {
      return (
        JSON.stringify(this.originalData) !==
        JSON.stringify(this.residenceDescription)
      );
    }
  },

  watch: {
    'residenceDescription.landlordName'(newValue) {
      if (this.residenceDescription.landlordName) {
        this.landlordNameHasContactInfo = formValidations.residenceDescription(
          newValue.trim()
        );
      }
    },
    'residenceDescription.description'(newValue) {
      if (this.residenceDescription.description) {
        this.descHasContactInfo = formValidations.residenceDescription(
          newValue.trim()
        );
      }
    }
  },

  async created() {
    this.propositionId = this.$route.params.propositionId;
    this.residenceIndex = this.$route.params.residenceIndex;

    window.scrollTo(0, 0);

    await this.getProposition();

    await this.getResidenceDescription();
    this.saveOriginalData();
  },

  methods: {
    ...mapActions({
      setToast: 'toast/setToast',
      fetchPropositions: 'myPropositions/fetchPropositions',
      refreshPropositions: 'myPropositions/refreshPropositions'
    }),

    goBack() {
      this.$router.go(-1);
    },

    async getProposition() {
      await this.fetchPropositions();

      this.proposition = this.userPropositions.find(
        id => id.propositionId == this.propositionId
      );
    },

    handleInput() {
      if (!this.hasTriedToSave) return;

      const landlordNameHasErrors = this.landlordNameHasContactInfo;
      const descHasErrors = this.descHasContactInfo;

      this.inputHasErrors = landlordNameHasErrors || descHasErrors;

      if (!landlordNameHasErrors && !descHasErrors) {
        this.hasTriedToSave = false;
      }
    },

    isNumeric(input) {
      return input - 0 == input && ('' + input).trim().length > 0;
    },

    validateForm() {
      this.invalidFields = [];

      if (!this.isNumeric(this.residenceDescription.rent)) {
        this.invalidFields.push('rent');
      }
      if (!this.isNumeric(this.residenceDescription.sqm)) {
        this.invalidFields.push('sqm');
      }
      if (this.landlordNameHasContactInfo) {
        this.invalidFields.push('landlordName');
      }
      if (this.descHasContactInfo) {
        this.invalidFields.push('description');
      }

      return this.invalidFields.length > 0;
    },

    saveOriginalData() {
      this.originalData = JSON.parse(JSON.stringify(this.residenceDescription));
    },

    handleSetRooms(selectedRooms) {
      if (!this.residenceDescription) return;

      this.residenceDescription.rooms = selectedRooms;
    },

    handleSetFloor(selectedFloor) {
      if (!this.residenceDescription) return;

      this.residenceDescription.floor = selectedFloor;
    },

    async getResidenceDescription() {
      try {
        const response = await lbApiBeta.get(
          `/api/v2/proposition/${this.propositionId}/residence/${this.residenceIndex}`
        );

        this.residenceDescription = response.data;
        console.log(response.data);
      } catch (e) {
        console.log(e);
      }
    },

    async updateResidenceDescription() {
      await lbApiBeta.post(
        `/api/v2/proposition/${this.propositionId}/residence/${this.residenceIndex}`,
        this.residenceDescription
      );
    },

    handleSaveClick() {
      this.hasTriedToSave = true;

      if (this.landlordNameHasContactInfo || this.descHasContactInfo) {
        this.inputHasErrors = true;

        this.isSaving = false;

        if (this.validateForm()) {
          const firstErrorField = this.invalidFields[0];
          this.scrollToError(firstErrorField);
        }
      } else if (
        this.isValid &&
        !this.landlordNameHasContactInfo &&
        this.isValid &&
        !this.descHasContactInfo
      ) {
        this.inputHasErrors = false;

        try {
          this.isSaving = true;
          this.updateResidenceDescription();
          this.saveOriginalData();
          this.setToast({ title: this.$t('toast_changes_saved') });
          this.isSaving = false;
          this.showErrors = false;
          this.refreshPropositions();
          setTimeout(() => {
            this.$router.push(this.editPropositionPath);
          }, 1500);
        } catch {
          this.isSaving = false;
          this.setToast({
            title: this.$t('toast_general_error_message'),
            timer: 5
          });
        }
      } else {
        this.showErrors = true;

        if (this.validateForm()) {
          const firstErrorField = this.invalidFields[0];
          this.scrollToError(firstErrorField);
        }
      }
    },

    scrollToError(inputName) {
      const inputWithError = document.querySelector(`#${inputName}`);

      if (inputWithError) {
        inputWithError.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.hero-wrapper {
  background-color: #f8f8fa;
  color: #0b182c;
  min-width: 100vw;
  padding-bottom: 139px;

  @media ($desktop) {
    padding-bottom: 75px;
  }
}

.edit-details-and-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @media ($small-desktop) {
    width: 96%;
  }

  @media ($desktop) {
    max-width: 700px;
    margin: 0 auto;
  }
}

.top-container {
  @media ($mobile) {
    padding: 0 16px;
  }
}

.description-title {
  font-size: 22px;
  margin: 20px 0 16px 0;
}

.info-text {
  color: #485261;
  margin: 0;
}

.address {
  font-size: 22px;
  margin-top: 24px;
  margin-bottom: 0;
  line-height: 150%;
}

.postal-address {
  margin-top: 0;
  margin-bottom: 24px;
  color: #999999;
  font-size: 14px;
}

.edit-details-container {
  padding-bottom: 24px;
  border-bottom: 1.9px solid #dddcdb;
  width: 100%;

  .details {
    @media ($mobile) {
      padding: 0 16px;
    }
  }
}

.edit-container {
  @media ($mobile) {
    padding: 0 16px;
  }
}

.amenities {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media ($desktop) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.textarea-label {
  font-weight: 600;
}

.save-button {
  width: 100%;
  padding: 0;
}

.residence-type-checkbox {
  display: inline-block;
}
</style>
