<template>
  <div class="swap-container">
    <div v-if="swaps.length > 0" class="swap-table-list">
      <thead>
        <tr>
          <!-- <th class="fav-mark"> -->
          <!--Fav mark-->
          <!-- </th> -->
          <swap-list-title-sortable
            :flex-left="true"
            style="min-width: 100px"
            :title="$t('swap_list_container_accomodation')"
            :handle-select-sort-option="handleSelectSortOption"
          />
          <swap-list-title-sortable
            :flex-right="true"
            style="min-width: 45px"
            :title="$t('swap_list_container_rooms')"
            :handle-select-sort-option="handleSelectSortOption"
          />
          <swap-list-title-sortable
            :flex-right="true"
            style="min-width: 53px"
            :title="$t('swap_list_container_sqm')"
            :handle-select-sort-option="handleSelectSortOption"
          />
          <swap-list-title-sortable
            :flex-right="true"
            style="min-width: 55px"
            :title="$t('swap_list_container_floor')"
            :handle-select-sort-option="handleSelectSortOption"
          />
          <swap-list-title-sortable
            :flex-right="true"
            :title="$t('swap_list_container_rent')"
            :handle-select-sort-option="handleSelectSortOption"
          />
          <swap-list-title-sortable
            :title="$t('swap_list_container_area')"
            :handle-select-sort-option="handleSelectSortOption"
          />
          <swap-list-title-sortable
            :title="$t('swap_list_container_swap_type')"
            :handle-select-sort-option="handleSelectSortOption"
          />

          <!-- <swap-list-title-sortable
            :title="$t('swap_list_container_interest')"
            :handle-select-sort-option="handleSelectSortOption"
          /> -->

          <th>
            <!--Interest mark-->
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(swap, index) in swaps">
          <SwapListItem
            :key="swap.propositionId + swap.details.type"
            :index="index"
            :show-swap-details="handleShowSwapDetails"
            :swap="swap"
            :toned="index % 2 === 0"
            :blur-content="blurContent"
            :is-blurred-distribution="
              shouldBlurLastSwaps &&
              (index === swaps.length - 1 || index === swaps.length - 2)
            "
          />
        </template>
      </tbody>
    </div>

    <SponsoredPropositions
      v-if="
        $country.isFeatureEnabled('EXTRA_VISIBILITY') &&
        sponsoredPropositions.length &&
        !isSwapsPage
      "
      :propositions="sponsoredPropositions"
      :title="$t('proposition_extra_visibility_title')"
      class="sponsored-propositions"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { SORT_TYPES, SWAP_FILTER_TYPES } from '../../store/modules/swapList';
import SwapListItem from './SwapListItem';
import SwapListTitleSortable from './SwapListTitleSortable';
import SponsoredPropositions from '@/components/SponsoredPropositions/SponsoredPropositions';

export default {
  name: 'SwapListContainer',

  components: {
    SwapListItem,
    SwapListTitleSortable,
    SponsoredPropositions
  },

  props: {
    blurContent: {
      type: Boolean,
      default: false
    },
    isSwapsPage: {
      type: Boolean,
      default: false
    },
    shouldBlurLastSwaps: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      showSponsored: true,
      sponsoredPosition: 7,
      SORT_TYPES,
      SWAP_FILTER_TYPES,
      extraVisModalOpen: false
    };
  },

  computed: {
    ...mapGetters({
      swaps: 'swapList/swaps',
      isMobile: 'screenSize/isMobile',
      sortType: 'swapList/sortType',
      sponsoredPropositions: 'propositions/sponsoredPropositions',
      primaryPropositionId: 'myPropositions/primaryPropositionId'
    })
  },

  mounted() {
    if (
      !this.sponsoredPropositions ||
      this.sponsoredPropositions.length === 0
    ) {
      this.getSponsoredPropositions();
    }
  },

  methods: {
    ...mapActions({
      fetchSwapDetails: 'swapList/fetchSwapDetails',
      getSponsoredPropositions: 'propositions/getSponsoredPropositions',
      listSetSortDirectionAndSort: 'swapList/listSetSortDirectionAndSort'
    }),

    handleSelectSortOption(sortDirection, sortType) {
      this.listSetSortDirectionAndSort({ sortDirection, sortType });
    },

    handleShowSwapDetails({ propositionId, type }) {
      this.fetchSwapDetails({ propositionId, type });
    }
  }
};
</script>

<style lang="scss" scoped>
.swap-container {
  padding-top: 24px;
  margin-bottom: 24px;
  max-width: 100%;
}

.swap-table-list {
  display: table;
  width: 100%;
  min-width: 880px;
  text-align: center;
  overflow-x: auto;
}

.swap-table-list thead {
  font-size: 10px;
}

.swap-table-list thead th {
  min-width: 50px;
  font-size: 0.7rem;
  text-align: center;
  padding-bottom: 15px;
}

// .swap-table-list thead th.fav-mark {
//   min-width: 22px;
// }

.swap-table-list thead th.left-align {
  text-align: left;
  padding-left: 0 !important;
}

.be-seen-here {
  cursor: pointer;
}

.sponsored-propositions {
  @media ($mobile) {
    margin-left: -10px;
    border-radius: 0;
  }
}

.margin-top-0 {
  margin-top: 0px;
}

.additional-swaps-divider {
  position: relative;
  border-top: 2px solid $text-primary;
  margin-top: 70px;
  margin-bottom: 40px;
  white-space: nowrap;
}
.additional-swaps-divider-label {
  position: absolute;
  top: -9px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  background-color: #f1f2f6;
  padding: 0 14px;
  font-weight: 600;
  @media ($large-screen) {
    background-color: #fff;
  }
}
</style>
