<template>
  <div
    :class="[
      'upload-photos-area',
      disabled ? 'disabled' : '',
      filesIsDragged ? 'files-is-dragged' : '',
      isLoading ? 'files-is-loading' : ''
    ]"
  >
    <template v-if="isLoading">
      <div class="upload-photos-area-content">
        <div class="" style="width: 28px; height: 28px; margin-bottom: 20px">
          <ASpinner class="spinner-alt" />
        </div>
        <div>{{ $t('edit_photos_uploading') }}</div>
      </div>
    </template>
    <template v-else-if="disabled">
      <div class="upload-block">
        <div class="upload-photos-area-content">
          <BaseIcon
            :icon-file="'camera-black'"
            :width="32"
            :height="32"
            class="upload-photos-icon"
          />
          <div
            v-if="explainer || isMobile"
            class="upload-text"
            v-html="
              isMobile
                ? $t('edit_photos_upload_description_mobile')
                : $t('edit_photos_upload_description_desktop')
            "
          ></div>
        </div>
      </div>
    </template>
    <template v-else>
      <div v-if="isMobile && isAndroid" class="upload-block">
        <input
          ref="uploadInput"
          type="file"
          accept="image/*"
          capture="environment"
          class="upload-photos-input camera"
          @change="handleChange($event.target.files)"
          @dragover="filesIsDragged = true"
          @dragleave="filesIsDragged = false"
          @drop="filesIsDragged = false"
        />
        <div class="upload-photos-area-content">
          <BaseIcon
            :icon-file="'camera-black'"
            :width="32"
            :height="32"
            class="upload-photos-icon"
          />
          <div
            v-if="explainer || isMobile"
            class="upload-text"
            v-html="$t('edit_photos_camera_description_mobile')"
          ></div>
        </div>
      </div>
      <div class="upload-block">
        <input
          ref="uploadInput"
          type="file"
          accept="image/*"
          multiple
          class="upload-photos-input upload"
          @change="handleChange($event.target.files)"
          @dragover="filesIsDragged = true"
          @dragleave="filesIsDragged = false"
          @drop="filesIsDragged = false"
        />
        <div class="upload-photos-area-content">
          <BaseIcon
            :icon-file="'camera-black'"
            :width="32"
            :height="32"
            class="upload-photos-icon"
          />
          <div
            v-if="explainer || isMobile"
            class="upload-text"
            v-html="
              isMobile
                ? $t('edit_photos_upload_description_mobile')
                : $t('edit_photos_upload_description_desktop')
            "
          ></div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import ASpinner from '@/components/atomic/atoms/a-spinner';
import { getBetaEndpoint } from '@/utils/getEndpoint';

export default {
  name: 'ImageDropZone',

  components: {
    ASpinner
  },

  props: {
    propositionId: {
      type: String,
      default: null
    },

    residenceIndex: {
      type: String,
      default: null
    },

    explainer: {
      type: Boolean,
      default: true
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      localImages: [],
      isLoading: false,
      filesIsDragged: false
    };
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile'
    }),

    // iOS can handle "take picture" on its own, android needs some help
    isAndroid() {
      const userAgent = navigator.userAgent;
      if (/android/i.test(userAgent)) {
        return true;
      }

      return false;
    }
  },

  methods: {
    ...mapActions({
      setToast: 'toast/setToast'
    }),

    async handleChange(files) {
      try {
        this.isLoading = true;
        let localImages = [];
        for (const file of files) {
          const localImageUrl = await this.getLocalImageUrl(file);
          localImages = [{ localImageUrl, file }, ...localImages];
        }

        await this.uploadImages(localImages);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        this.isLoading = false;
      }
    },

    async uploadImages(images) {
      const URL = getBetaEndpoint() + '/api/object/NewUpload';

      const config = {
        header: {
          'Content-Type': 'multipart/form-data'
        },
        withCredentials: true,
        credentials: 'same-origin'
      };

      const body = new FormData();

      body.append('propositionId', this.propositionId);
      body.append('suffix', this.residenceIndex);
      body.append('categoryId', 8);

      for (const image of images) {
        body.append('image', image.file);
      }

      axios
        .post(URL, body, config)
        .then(response => {
          // eslint-disable-next-line no-console

          this.$emit('getImages');
          this.isLoading = false;
          this.userResponseMessage(response.data.Message);
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log(error);

          this.isLoading = false;
          this.userResponseMessage(0);
        });
    },

    userResponseMessage(imagesUploaded) {
      // 0 == error
      let toastMessage = '';

      if (imagesUploaded == 0) {
        toastMessage = this.$t('edit_photos_upload_failed');
      } else if (imagesUploaded == 1) {
        toastMessage = this.$t('edit_photos_upload_1_image');
      } else {
        toastMessage = `${imagesUploaded} ${this.$t(
          'edit_photos_upload_x_images'
        )}`;
      }

      this.setToast({ title: toastMessage });
    },

    getLocalImageUrl(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = function (progress) {
          resolve(progress.currentTarget.result);
        };

        reader.onerror = reject;

        reader.readAsDataURL(file);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.upload-photos-area {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 200px;
  border: 2px dashed #448be4;
  text-align: center;
  line-height: 1.3;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;

  @media ($mobile) {
    font-size: 15px;
    min-height: 200px;
  }

  @media ($desktop) {
    // width: 100%;
    margin-bottom: 30px;

    &:not(.files-is-loading) {
      &:hover,
      &:focus {
        background-color: #e9efff;
      }
    }
  }
}

.upload-photos-area.disabled {
  border: 2px dashed rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.05);
  &:hover,
  &:focus {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.upload-photos-area.disabled .upload-photos-area-content {
  opacity: 0.5;
}

.upload-block {
  @media ($mobile) {
    display: flex;
    height: 100%;
    width: 50%;

    &:nth-child(2) {
      border-left: 2px dashed #448be4;
    }
  }
}

.files-is-loading {
  cursor: progress;
}

.files-is-dragged {
  background-color: #c9d8ff;
}

.upload-photos-input {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 1;

  @media ($mobile) {
    width: 50%;
  }

  &.camera {
    left: 0;
    border-right: 1px solid #000;
  }

  &.upload {
    right: 0;
  }
}

.upload-photos-area-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.upload-photos-icon {
  margin-bottom: 1rem;
}
</style>
