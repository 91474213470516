var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['interest-buttons', { 'is-blurred': _vm.isBlurred }]},[_c('div',{class:[
      'interest-button interest-button--no',
      { 'is-not-interested': _vm.interested === false },
      { 'reverse-is-shown': _vm.reverseIsShown }
    ],on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.handleNoClick.apply(null, arguments)}}},[_c('div',{staticClass:"button-content"},[_c('BaseIcon',{staticClass:"button-icon",attrs:{"icon-file":_vm.interested === false ? 'close-new-white' : 'close-new'}}),_vm._v(" "+_vm._s(_vm.$t('interest_button_hide'))+" ")],1)]),(_vm.swap.details.percentage !== 100 || _vm.matchesViewABTest)?_c('div',{class:[
      'interest-button interest-button--yes',
      {
        'is-interested': _vm.interested === true,
        'background-fill-rl':
          !_vm.noInterestMarksLeft &&
          _vm.interested === true &&
          _vm.shouldAnimateYesButton
      }
    ],on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.handleYesClick.apply(null, arguments)}}},[_c('div',{staticClass:"button-content"},[(!_vm.noInterestMarksLeft && _vm.interested && _vm.shouldAnimateYesButton)?_c('YesClickAnimation'):_vm._e(),_c('BaseIcon',{staticClass:"button-icon",attrs:{"icon-file":_vm.interested ? 'check-mark-new-white' : 'check-mark-new'}}),_vm._v(" "+_vm._s(_vm.$t('interest_button_interested'))+" ")],1)]):_c('div',{staticClass:"interest-button start-chat",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$emit('open-chat')}}},[_c('div',{staticClass:"button-content"},[_c('BaseIcon',{staticClass:"button-icon",attrs:{"icon-file":"icon-chat-bubbles"}}),(!_vm.swap.chat)?[_vm._v(" "+_vm._s(_vm.$t('swap_item_start_chat'))+" ")]:(_vm.swap.chat && !_vm.swap.chat.unreadCount)?[_vm._v(" "+_vm._s(_vm.$t('swap_item_open_chat'))+" ")]:[_vm._v(" "+_vm._s(_vm.$t('swap_item_chat_x_unread', { unread: _vm.swap.chat.unreadCount }))+" ")]],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }